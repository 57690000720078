/**
 * App: Chat message window
 * 
 * @module components/details-chat
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap 
 * @requires NPM:react-intl
 * 
 * @requires module:locales/language
 */

import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import lang from "../locales/language";

/**
 * Chat, gets messages from chat array in transaction. 
 * 
 * @class Chat
 * @extends {Component}
 * 
 * @param {object} props.user user object
 * @param {Array} props.transaction The transaction where the chat is.
 * @param {boolean} props.reverse Flag to reverse date order
 * @param {string}props.headingIntl i18 tag for the headline of the chatview.
 */
export default class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transaction: this.props.transaction,
            user: this.props.user,
            show: false
        }
        this.showMessages = this.showMessages.bind(this);
        this.keyExists = this.keyExists.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
    }
    onChangeText(e) {
        this.setState({ text: e.target.value });
    }

    showMessages() {
        let message = "", user = "", org = "", alignmess = "", timeStamp = "" , userorg = this.state.user.organisation.name;
        if (this.keyExists(this.props.transaction, "chat")) {
            let thearray = this.props.transaction.chat;
           function sortThings(b, a) {
            if (a.timeStamp > b.timeStamp) {
              return 1;
            } else if (a.timeStamp < b.timeStamp) {
              return -1;
            } else if (a.timeStamp === b.timeStamp) {
              return 0;
            }
          }
          if (this.props.reverse) {
          thearray.sort(sortThings);
          }
          let sortedMessages = thearray;
            return sortedMessages.map((item, i) => {
                timeStamp = lang.elapsed2str(item.timeStamp);
                org = item.organisation;
                user = item.user;
                message = item.message;
                alignmess = "him";
                if (userorg.indexOf(org) !== -1) {
                    alignmess = "me";
                }
                return <ul className="Chat" key={i + 1}><li className={alignmess} key={i}>
                    {message}
                </li>
                    <li className={alignmess + 'info text-muted'} key={i + 2}><small>{timeStamp}, {user} @ {org}</small></li>
                </ul>
            })
        } else {
            return null;
        }
    };

    /**
  * Check if it's a object
  * @param {*} obj 
  */
    keyExists(obj, prop) {
        return obj.hasOwnProperty(prop)
    };

    render() {
        const { headingIntl} = this.props;
        return (
            <>
               {this.keyExists(this.state.transaction, "chat") && this.state.transaction.chat.length > 0    && <Row>
                    <Col>
                        {!headingIntl ? (<FormattedMessage id={'CcpTransaction.chat.heading'} defaultMessage="Message" />
                        ) : (
                                <h5> <FormattedMessage id={headingIntl} defaultMessage="Messages" /></h5>
                            )
                        }
                    </Col>
                </Row>}
                <Row>
                    <Col className="ChatWindow">
                        <this.showMessages />
                    </Col>
                </Row>
            </>
        )
    }
}