import lang from '../locales/language.js';
import axios from 'axios';
import config from './config.js';
import uuid from "./uuid.js";
import auth from "./auth.js";

var myIP;

class getStats {
    statsKey = "marconet_87hbV6kEDlqb867ci6UQHGFhjg"
    
    constructor() {
        // Set a unique browser id, if not available
        var browserUid = localStorage.getItem(this.statsKey);
        if(browserUid === null) {
            browserUid = uuid.uuid();
            localStorage.setItem(this.statsKey, browserUid);
        }
        // Set a unique session id, if not available
        var sessionUid = sessionStorage.getItem(this.statsKey);
        if(sessionUid === null) {
            sessionUid = uuid.uuid();
            sessionStorage.setItem(this.statsKey, sessionUid);
        }
    }

    browser() {
        const browser = this.browserType();
        const timeLocal = new Date().toTimeString();

        return {
            uid: localStorage.getItem(this.statsKey),
            name: browser.name,
            version: browser.version,
            size: this.breakpoint(),
            width: window.innerWidth,
            height: window.innerHeight,
            locale: this.locale(),
            timezone: this.tz().name,
            time: Date.now(),
            timeLocal: timeLocal
        };
    }

    browserType() {
        var ua = navigator.userAgent, tem, 
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if(/trident/i.test(M[1])){
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return {name:'IE',version:(tem[1] || '')};
        }
        if(M[1]=== 'Chrome'){
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem != null) return {name:tem[1].replace('OPR', 'Opera'),version:tem[2]};
        }
        M = M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem = ua.match(/version\/(\d+)/i))!= null)
            M.splice(1, 1, tem[1]);
        return {name:M[0], version:M[1]};
    }
 
    breakpoint() {
        let breakpoint = {'xl': 1200, 'lg': 992, 'md': 768, 'sm': 576, 'xs': 0 };
        var ret = '';
        for( var b in breakpoint) {
            if(window.innerWidth >= breakpoint[b]) {
                ret = b;
                break;
            }
        }
        return ret;
    }

    locale() {
        return (navigator.languages && navigator.languages[0]) ||
            navigator.language ||
            navigator.userLanguage ||
            'en';
    }

    tz() {
        const timezoneOffset = new Date().getTimezoneOffset();
        var gmtRe = /GMT([-+]?\d{4})/; // Look for GMT, + or - (optionally), and 4 characters of digits (\d)
        var tzRe = /\(([\w\s.]+)\)/; // Look for "(", any words (\w) or spaces (\s), and ")"
        var d = new Date().toString();
        var tzGMT = gmtRe.exec(d)[1]; // timezone, i.e. -0700
        var tzName = tzRe.exec(d)[1]; // timezone, i.e. "Pacific Daylight Time"
        return { offset: timezoneOffset, relGMT: tzGMT, name: tzName };
    }

    session() {
        const version = process.env.REACT_APP_VERSION + " " + process.env.NODE_ENV;
        return { uid: sessionStorage.getItem(this.statsKey),
                url: window.location.href, 
                version: version,
                language: lang.getLanguage(),
                views: window.history.length,
            };
    }

    sendStats(action = "page", value = window.location.pathname) {
        const stats = {
            user: {
                uid: auth.getToken() || "",
                ip: myIP || "unknown"
            },
            action: {
                type: action,
                value: value
            },
            browser: this.browser(),
            session: this.session()
        };

        if (myIP) {
            axios.post(config.apiUrl + "/stats", stats, auth.getAuthHeader())
                .catch(function (e) {
                    console.log("API stats error: ", e);
                    if (e.response)
                        if (e.response.status === 401) {
                            auth.removeToken();
                            window.location.replace('/');
                        }
                });
        } else {
            axios.get("https://ip.marconet.eu/myip.php")
                .then(function (res) {
                    myIP = res.data.ip;
                    stats.user.ip = myIP;
                    axios.post(config.apiUrl + "/stats", stats, auth.getAuthHeader())
                        .catch(function (e) {
                            console.log("API stats error: ", e);
                            if (e.response)
                                if (e.response.status === 401) {
                                    auth.removeToken();
                                    window.location.replace('/');
                                }
                        });
                }).catch(function (e) {
                    stats.user.ip = e.message;
                    axios.post(config.apiUrl + "/stats", stats, auth.getAuthHeader())
                        .catch(function (e) {
                            console.log("API stats error: ", e);
                            if (e.response)
                                if (e.response.status === 401) {
                                    auth.removeToken();
                                    window.location.replace('/');
                                }
                        });
                });
        }
    }
};

const stats = new getStats();
export default stats;
