/**
 * App: Load transactions and display in a table view or two
 * 
 * @module components/table-transactions
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires module:utils/tableview
 * @requires module:utils/countdown
 * @requires module:utils/global
*/

import React, { Component } from 'react';
import { Container } from 'react-bootstrap'
import { TableView } from '../utils/tableview';
import { makeDeadline } from '../utils/countdown';
import { global } from '../utils/global';

/**
 * Display transactions overview table
 *
 * @class TransactionTable
 * @extends {Component}
 * 
 * @param {string} props.myHeadingIntl React-Intl id for the heading over the optional my transactions table
 * @param {string} props.headingIntl React-Intl id for the heading over the table
 * @param {Array}  props.allCollRequests Contains source data, array with all collateral request transactions
 * @param {string} props.marginPos Optional string with the margin position to select
 * @param {string} props.giver Optional string with the member name to select, or
 * @param {string} props.taker Optional string with the CCP to select, or
 */
export class TransactionTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			format: [
				{
					field: "position",
					headerIntl: "Table.colr.position", headerAlign: "text-left text-uppercase",
					bodyType: "Link", bodyAlign: "text-left"
				},
				{
					field: "type",
					headerIntl: "Table.colr.type", headerAlign: "d-none d-sm-table-cell text-left text-uppercase",
					bodyType: "Intl", bodyAlign: "d-none d-sm-table-cell text-left"
				},
				{
					field: "next",
					headerIntl: "Table.colr.next", headerAlign: "text-left text-uppercase",
					bodyType: "Intl", bodyAlign: "text-left"
				},
				{
					field: "deadline",
					headerIntl: "Table.colr.deadline", headerAlign: "text-left text-uppercase",
					bodyType: "Countdown", bodyAlign: "text-center"
				}
			],
			myData: [],
			data: []
		}
	}

	static getDerivedStateFromProps(props, state) {
		var myData = [];
		var data = [];
		let isCCP = global.user && global.user.organisation && (global.user.organisation.role === 'ccp');

		if (props.allCollRequests) {
			// Iterate through the collateral requests and prepare display data for my actions and all current actions separately
			props.allCollRequests.forEach(element => {
				var keep = true;
				keep = props.marginPos && props.marginPos !== element.marginPos ? false : keep;
				keep = props.giver && props.giver !== element.giver ? false : keep;
				keep = props.taker && props.taker !== element.taker ? false : keep;
				if (keep) {
					let deadline = makeDeadline(element.deadline);
					if (element.state === 'completed' || element.state === 'cancelled') {
						deadline = undefined;
					}
					var row = {
						position: [(isCCP ? element.giver : element.taker) + ": " + element.marginPos, "/transaction/" + element._id],
						type: element.name,
						next: "next." + element.nextState,
						deadline: deadline
					}
					data.push(row);
					if (props.myHeadingIntl && element.nextStateUser) {
						myData.push(row);
					}
				}
			});
		}

		return {
			myData: myData,
			data: data
		};
	}

	render() {
		return (
			<React.Fragment>
				{this.props.myHeadingIntl &&
					<Container className="mt-5">
						<TableView headingIntl={this.props.myHeadingIntl} format={this.state.format} data={this.state.myData} />
					</Container>
				}

				<Container className="mt-5">
					<TableView headingIntl={this.props.headingIntl} format={this.state.format} data={this.state.data} />
				</Container>
			</React.Fragment>
		)
	}
}
