/**
 * App: Display contact details
 * 
 * @module components/modal-contacts
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires NPM:@fortawsome
 * @requires module:utils/api
 * @requires module:utils/modalcomp
 * @requires module:utils/tableview
 */

import React, { Component } from 'react';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import api from '../utils/api';
import Modalcomp from "../utils/modalcomp";
import { TableView } from '../utils/tableview';
library.add(faPlus, faPhone, faEnvelope)

/**
 * Show a table in a modal with contact information. Get it from /user end point
 * and filter on organisation.
 *
 * @class ContactDetails
 * @param {string} props.orgname Name of organisation to display contacts at
 * @extends {Component}
 */
export class ContactDetails extends Component {
    constructor(props) {
        super(props);
        this.intl = props.intl;
        this.state = {
            users: [],
            dataLoaded: false,
            open: false,
            orgname: this.props.orgname,
            format: [
                {
                    field: "name",
                    headerIntl: "ContactDetails.escalation.name", headerAlign: "text-left text-uppercase",
                    bodyType: "Text", bodyAlign: "text-left overflow-ellipsis"
                },
                {
                    field: "email",
                    headerIntl: "ContactDetails.escalation.email", headerAlign:"d-none d-lg-table-cell text-center text-lg-left text-uppercase",
                    bodyType: "Mail", bodyAlign: "d-none d-lg-table-cell text-center text-lg-left overflow-ellipsis"
                },
                {
                    field: "emailshort",
                    headerIntl: "ContactDetails.escalation.email", headerAlign: "d-table-cell d-lg-none text-center text-lg-left text-uppercase",
                    bodyType: "Mail", bodyAlign: "d-table-cell d-lg-none text-center text-lg-left"
                },
                {
                    field: "mobile",
                    headerIntl: "ContactDetails.escalation.phone", headerAlign: "text-left text-uppercase",
                    bodyType: "Tel", bodyAlign: "text-left"
                }
            ]
        };
        this.toggle = this.toggle.bind(this);
    }
    
    getUsers() {
        api.list("/user", { filter: "organisation:" + this.state.orgname }, (err, res) => {
            if (err) {
            } else {
                var i = 0;
				var data = [];

				// Iterate through the actions and load display data for my actions
				res.forEach(element => {
					if (element.isContact) {
						data[i] = {};
                        data[i].name = element.firstName + ' ' + element.lastName;
                        data[i].email = [ element.email, element.email];
                        data[i].emailshort = [ <FontAwesomeIcon icon={["fas", "envelope"]} />, element.email];
                        data[i].mobile = [element.mobile, element.mobile];
						i++;
					}
				})

                this.setState({
                    dataLoaded: true,
                    users: data,
                });
            }
        });
    }

    /**
     * Check lenght and trim if necessary. 
     */
    trimText(text, value, ending) {
        if (value == null) {
            value = 100;
        }
        if (ending == null) {
            ending = '...';
        }
        let lenght = text.length;

        if (lenght > value) {
            return text.substring(0, value - ending.length) + ending
        } else {
            return text;
        }
    }

    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     */
    toggle() {
        this.setState({
            open: !this.state.open
        });
        this.isToggled();
    }

    isToggled() {
        if (this.state.open || !this.state.dataLoaded) {
            this.getUsers();
        }
    }

    render() {
        return (
            <>
                <Button variant="outline-success" className="mb-1" block size="sm" onClick={this.toggle}>
                    <FormattedMessage id="Action.contact.button" />
                </Button>
                <Modalcomp size="lg" show={this.state.open} parentAction={this.toggle} titleIntl={"Action.contact.button"} >
                    <Modal.Body>
                        <React.Fragment>
                             <Row className="mt-3">
                                <Col>
                                    <h4>
                                        <FormattedMessage id="CcpTransaction.escalation.contactmodal" />
                                        &nbsp;
                                        {this.state.orgname}
                                    </h4>
                                </Col>
                            </Row>
                           
                            {/* Tableview */}
                            <TableView  headingFormat="h5" format={this.state.format} data={this.state.users} />
                        </React.Fragment>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary"  size="sm" onClick={this.toggle}>
                            <FormattedMessage id="Contact.details.close" />
                        </Button>
                    </Modal.Footer>
                </Modalcomp>
            </>
        )
    }
}
export default injectIntl(ContactDetails);

