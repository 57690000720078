/**
 * App: Display transaction details
 * 
 * @module components/modal-colr-review
 * 
 * @requires NPM:react
 * @requires NPM:react-router-dom
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/api
 * @requires module:utils/modalcomp
 * @requires module:utils/tableview
 * @requires module:utils/post-message
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import api from '../utils/api';
import Modalcomp from "../utils/modalcomp";
import { TableView, numberRedFormat } from '../utils/tableview';
import PostMessage from "../utils/post-message";

// Responsive width of left and right column in the modal at different screen sizes
const widthLeft = { xs: 7, sm: 6, md: 5, lg: 4, xl: 3 };
const widthRight = { xs: 5, sm: 4, md: 3, lg: 2, xl: 2 };

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}
/**
 * Show the form in a modal to edit actions. Review proposal. 
 *
 * @class ReviewModal
 * @extends {Component}
 */
export class ReviewModal extends Component {
    constructor(props) {
        super(props);
        this.intl = props.intl;
        console.log('Transaction: ', this.props.transaction);
        this.state = {
            dropdownfield: '',
            subjectfield: '',
            textareafield: '',
            errors: {
                dropdownfield: '',
                subjectfield: '',
                textareafield: ''
            },
            rejectionvalue: 'OTHR',
            open: false,
            openapprove: false,
            opendecline: false,
            submitted: false,
            validated: false,
            response: '',
            //text: '',
            //subject: '',
            headingintl: "CcpTransaction.escalation.approvemodal",
            transaction: this.props.transaction,
            format: [
                {
                    field: "asset",
                    headerIntl: "Table.assets.asset", headerAlign: "text-left text-uppercase",
                    bodyType: "Text", bodyAlign: "text-left"
                },
                {
                    field: "volume",
                    headerIntl: "Table.assets.volume", headerAlign: "d-none d-lg-table-cell text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "d-none d-lg-table-cell text-right"
                },
                {
                    field: "value",
                    headerIntl: "Table.assets.marketValue", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
                },
                {
                    field: "haircut",
                    headerIntl: "Table.assets.haircut", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
                },
                {
                    field: "collValue",
                    headerIntl: "Table.assets.collValue", headerAlign: "text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "text-right"
                }
            ],
            rejection: [

                {
                    codename: 'DSEC',
                    name: 'InvalidSecurity',
                    definition: 'Unrecognised or invalid financial instrument identification.'
                },
                {
                    codename: 'EVNM',
                    name: 'UnrecognisedIdentification',
                    definition: 'Unrecognised event number.'
                },
                {
                    codename: 'UKWN',
                    name: 'UnknownSymbol',
                    definition: 'Financial instruments symbol has not been recognized.'
                },
                {
                    codename: 'ICOL',
                    name: 'InsufficientCollateral',

                    definition: 'Insufficient collateral proposed.'
                },
                {
                    codename: 'CONL',
                    name: 'ConcentrationLimitExceeded',
                    definition: 'Concentration limit was exceeded.'
                },
                {
                    codename: 'ELIG',
                    name: 'NonEligibleSecurity',
                    definition: 'Security is not eligible.'
                },
                {
                    codename: 'INID',
                    name: 'InvalidIdentification',
                    definition: 'Instruction contains an invalid message identification, identification is unknown.'
                },
                {
                    codename: 'OTHR',
                    name: 'Other',
                    definition: 'Other. See Narrative.'
                }

            ]
        };

        this.toggle = this.toggle.bind(this);
        this.toggleApprove = this.toggleApprove.bind(this);
        this.toggleDecline = this.toggleDecline.bind(this);
        this.onApproved = this.onApproved.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onChangeSubject = this.onChangeSubject.bind(this);
        this.onChangeDecline = this.onChangeDecline.bind(this);
        this.close = this.close.bind(this);
        this.closedecline = this.closedecline.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
    }

    componentDidMount() {
        // No need to load data
    }

    toggleApprove() {
        this.setState({
            openapprove: !this.state.openapprove,
            opendecline: false,
            response: ''
        });
    }

    toggleDecline() {
        this.setState({
            opendecline: !this.state.opendecline,
            openapprove: false,
            response: ''
        });
    }

    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     */
    toggle() {
        this.setState({
            open: !this.state.open,
            openapprove: false,
            opendecline: false,
            response: ''
        });
    }

    close() {
        // close modal
        this.toggle();
        //go to dashboard
        // window.location.replace('/dashboard');
        this.followLink('/dashboard')
    }

    closedecline() {
        // close modal
        this.toggleDecline();
        //go to dashboard
        //  window.location.replace('/dashboard');
        this.followLink('/dashboard')
    }

    /*followLink = (link) => {
        if (link && link.length > 0)
        this.setState({link: link})
    }*/

    followLink = (link) => {
        this.linkToFollow = link;
        // scroll to top
        if (link) {
            window.scrollTo(0, 0);
        }

        //this.forceUpdate();
    }

    onChangeText(e) {
        this.setState({ text: e.target.value });
    }

    onChangeSubject(e) {
        this.setState({ subject: e.target.value });
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        const dropdowninfo = this.intl.formatMessage({ id: "Approve.validate.dropdown" });
        const subjectinfo = this.intl.formatMessage({ id: "Approve.validate.subject" });
        const textareainfo = this.intl.formatMessage({ id: "Approve.validate.textarea" });

        // https://www.telerik.com/blogs/up-and-running-with-react-form-validation
        switch (name) {
            case 'dropdownfield':

                if (value.length <= 1) {

                    errors.dropdownfield = dropdowninfo
                } else {
                    errors.dropdownfield = "";
                }

                break;
            case 'subjectfield':
                if (value.length <= 1) {

                    errors.subjectfield = subjectinfo
                } else {
                    errors.subjectfield = "";
                }


                break;
            case 'textareafield':
                if (value.length <= 1) {

                    errors.textareafield = textareainfo
                } else {
                    errors.textareafield = "";
                }

                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            console.log(errors)
        })
    }

    onChangeDecline(e) {

        e.preventDefault();
        /* if(validateForm(this.state.errors)) {
             console.info('Valid Form')
           }else{
             console.error('Invalid Form')
           }*/
        if (validateForm(this.state.errors) && this.state.dropdownfield.length > 0 &&
            this.state.subjectfield.length > 0 &&
            this.state.textareafield.length > 0) {
            console.log("OK");


            //  if (validateForm(this.state.errors)) {

            //   if(this.state.validated){
            let body = {
                response: 'REJT',
                reason: this.state.rejectionvalue, // value from drop down: DSEC, EVNM, UKWN, ICOL, CONL, ELIG, INID
                message: this.state.text
            };

            api.update("/colr/approve/" + this.state.transaction._id, body)
                .then(res => {
                    this.setState({
                        openapprove: false,
                        opendecline: true,
                        submitted: true,
                        open: true,
                        subject: '',
                        text: '',
                        response: "CcpTransaction.escalation.approve.collateraldeclined"
                    });
                })
                .catch(err => {
                    this.setState({
                        openapprove: false,
                        opendecline: true,
                        submitted: false,
                        response: "CcpTransaction.escalation.response.error",
                        errormessage: err.message
                    })
                });
        }
    }
    onApproved(e) {
        e.persist();
        e.preventDefault();
        e.stopPropagation();
        let body = {
            _id: this.state.transaction._id,
            response: 'PACK',
            message: this.state.text
        };

        api.update("/colr/approve/" + body._id, body)
            .then(res => {
                this.setState({
                    openapprove: true,
                    opendecline: false,
                    submitted: true,
                    open: true,
                    response: "CcpTransaction.escalation.approve.collateralapproved"
                });
                e.preventDefault();
            })
            .catch(err => {
                this.setState({
                    openapprove: true,
                    submitted: false,
                    open: true,
                    response: "CcpTransaction.escalation.response.error",
                    errormessage: err.message
                })
            });
    }

    postMessage = (evt) => {
        this.setState({
            text: evt.target.value
        });
    }

    /**
   * Get the value from the dropdown
   * @param {*} e 
   */
    onChangeDropdown(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value) {
            this.setState({
                rejectionvalue: e.target.value,
                //validated: true
            });
        } else {
            this.setState({
                // validated: false
            });
        }

    }

    render() {
        /*if(this.state.link){
            return <Redirect to={this.state.link} />
        }*/
        const { errors } = this.state;

        if (this.linkToFollow) {
            console.log("Redirecting to", this.linkToFollow);
            const link = this.linkToFollow;
            this.linkToFollow = undefined;
            return <Redirect to={link} />
        }
        const { validated, rejection, transaction, openapprove, opendecline, response, submitted } = this.state;
        let { headingintl } = this.state;
        const selectAccount = this.intl.formatMessage({ id: "CcpTransaction.escalation.approve.modal.selectreason" }); // rejection reason...

        // const selectRejection = this.intl.formatMessage({ id: "Approve.codename" });

        //console.log(this.state.chatmessage);
        console.log("Validering: ", validateForm(this.state.errors))

        if (opendecline) {
            headingintl = "CcpTransaction.escalation.declinemodal";
        } else {
            headingintl = "CcpTransaction.escalation.approvemodal";
        }
        return (
            <>
                <Button onClick={this.toggle} variant="outline-success" className="mb-1" block size="sm">
                    <FormattedMessage id="Action.review.button" />
                </Button>

                <Modalcomp show={this.state.open} parentAction={this.toggle} titleText={'Review substitution'} titleIntl={headingintl} size="lg">

                    {/* No button clicked in modal  */}
                    {!(openapprove || opendecline) &&
                        <Modal.Body>
                            <Row className="mt-3">
                                <Col>
                                    <h4>
                                        <FormattedMessage id="CcpTransaction.escalation.approve.subheading" defaultMessage="Collateral proposal:" /> <FormattedMessage id={"CcpTransaction.heading." + transaction.name} />
                                        &nbsp;{transaction.marginPos}</h4>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col {...widthLeft}>
                                    <b>
                                        <FormattedMessage id={transaction.netCollMargin < 0 ? "deficit" : "surplus"} />
                                    </b>
                                </Col>
                                <Col {...widthRight} className="text-right">
                                    {numberRedFormat(transaction.netCollMargin)}
                                </Col>
                            </Row>

                            <Row>
                                <Col {...widthLeft} className="text-left">
                                    <b>
                                        <FormattedMessage id="Table.holdings.collValue" />
                                    </b>
                                </Col>
                                <Col  {...widthRight} className="mb-1 border-bottom text-right">
                                    {numberRedFormat(transaction.collValueChange)}
                                </Col>
                            </Row>

                            <Row>
                                <Col {...widthLeft} className="text-left">
                                    <b><FormattedMessage id="CcpTransaction.escalation.approve.collvaluechange" /></b>
                                </Col>
                                <Col {...widthRight} className="text-right mb-4">
                                    {numberRedFormat(transaction.netCollMargin + transaction.collValueChange)}
                                </Col>
                            </Row>

                            {/* Tableview */}
                            <TableView headingIntl={"CcpTransaction.escalation.approve.heading.proposed"} headingFormat="h5" format={this.state.format} data={transaction.collSubmit} />
                            <TableView headingIntl={"CcpTransaction.escalation.approve.heading.return"} headingFormat="h5" format={this.state.format} data={transaction.collReturn} />

                            <PostMessage onTextChange={this.postMessage} />

                        </Modal.Body>
                    }
                    {/* Approve button clicked in modal, opens post to api view */}
                    {openapprove &&
                        <>
                            <Modal.Body>
                                <Container className="mt-4">
                                    <Row>
                                        <Col>
                                            {response &&

                                                <><p><b className="text-danger">{this.state.errormessage}</b></p>

                                                    <FormattedMessage id={response} defaultMessage="Thanks for your feedback"
                                                        values={{
                                                            a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                        }}
                                                    />
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {!response &&
                                                <>
                                                    <FormattedMessage id="CcpTransaction.escalation.approve.collateralapprove" />
                                                    <FormattedMessage id={"CcpTransaction.heading." + transaction.name} />
                                                    &nbsp;{transaction.marginPos}?
                                        </>
                                            }
                                        </Col>
                                    </Row>

                                </Container>
                            </Modal.Body>

                            <Modal.Footer>
                                {!submitted &&
                                    <Button variant="outline-primary" className="mb-1" size="sm" onClick={this.onApproved}>
                                        <FormattedMessage id="CcpTransaction.escalation.button.ok" />
                                    </Button>}
                                {submitted &&
                                    <Button variant="outline-primary" className="mb-1" size="sm" onClick={this.close}>
                                        <FormattedMessage id="CcpTransaction.escalation.button.ok" />
                                    </Button>}
                            </Modal.Footer>
                        </>
                    }
                    {/* Decline clicked in modal  */}
                    {opendecline &&
                        <>
                            {!response ? (
                                <Form noValidate validated={validated} onSubmit={this.onChangeDecline} >
                                    <Modal.Body>
                                        <Container className="mt-4">
                                            <Row>
                                                <Col>
                                                    <FormattedMessage id="CcpTransaction.escalation.approve.colldeclined" /> {transaction.giver}
                                                </Col>
                                            </Row>


                                            <Form.Group controlId="dropdown">
                                                <Form.Label><FormattedMessage id="CmTransaction.propose.colatteral.modal.selectlabel" /></Form.Label>
                                                {rejection &&
                                                    <Form.Control name="dropdownfield" as="select" autoFocus={true} onBlur={this.handleChange} onChange={this.onChangeDropdown} required>
                                                        <option value="" >{selectAccount}</option>
                                                        {rejection.map((item, i) => {
                                                            return <option key={i} value={item.codename}> {this.intl.formatMessage({ id: "Review." + item.codename })} ({item.codename})</option>
                                                        })}
                                                    </Form.Control>
                                                }
                                                {errors.dropdownfield.length > 0 &&
                                                    <span className='text-danger'>{errors.dropdownfield}</span>}
                                            </Form.Group>

                                            <Form.Group controlId="subject">
                                                <Form.Label><FormattedMessage id="CcpTransaction.escalation.approve.subject" defaultMessage="Subject" /></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="subjectfield"
                                                    value={this.state.subject}
                                                    onChange={this.onChangeSubject}
                                                    onBlur={this.handleChange}
                                                />
                                                {errors.subjectfield.length > 0 &&
                                                    <span className='text-danger'>{errors.subjectfield}</span>}
                                            </Form.Group>

                                            <Form.Group controlId="text">
                                                <Form.Label><FormattedMessage id="CcpTransaction.escalation.approve.body" defaultMessage="Your feedback" /></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="textareafield"
                                                    type="text"
                                                    value={this.state.text}
                                                    onChange={this.onChangeText}
                                                    onBlur={this.handleChange}
                                                    required />
                                                {errors.textareafield.length > 0 &&
                                                    <span className='text-danger'>{errors.textareafield}</span>}
                                            </Form.Group>
                                        </Container>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="outline-primary" type="submit" className="mb-1" size="sm">
                                            <FormattedMessage id="CcpTransaction.escalation.button.ok" />
                                        </Button>
                                    </Modal.Footer></Form>
                            ) : (
                                    <>
                                        <Modal.Body>
                                            <Container className="mt-4">
                                                <Row>
                                                    <Col>
                                                        {response &&
                                                            <>
                                                                <p><b className="text-danger">{this.state.errormessage}</b></p>
                                                                <FormattedMessage id={response} defaultMessage="Thanks for your feedback"
                                                                    values={{
                                                                        a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <Button variant="outline-primary" onClick={this.closedecline} className="mb-1" size="sm">
                                                <FormattedMessage id="CcpTransaction.escalation.button.decline.close" />
                                            </Button>
                                        </Modal.Footer>
                                    </>
                                )}
                        </>
                    }
                    {!(openapprove || opendecline) &&
                        <Modal.Footer>
                            <Button variant="outline-primary" className="mb-1" size="sm" onClick={this.toggleApprove}>
                                <FormattedMessage id="CcpTransaction.escalation.button.approve" />
                            </Button>

                            <Button variant="outline-danger" className="mb-1" size="sm" onClick={this.toggleDecline}>
                                <FormattedMessage id="CcpTransaction.escalation.button.decline" />
                            </Button>
                        </Modal.Footer>}
                </Modalcomp>
            </>
        )
    }
}

export default injectIntl(ReviewModal);