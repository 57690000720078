/**
 * UUID module
 * 
 * @module utils/uuid
 */

/**
 * This function generates a pseudorandom RFC4122 v4 Universally Unique IDentifier (UUID) {@link https://www.rfc-editor.org/info/rfc4122|RFC 4122}.
 * 
 * @function uuid
 * @returns {string} returns a  UUID as a 16 character string.
 */

exports.uuid = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}