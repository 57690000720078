/**
* App: Table display utility
* @module utils/tableview
*/

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Table, Collapse } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';
import { CollapseIcon } from './collapse-icon';
import { CountdownButton } from './countdown';
//import { thisApp } from '../App';
import lang from "../locales/language.js";

/**
 * Display component that presents a table based on a format array and a data array
 *
 * @export
 * @class TableView
 * @param {string} headingIntl - A React-Intl tag for the heading of the table, 
 * @param {Array} format Table with formatting objects. One object per column. Key-value pairs are:
 * 			- field - An arbitrary name for the column
 * 			- headerIntl - Key to get header text from React-Intl
 * 			- headerText - Header text, instead of using React-Intl
 * 			- headerAlign - Bootstrap class to format the header text
 * 			- bodyType - Type of data in the table body field. Options include Link, Intl, Countdown, Number, NumberRed or just Text
 * 			- bodyAlign - Bootstrap class to format the body data field
 * @param {Array} data Data to be presented in the table. There is one object per line in the array. For some 
 * 			body types, like Link and Intl, there is a two item array. For links there is a display text and a url.
 * @extends {Component}
 */
export class TableView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: true
		};
	}

	followLink = (link) => {
		this.linkToFollow = link;
		// scroll to top
		if(link){
			window.scrollTo(0, 0);
		}
		
		this.forceUpdate();
	}

	toggleCollapse = () => {
		this.setState({ show: !this.state.show });
	}

	render() {
		if (this.linkToFollow) {
			console.log("Redirecting to", this.linkToFollow);
			const link = this.linkToFollow;
			this.linkToFollow = undefined;
			return <Redirect to={link} />
		}

		if (!this.props.data || this.props.data.length < 1) return null;

		return (
			<React.Fragment>
				{(this.props.headingIntl || this.props.headerText) &&
					<h3 className={this.props.headingFormat}>
						<div onClick={this.toggleCollapse}>
							<CollapseIcon in={this.state.show} />
							&nbsp;
						{this.props.headingIntl ?
								<FormattedMessage id={this.props.headingIntl} />
								:
								this.props.headingText
							}
						</div>
					</h3>
				}

				<Collapse in={this.state.show}>
					<div>
						<Table className="table table-hover">
							<thead>
								<tr>
									{
										this.props.format.map((itemFormat, i) => {
											if (itemFormat.headerIntl) {
												return <th key={i} className={itemFormat.headerAlign}>
													<FormattedMessage id={itemFormat.headerIntl} />
												</th>
											} else {
												return <th key={i} className={itemFormat.headerAlign}>{itemFormat.headerText}</th>
											}
										})
									}
								</tr>
							</thead>
							<tbody>
								{
									this.props.data.map((lineData, i) => {
										// If the first column in the table is a Link item, make the row clickable using that link
										// onClick={() => this.followLink(clickLink)} <Link to={clickLink}></Link>
										// onClick={()=> history.push({clickLink})}
										let clickLink = "";
										if (this.props.format[0].bodyType === "Link") {
											let col0 = lineData[this.props.format[0].field];
											clickLink = col0 ? col0[1] : "";
										}

										return <tr key={i} onClick={() => this.followLink(clickLink)} >{
											Object.values(this.props.format).map((itemFormat, j) => {
												return <ItemView key={j} itemFormat={itemFormat} itemData={lineData[itemFormat.field]} />
											})
										}</tr>
									})
								}
							</tbody>
						</Table>
					</div>
				</Collapse>
			</React.Fragment>
		);
	}
}


/**
 * Function to format and display a single data item of a table based on a format object and a data item
 *
 * @param {*} { itemFormat, itemData } Parameter object with format object and data item being a number, 
 * 			string or two item array
 * @returns JSX.Element
 */
const ItemView = ({ itemFormat, itemData }) => {
	var ret = "";
	switch (itemFormat.bodyType) {
		case 'Link':
			if (itemData) {
				ret = (<Link to={itemData[1]}>
					{itemData[0]}
				</Link>)
			}
			break;
		case 'Mail':
			if (itemData) {
				ret = (<a href={"mailto:" + itemData[1]}>
					{itemData[0]}
				</a>)
			}
			break;
		case 'Tel':
			if (itemData) {
				ret = (<a href={"tel:" + itemData[1]}>
					{itemData[0]}
				</a>)
			}
			break;
		case 'Intl':
			if (itemData) {
				ret = (<FormattedMessage
					id={itemData}
					defaultMessage={"- MISSING -"}
				/>)
			}
			break;
		case 'Countdown':
			ret = <CountdownButton deadline={itemData} block />;
			break;
		case 'Number':
			ret = numberFormat(itemData);
			break;
		case 'NumberRed':
			ret = numberRedFormat(itemData);
			break;
		default:
			if (itemData) {
				ret = itemData;
			}
			break;
	}
	return <td className={itemFormat.bodyAlign}>{ret}</td>;
}

/**
 * Number formatting function that presents a formatted number according to international settings
 *
 * @param {number} value The value to present
 * @returns JSX.Element
 */
export function numberFormat(value) {
	return new Intl.NumberFormat(lang.getLanguage(), {
		style: 'decimal',
		minimumIntegerDigits: 1,
		maximumFractionDigits: 0
	}).format(value);
}

/**
 * NumberRed formatting function that presents a formatted number until it becomes negative, then 
 * it is displayed as a red button to emphasise it is negative
 *
 * @param {number} value The value to present
 * @returns JSX.Element
 */
export function numberRedFormat(value) {
	if (value < 0) {
		return <span className="text-danger"><b>{numberFormat(value)}</b></span>
		// return <Button className="buttonInTbl" block size="sm" variant={'outline-danger'} disabled>{numberFormat(value)}</Button>
	} else {
		return numberFormat(value)
	}
}


