/**
 * Manage default language and switching of language
 *
 * @module locales/language
 *
 * @requires module:utils/global
 */

import { global } from '../utils/global';

/**
 * LanguageManager takes care of user language and local settings available through global lang
 */
class LanguageManager {
    constructor() {
        this.language_key = "language_87hbV6kEDlqb867ci6UQHGFhjg";

        // Define user's browser language.
        const browserLanguage = this.getBrowserLocale();

        // Check if language exist in cookie. Set language to browser setting if coookie does not exist.
        if (!this.getLanguage()) {
            // Split locales with a region code
            const languageWithoutRegionCode = browserLanguage.toLowerCase().split(/[_-]+/)[0];
            this.setLanguage(languageWithoutRegionCode);
        }
    }

    getBrowserLocale() {
        // Define user's language. Different browsers have the user locale defined
        // on different fields on the `navigator` object, so we make sure to account
        // for these different by checking all of them
        const browserLocale =
            (navigator.languages && navigator.languages[0]) ||
            navigator.language ||
            navigator.userLanguage ||
            'en';

        return browserLocale;
    }

    /**
     * Get current language setting from App
     * @returns {string} Language code of the currently selected language ("en" or "sv")
     */
    getLanguage() {
        global.language = localStorage.getItem(this.language_key);
        return global.language;
    }

    /**
     * Set current language for the App
     * @param {string} language - Language code to be selected ("en" or "sv") 
     */
    setLanguage(language) {
        global.language = language;
        localStorage.setItem(this.language_key, language);
    }

    // Set language and refresh the browser if there is a change
    switchLanguage(language) {
        if (this.getLanguage() !== language) {
            this.setLanguage(language);
            window.location.reload();
        }
    }

    // Get current language setting from browser local storage
    getLocale() {
        const language = this.getLanguage();
        if (language === 'sv') {
            return language + '_SE';
        } else {
            return language + '_GB';
        }
    }

    // Format any date to yyyy-mm-dd
    // TO DO: Use current locale to set the format
    date2str(...dateFields) {
        const date = new Date(...dateFields);
        const month = date.getMonth() + 1 + "";
        const day = date.getDate() + "";
        const dateString = date.getFullYear() + "-" + month.padStart(2, '0') + "-" + day.padStart(2, '0');

        return dateString;
    }

    // Format any date to hh:mm:dd
    // TO DO: Use current locale to set the format
    time2str(...dateFields) {
        const date = new Date(...dateFields);
        const minutes = date.getMinutes() + 1 + "";
        const seconds = date.getSeconds() + "";
        const timeString = date.getHours() + ":" + minutes.padStart(2, '0') + ":" + seconds.padStart(2, '0');

        return timeString;
    }

    // Format date and time to a short string describing elapsed time
    // TO DO: Spell out weekday for the last 2-3 days and further away, use format Ddd dd Mmm
    elapsed2str(...dateFields) {
        const dateTime = new Date(...dateFields);
        const now = Date.now();
        const oneDay = 24 * 60 * 60 * 1000;
        const days = Math.trunc(now / oneDay) - Math.trunc(dateTime / oneDay);
        var dateString = "";
        if(days === 0) {
            dateString = "Today";
        } else if(days === 1) {
            dateString = "Yesterday";
        } else {
            const month = dateTime.getMonth() + 1 + "";
            const day = dateTime.getDate() + "";
            dateString = dateTime.getFullYear() + "-" + month.padStart(2, '0') + "-" + day.padStart(2, '0');
        }
        const minutes = dateTime.getMinutes() + 1 + "";
        return dateString + " " + dateTime.getHours() + ":" + minutes.padStart(2, '0');
    }
}



var lang = new LanguageManager();
export default lang;