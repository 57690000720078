/**
 * Modal making a margin call
 * 
 * @module components/modal-colr-margincall
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/modalcomp
 * @requires module:utils/tableview
 * @requires module:utils/api
 * @requires module:utils/post-message
 */

import React, { Component } from "react";
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modalcomp from "./../utils/modalcomp";
import { numberFormat } from '../utils/tableview'
import api from "../utils/api";
import PostMessage from "../utils/post-message";

/**
 * Modal for margin call, to get to the first state "requested"
 * 
 * @class ModalMarginCall
 * @param {object} props.marginPos The margin position to make a margin call
 * @extends {Component}
 */
export class ModalMarginCall extends Component {
    constructor(props) {
        super(props);
        this.intl = props.intl;
        this.state = {
            open: false,
            submitted: false,
            time: 60,
            validated: true
        };
    }

    /**
     * Method to toggle modal display state, passed in as parentAction props to the modal 
     */
    toggle = () => {
        this.setState({
            open: !this.state.open,
            response: '',
            submitted: false,
            time: 60
        });
    }

    /**
     * onChangeTime()
     * @param {*} e validate time value field 
     */
    onChangeTime = (e) => {
        const errorMess = this.intl.formatMessage({ id: "CcpTransaction.escalation.validation.error" });
        const notANumber = this.intl.formatMessage({ id: "CcpTransaction.escalation.validation.notanumber" });
        if (e.target.value === null) {
            this.setState({
                Error: '',
                validated: false
            })
        } else if (e.target.value === 0) {
            this.setState({
                Error: errorMess,
                open: false,
                submitted: false,
                value: null,
                validated: false
            })
        } else if (isNaN(e.target.value)) {
            this.setState({
                Error: notANumber,
                validated: false
            })
        } else if (!e.target.value) {
            this.setState({
                Error: '',
                validated: false
            })
        }
        else if (e.target.value <= 0) {
            this.setState({
                Error: errorMess,
                validated: false
            })
        }
        else if (e.target.value > 0) {
            this.setState({
                Error: null,
                validated: true
            })
        }
        this.setState({ time: e.target.value });
    }

    /**
     * Method to submit the margin call via the API
     * @param {*} e - environment data from React
     */
    onSubmit = (e) => {
        e.preventDefault();
        console.log('Validated: ', this.state.validated);
        if (this.state.validated === true && this.state.time > 0) {
            console.log('time: ', parseInt(this.state.time))

            // Preparing data for the API call
            let body = {
                // Required, name of the margin position to call
                marginPos: this.props.marginPos.name,
                // Optional, remaining time in minutes to deadine for the margin call. Defaults to 60 minutes.
                remaining: parseInt(this.state.time),

                // Alternative, absolute time of the margin call deadline (instead of remaining time). ISO date-time string
                deadline: undefined,
                // Optional, margin position override of netValue = collateral value - margin requirement
                netValue: undefined,
                // Optional, message text string to be provided to the counterparty
                message: this.state.text,
                type: "margincall"
            };

            // Making the API call
            api.create("/colr", body)
                .then(res => {
                    this.setState({
                        open: true,
                        submitted: true,
                        response: "OK"
                    });
                })
                .catch(err => {
                    this.setState({
                        open: true,
                        textvalue: '',
                        submitted: false,
                        confirm: true,
                        response: "CcpTransaction.escalation.response.error",
                        errormessage: err.message
                    });
                });
        }
    }
    postMessage = (evt) => {
        this.setState({
            text: evt.target.value
        });
    }

    /**
     * Method to display the magin call modal
     */
    render() {
        const { submitted, response, errormessage, Error } = this.state;
        let marginvalue = Math.abs(this.props.marginPos.netCollMargin);

        Error && console.log('Error: ', Error);
        return (// Link to open modal for authenticated users 
            <React.Fragment>
                <Button variant="outline-warning" className="mb-1" block size="sm" onClick={this.toggle}>
                    <FormattedMessage id="Action.deficit.button" />
                </Button>
                {/* Modalcomp */}
                <Modalcomp show={this.state.open} parentAction={this.toggle} titleIntl={"Action.deficit.button"} >

                    <Form noValidate onSubmit={this.onSubmit} >
                        <Modal.Body>
                            {!submitted && <>
                                {response &&
                                    <>  
                                        <b className="text-danger">{errormessage}</b>
                                        <br />
                                        <FormattedMessage id={response} defaultMessage={response}
                                            values={{
                                                a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                            }}
                                        />
                                        <br />
                                        <br />
                                    </>
                                }

                                <Row>
                                    <Col xs={7}>
                                        <b><FormattedMessage id="Colr.margincall.action" /></b>
                                    </Col>
                                    <Col xs={5}>
                                        {numberFormat(marginvalue)}
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col xs={7}>
                                        <b><FormattedMessage id="Colr.margincall.addtime" /></b>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Group controlId="timeID">

                                            <Form.Control
                                                type="number"
                                                step="10"
                                                min="0"
                                                value={this.state.time}
                                                onChange={this.onChangeTime}
                                                required
                                                autoFocus
                                            />
                                            {Error && <b className="text-danger"> {Error}</b>}

                                        </Form.Group>
                                    </Col>
                                </Row>

                                <PostMessage onTextChange={this.postMessage} />
                            </>

                            }
                            {submitted &&
                                <FormattedMessage id="Colr.margincall.response" />
                            }
                        </Modal.Body>

                        <Modal.Footer>
                            {!submitted &&
                                <Button variant="outline-primary" type="submit" size="sm">
                                    <FormattedMessage id="Colr.margincall.submit" defaultMessage="Submit" />
                                </Button>
                            }
                            {submitted &&
                                <Button variant="outline-primary" size="sm" onClick={this.toggle}>
                                    <FormattedMessage id="Close" defaultMessage="Close" />
                                </Button>
                            }
                        </Modal.Footer>
                    </Form>
                </Modalcomp>
            </React.Fragment>
        )
    }
}

export default injectIntl(ModalMarginCall);