/**
 * App: 404 page to display when the path is not found
 * 
 * @module components/page404
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 */

import React, { Component } from 'react';
import { Container} from "react-bootstrap";
import {FormattedMessage} from 'react-intl';

/**
 * Status page 404 to show when a path is not found
 * Import it and the use it as fallback in the router: <Route component={Page404} />
 */
export default class Page404 extends Component {
    render() {
        const path = window.location.hostname + window.location.pathname;
        const url = window.location.href;
        return (            
            <React.Fragment>   
                <Container className="mt-5"> {/* General introduction */}
                    <h2>
                        <FormattedMessage  
                            id="NotFound.headline"  
                            defaultMessage="Cannot find <url></url>, that you were looking for"
                            values={{
                                url: msg => (
                                    <a className="external_link" href={url}>{path}</a>
                                )
                            }}
                        />
                    </h2>
                    <p>
                        <FormattedMessage  
                            id="NotFound.explanation"  
                            defaultMessage="Probably the page you are looking for is under development. Alternatively 
                                you need to loggin first or you received a bad link. You may go to the <a>home page</a>."
                            values={{
                                a: msg => (
                                    <a className="external_link" href="/">{msg}</a>
                                )
                            }}
                        />
                    </p>
                </Container>
            </React.Fragment>
        )
    }
}
