/**
 * App: Display transaction details
 * 
 * @module components/modal-colr-transfer
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/api
 * @requires module:utils/modalcomp
 * @requires module:utils/tableview
 */

import React, { Component } from 'react';
import { Container, Button, Col, Row, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import api from '../utils/api';
import Modalcomp from "../utils/modalcomp";
import { TableView, numberRedFormat } from '../utils/tableview';
import PostMessage from "../utils/post-message";

// Responsive width of left and right column in the modal at different screen sizes
const widthLeft = { xs: 7, sm: 6, md: 5, lg: 4, xl: 3 };
const widthRight = { xs: 5, sm: 4, md: 3, lg: 2, xl: 2 };

/**
 * Show the form in a modal to confirm transfer 
 *
 * @class ModalTransfer
 * @extends {Component}
 */
export default class ModalTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openapprove: false,
            opendecline: false,
            response: '',
            text: '',
            subject: '',
            headingintl: "CcpTransaction.escalation.approvemodal",
            transaction: this.props.transaction,
            format: [
                {
                    field: "asset",
                    headerIntl: "Table.assets.asset", headerAlign: "text-left text-uppercase",
                    bodyType: "Text", bodyAlign: "text-left"
                },
                {
                    field: "volume",
                    headerIntl: "Table.assets.volume",headerAlign: "d-none d-lg-table-cell text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "d-none d-lg-table-cell text-right"
                },
                {
                    field: "value",
                    headerIntl: "Table.assets.marketValue", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
                },
                {
                    field: "haircut",
                    headerIntl: "Table.assets.haircut", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
                },
                {
                    field: "collValue",
                    headerIntl: "Table.assets.collValue", headerAlign: "text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "text-right"
                }
            ]
        };

        this.toggle = this.toggle.bind(this);
        this.toggleApprove = this.toggleApprove.bind(this);
        this.toggleDecline = this.toggleDecline.bind(this);
        this.thisTransfer = this.thisTransfer.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onChangeSubject = this.onChangeSubject.bind(this);
        this.getKey = this.getKey.bind(this);
    }

    toggleApprove() {
        this.setState({
            openapprove: !this.state.openapprove,
            opendecline: false,
            response: ''
        });
    }

    toggleDecline() {
        this.setState({
            opendecline: !this.state.opendecline,
            openapprove: false,
            response: ''
        });
    }

    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     */
    toggle() {
        this.setState({
            open: !this.state.open,
            openapprove: false,
            opendecline: false,
            response: ''
        });
    }

    onChangeText(e) {
        this.setState({ text: e.target.value });
    }

    onChangeSubject(e) {
        this.setState({ subject: e.target.value });
    }

    getKey(value, obj) {
        return [...obj].find(([key, val]) => val === value)[0]
    }

    thisTransfer(e) {
        e.preventDefault();

        let body = {
            settlStatus: 'AAUT',
            message: this.state.text
        }

        api.update("/colr/transfer/" + this.state.transaction._id, body)
            .then(res => {
                console.log(res)
                this.setState({
                    openapprove: true,
                    opendecline: false,
                    submitted: true,
                    response: "CmTransaction.escalation.response.message"
                });
            })
            .catch(err => {
                this.setState({
                    openapprove: true,
                    submitted: false,
                    response: "CcpTransaction.escalation.response.error",
                    errormessage: err.message
                });
            });
        // Next: Display settlement details if 'manual'
    }

    postMessage = (evt) =>{
        this.setState({
            text: evt.target.value
        });
    }

    render() {
       
        const { transaction, submitted, response } = this.state;
      //  console.log('transaction ', transaction)
        let { headingintl } = this.state;
        headingintl = "CmTransaction.escalation.transfer.button";
        return (
            <>
                <Button onClick={this.toggle} variant="outline-success" className="mb-1" block size="sm">
                    <FormattedMessage id="CmTransaction.escalation.transfer.button" />
                </Button>

                <Modalcomp show={this.state.open} parentAction={this.toggle} titleText={'Approve substitution'} titleIntl={headingintl} size="lg">

                    {!submitted &&
                    <>
                        <Modal.Body>
                            <Row className="mt-3">
                                <Col>
                                    <h4>
                                        <FormattedMessage id="CcpTransaction.escalation.approve.subheading" defaultMessage="Collateral proposal:" /> <FormattedMessage id={"CcpTransaction.heading." + transaction.name} />
                                        &nbsp;{transaction.marginPos}</h4>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col {...widthLeft}>
                                    <b>
                                        <FormattedMessage id={transaction.netCollMargin < 0 ? "deficit" : "surplus"} />
                                    </b>
                                </Col>
                                <Col {...widthRight} className="text-right">
                                    {numberRedFormat(transaction.netCollMargin)}
                                </Col>
                            </Row>

                            <Row>
                                <Col {...widthLeft} className="text-left">
                                    <b>
                                        <FormattedMessage id="Table.holdings.collValue" />
                                    </b>
                                </Col>
                                <Col  {...widthRight} className="mb-1 border-bottom text-right">
                                    {numberRedFormat(transaction.collValueChange)}
                                </Col>
                            </Row>

                            <Row>
                                <Col {...widthLeft} className="text-left">
                                    <b><FormattedMessage id="CcpTransaction.escalation.approve.collvaluechange" /></b>
                                </Col>
                                <Col {...widthRight} className="text-right mb-4">
                                    {numberRedFormat(transaction.netCollMargin + transaction.collValueChange)}
                                </Col>
                            </Row>

                            {/* Tableview */}
                            <TableView headingIntl={"CcpTransaction.escalation.approve.heading.submit"} headingFormat="h5" format={this.state.format} data={transaction.collSubmit} />
                            <TableView headingIntl={"CcpTransaction.escalation.approve.heading.return"} headingFormat="h5" format={this.state.format} data={transaction.collReturn} />
                        
                            <PostMessage onTextChange={this.postMessage}  />
                        </Modal.Body>
                      
                            <Modal.Footer>
                                <Button variant="outline-primary" className="mb-1" size="sm" onClick={this.thisTransfer}>
                                    <FormattedMessage id="CmTransaction.escalation.transfer.button" />
                                </Button>
                            </Modal.Footer>
                  
                  </>
                    }
                    
                    {submitted &&
                        <>
                            <Modal.Body>
                                <Container className="mt-4">
                                    <Row>
                                        <Col>
                                            {response &&
                                                <><p><b className="text-danger">{this.state.errormessage}</b></p>
                                                    <FormattedMessage id={response} defaultMessage="Thanks for your feedback"
                                                        values={{
                                                            a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                        }}
                                                    />
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="outline-success" className="mb-1" size="sm" onClick={this.toggle}>
                                    <FormattedMessage id="CcpTransaction.escalation.button.ok" />
                                </Button>
                            </Modal.Footer>
                        </>
                    }
                   
                </Modalcomp>
           </>
        )
    }
}