/**
 * App: Display an overview of a selected margin position
 * 
 * @module components/overview-marginpos
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires NPM:@fortawesome
 * @requires module:utils/global
 
 * @requires module:components/details-marginpos
 * @requires module:components/table-transactions
 * @requires module:components/table-holdings
 * @requires module:components/table-assets
 * @requires module:components/overview-counterparty
 * 
 * @requires module:components/modal-contacts
 * @requires module:components/modal-colr-margincall
 * @requires module:components/modal-colr-propose
 * @requires module:components/modal-colr-requestrecall
 * @requires module:components/modal-colr-substitution
 * @requires module:components/modal-colr-requestsubst
 */

import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { global } from '../utils/global';

import MarginPosDetails from "./details-marginpos";
import { TransactionTable } from './table-transactions'
import { HoldingTable } from './table-holdings'
import { AssetTable } from './table-assets'
import { CounterpartyOverview } from './overview-counterparty'

import ContactDetails from "./modal-contacts"
import ModalMarginCall from "./modal-colr-margincall";
import ModalPropose from "./modal-colr-propose";
import ModalRequestrecall from "./modal-colr-requestrecall";
import ModalSubstitution from "./modal-colr-substitution";
import ModalRequestSubstitution from "./modal-colr-requestsubst";

library.add(faPencilAlt);

const widthLeft = { xs: 7, sm: 6, md: 5, lg: 4, xl: 3 }
const widthRight = { xs: 5, sm: 4, md: 3, lg: 2, xl: 2 }

/**
 * Present a single margin position with relevant overviews
 *
 * @export
 * @class MarginposOverview
 * @extends {Component}
 */
export default class MarginposOverview extends Component {
    render() {
        const marginPos = this.props.allMarginPositions &&
            this.props.allMarginPositions.find(({ _id }) => _id === this.props.match.params.id);
        if (!marginPos) return null;
        const isTaker = marginPos.taker === global.user.organisation.name;
        const counterpartyName = isTaker ? marginPos.giver : marginPos.taker;

        return (
            <React.Fragment>
                <Container className="mt-5">
                    <h1><FormattedMessage id="MarginPos.heading" /></h1>
                </Container>

                <MarginPosDetails headingIntl="MarginPos.heading.details" marginPos={marginPos} />
                <MarginPosActions marginPos={marginPos} />

                <TransactionTable
                    headingIntl="Heading.allCollRequests"
                    allCollRequests={this.props.allCollRequests}
                    marginPos={marginPos.name} />

                <HoldingTable
                    headingIntl="Heading.allCollateral"
                    allMarginPositions={this.props.allMarginPositions}
                    marginPos={marginPos.name} />

                <AssetTable 
                    headingIntl="Heading.allAssets" 
                    account={global.user.organisation.account} />

                <div className="alt-background mt-5 py-2">
                    <CounterpartyOverview 
                        headingIntl="Heading.counterparty" 
                        allCollRequests={this.props.allCollRequests}
                        allMarginPositions={this.props.allMarginPositions}
                        counterpartyName={counterpartyName} />
                </div>
            </React.Fragment>
        )
    }
}

/**
 * Subview of margin position detils page: Section shows different actions to take relevant 
 * to the current margin position state
 *
 * @class MarginPosActions
 * @extends {Component}
 */
class MarginPosActions extends Component {
    render() {
        let marginPos = this.props.marginPos;
        if (!marginPos) return null;
        const isTaker = marginPos.taker === global.user.organisation.name;
        const counterpartyName = isTaker ? this.props.marginPos.giver : this.props.marginPos.taker;

        // Logic for which actions to propose
        const showContact = true;
        const showMargincall = isTaker  && marginPos.state === 'deficit';
        const showReturn = isTaker  && marginPos.state === 'surplus';
        const showSubstitution = isTaker;
        const showPost    = !isTaker && marginPos.state === 'deficit';
        const showRecall  = !isTaker && marginPos.state === 'surplus';
        const showSubstitutionRequest = !isTaker;
        console.log('MarginPos: ', marginPos)
        return (
            <Container className="mt-4">
                <Row>
                    <Col>
                        <h5>
                            <FormattedMessage id="Action.heading" />
                        </h5>
                    </Col>
                </Row>
                {showContact && <Row>
                    <Col {...widthLeft}>
                        <b>
                            -&nbsp;
                            <FormattedMessage id="Action.contact" />
                            &nbsp;
                            {counterpartyName}
                        </b>
                    </Col>
                    <Col {...widthRight}>
                        < ContactDetails orgname={counterpartyName} />
                    </Col>
                </Row>}

                {showMargincall && <Row>
                    <Col {...widthLeft}>
                        <b>
                            -&nbsp;
                            <FormattedMessage id="Action.deficit" />
                        </b>
                    </Col>
                    <Col {...widthRight}>
                        {/* Ny knapp ModalMarginCall */}
                        <ModalMarginCall marginPos={marginPos} />
                    </Col>
                </Row>}
                {showReturn && <Row>
                    <Col {...widthLeft}>
                        <b>
                            -&nbsp;
                            <FormattedMessage id="Action.surplus" />
                        </b>
                    </Col>
                    <Col {...widthRight}>
                         {/*Modal return surplus */}
                         <ModalRequestrecall marginPos={marginPos} transaction={marginPos} />
                    </Col>
                </Row>}
                {showSubstitution && <Row>
                    <Col {...widthLeft}>
                        <b>
                            -&nbsp;
                            <FormattedMessage id="Action.substitution" />
                        </b>
                    </Col>
                    <Col {...widthRight}>

                        <ModalRequestSubstitution marginPos={marginPos} transaction={marginPos} />
                    </Col>
                </Row>}

                {showPost && <Row>
                    <Col {...widthLeft}>
                        <b>
                            -&nbsp;
                            <FormattedMessage id="Action.post" />
                        </b>
                    </Col>
                    <Col {...widthRight}>
                        {/* Ny knapp ModalPostCollateral */}
                        <ModalPropose marginPos={marginPos} type="margincall" />
                    </Col>
                </Row>}
                {showRecall && <Row>
                    <Col {...widthLeft}>
                        <b>
                            -&nbsp;
                            <FormattedMessage id="Action.recall" />
                        </b>
                    </Col>
                    <Col {...widthRight}>
                        {/*Modal request recall */}
                        <ModalRequestrecall marginPos={marginPos} transaction={marginPos} />
                    </Col>
                </Row>}
                {showSubstitutionRequest && <Row>
                    <Col {...widthLeft}>
                        <b>
                            -&nbsp;
                            <FormattedMessage id="Action.substitutionRequest" />
                        </b>
                    </Col>
                    <Col {...widthRight}>

                        <ModalSubstitution marginpos={marginPos.name} transaction={marginPos} />
                    </Col>
                </Row>}
            </Container>
        )
    }
}

