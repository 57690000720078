/**
 * App: Display an html document in an iframe, keeping the menues and footer
 * @module utils/display-html
 * @requires NPM:react
 */

import React, { Component } from 'react';

/**
 * React component that displays a html file (path) in an iframe
 *
 * @class DisplayHtml
 * @property {string} src http path to document to display
 * @example 
 * <DisplayHtml src="http://localhost/docs/app" />
 * @extends {Component}
 */
export class DisplayHtml extends Component {
    iframe() {
      return {
        __html: '<iframe src="' + this.props.src + '" width="100%" height="896"></iframe>'
      }
    }
  
    render() {
      return (
          <div dangerouslySetInnerHTML={ this.iframe() } />
      );
    }
}
