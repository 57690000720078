// JSDoc format https://jsdoc.app/ 

/**
* API support module
* @module utils/api
*/

import axios from 'axios';
import config from './config';
import stats from './stats.js';
import auth from './auth.js';

/**
* The API class supports supports API CRUD calls with config, stats and error handling
*/
class ApiHelper {

    /**
    * The constructor just defines the API server url
    * 
    * @constructor
    */
    constructor(url = config.apiUrl) {
        this._url = url;
        this.cbQueue = [];
    }

    /**
    * Create a new record using Axios POST, do stats and console logging
    * 
    * @param {string} path - API path to call
    * @param {object} data - Object with data to provide to the API
	* @return {Promise} Returns a new promise waiting for the API to return with status
    */
    create(path, data) {
        console.log("POST to API", this._url + path);
        stats.sendStats("api", "POST" + path);
        return new Promise((resolve, reject) => {
            axios.post(this._url + path, data, auth.getAuthHeader())
                .then(res => {
                    console.log("Result:", res.status, res.statusText);
                    resolve(res.data);
                })
                .catch(err => {
                    this.errorHandling(err);
                    reject(err);
                });
        });
    }

    /**
     * List all record using Axios GET, do stats and console logging
     * 
     * @param {string} path API path to call
     * @param {object} options Optional filter, sort, limit and offset parameters
     * @param {string} options.filter Query string with keys, values and logic: name:Fido AND type:dog
     * @param {string} options.sort Sort key or array with sort keys. A - (minus sign) preceeding the key denotes decending sort order.
     * @param {string} options.limit Max number of entries to return
     * @param {string} options.offset Skip the first {offset} entries
     * @param {function} cbNewInfo Optional call back function, when new info might be available from the API due to updates.
	 * @return {Promise|undefined} Returns a new promise waiting for the API or undefined if the optional call back is used 
     */
    list(path, options = {}, cbNewInfo = undefined) {
        path += "?";
        path += options.filter ? "filter=" + encodeURIComponent(options.filter) + "&" : "";
        if (options.sort) {
            if (Array.isArray(options.sort)) {
                options.sort.forEach(sortKey => {
                    path += "sort=" + encodeURIComponent(sortKey) + "&";
                });
            } else {
                path += options.sort ? "sort=" + encodeURIComponent(options.sort) + "&" : "";
            }
        }
        path += options.limit ? "limit=" + options.limit + "&" : "";
        path += options.offset ? "offset=" + options.offset + "&" : "";
        path = path.slice(0, -1);

        console.log("GET from API", this._url + path);
        stats.sendStats("api", "GET" + path);

        if (cbNewInfo) {
            this._cbList(path, cbNewInfo);
        } else {
            return this._promiseList(path);
        }
    }

    _cbList(path, cbNewInfo) {
        this._addCbQueue(path, cbNewInfo);

        axios.get(this._url + path, auth.getAuthHeader())
            .then(res => {
                console.log("Result:", res.status, res.statusText);
                cbNewInfo(false, res.data);
            })
            .catch(err => {
                this.errorHandling(err);
                cbNewInfo(err);
            });
    }

    _promiseList(path) {
        return new Promise((resolve, reject) => {
            axios.get(this._url + path, auth.getAuthHeader())
                .then(res => {
                    console.log("Result:", res.status, res.statusText);
                    resolve(res.data);
                })
                .catch(err => {
                    this.errorHandling(err);
                    reject(err);
                });
        });
    }

    listStop() {
        this._stopCbQueue();
    }

    /**
    * Read a record using Axios GET, do stats and console logging
    * 
    * @param {string} path - API path to call
    * @param {string} id - ID of the record to read
	* @return {Promise} Returns a new promise waiting for the API to return with status and data
    */
    read(path, id = undefined) {
        // record ID can either be part of path or provided as the id parameter
        path = id ? path + "/" + id : path;

        console.log("GET from API", this._url + path);
        stats.sendStats("api", "GET" + path);
        return new Promise((resolve, reject) => {
            axios.get(this._url + path, auth.getAuthHeader())
                .then(res => {
                    console.log("Result:", res.status, res.statusText);
                    resolve(res.data);
                })
                .catch(err => {
                    this.errorHandling(err);
                    reject(err);
                });
        });
    }

    /**
    * Update an existing record using Axios PUT, do stats and console logging
    * 
    * @param {string} path - API path to call, including id
    * @param {object} data - Object with data to provide to the API
	* @return {Promise} Returns a new promise waiting for the API to return with status
    */
    update(path, data) {
        console.log("PUT to API", this._url + path);
        stats.sendStats("api", "PUT" + path);
        return new Promise((resolve, reject) => {
            axios.put(this._url + path, data, auth.getAuthHeader())
                .then(res => {
                    console.log("Result:", res.status, res.statusText);
                    resolve(res.status);
                })
                .catch(err => {
                    this.errorHandling(err);
                    reject(err);
                });
        });
    }

    /**
    * Delete a record using Axios DELETE, do stats and console logging
    * 
    * @param {string} path - API path to call
    * @param {string} id - ID of the record to read
	* @return {Promise} Returns a new promise waiting for the API to return with status and data
    */
    delete(path, id = undefined) {
        // record ID can either be part of path or provided as the id parameter
        path = id ? path + "/" + id : path;

        console.log("DELETE from API", this._url + path);
        stats.sendStats("api", "DELETE" + path);
        return new Promise((resolve, reject) => {
            axios.delete(this._url + path, auth.getAuthHeader())
                .then(res => {
                    console.log("Result:", res.status, res.statusText);
                    resolve(res.status);
                })
                .catch(err => {
                    this.errorHandling(err);
                    reject(err);
                });
        });
    }

    errorHandling(err) {
        console.log("API error: ", err);
        if (err.response) {
            if (err.response.status === 401) {
                auth.removeToken();
                window.location.replace('/');
            }
        }
    }

    // Callback queue and websocket handling

    _addCbQueue(path, callBack) {
        let i = this._findCbQueue(path, callBack);
        if (i === undefined) {
            console.log("Adding to queue:", path);
            this.cbQueue.push({ path: path, callBack: callBack });
            if (this.cbQueue.length === 1) {
                this._startCbQueue();
            }
        } else {
            console.log("NOT adding to queue:", path);
        }
    }

    _removeCbQueue(path, callBack) {
        let i = this._findCbQueue(path, callBack);
        if (i !== undefined) {
            console.log("Removing from queue:", path);
            this.cbQueue.splice(i, 1);
            if (this.cbQueue.length === 0) {
                this._stopCbQueue();
            }
        } else {
            console.log("NOT removing from queue:", path);
        }
    }

    _findCbQueue(path, callBack) {
        let index = undefined;
        for (let i = 0; i < this.cbQueue.length; i++) {
            if (path === this.cbQueue[i].path && callBack === this.cbQueue[i].callBack) {
                index = i;
                i = this.cbQueue.length;
            }
        }
        return index;
    }

    _startCbQueue() {
        console.log("Starting queue");

        if (this.socket) {
            this.socket.close();
        }
        this.socket = new WebSocket(config.wsUrl);
        // On open: Send identification to subscribe to notification
        this.socket.onopen = () => {
            console.log('WebSocket Client Connected');
            this.socket.send("User " + auth.getUserName());
        };

        // On message: Check if there is any list in queue that needs an update
        this.socket.onmessage = (message) => {
            console.log("Message data:", message.data);
            // if message begins with / it indicates an API path update message
            if (message.data[0] === '/') {
                console.log("API broadcasted update for", message.data)
                this.cbQueue.forEach(element => {
                    let path = element.path.split('?')[0];
                    // Compare using the length of the shortest string
                    let len = Math.min(path.length, message.data.length);
                    if (message.data.slice(0,len) === path.slice(0,len)) {
                        try {
                            this._cbList(element.path, element.callBack)
                        }
                        catch (err) {
                            console.log(err);
                            this._removeCbQueue(element.path, element.callBack);
                        }
                    }
                })
            }
        };

        // On close (server down): Kill queue, logout and return to home page
        this.socket.onclose = () => {
            this._stopCbQueue();
            auth.logOut(function () {
                console.log('Signed out');
                window.location.replace('/');
            });
        };
    }

    _stopCbQueue() {
        console.log("Stoping queue");

        this.cbQueue = [];
        this.socket.close();
        this.socket = undefined;
    }
}

var api = new ApiHelper();
export default api;