/**
 * App: Display CCP Dashboard component module
 * 
 * @module components/info-blog-post
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires NPM:@fortawsome
 * @requires module:utils/stats
 * @requires module:locales/language
 * 
 * @requires module:components/modal-show-image
 * @requires module:components/page404
 * @requires module:components/info-blog
 */

import React, { Component } from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import stats from '../utils/stats.js';
import lang from "../locales/language";

import ModalShowImage from "./modal-show-image";
import Page404 from "./page404";

library.add(faCircle);

/**
 * Present a single blog post on a full page
 *
 * @class BlogPost
 * @extends {Component}
 * 
 * @param {Object} props React properties
 * @param {Array} props.allBlogPosts All home page blog posts
 */
export default class BlogPost extends Component {
	constructor(props) {
		super(props);
		stats.sendStats();

		this.state = {
			showImage: false
		}
	}

	toggleShowImage = () => {
		this.setState({ showImage: !this.state.showImage })
	}

	render() {
		const postID = decodeURIComponent(this.props.match.params.id);
		const post = this.props.allBlogPosts && this.props.allBlogPosts.find(({ link }) => link === postID);
		if (!post) return (<Page404 />);

		return (
			<React.Fragment>
				<Container className="mt-5">
					<h1>{post.title}</h1>
					<p>
						<small>
							<FontAwesomeIcon className={"text-" + post.color} icon={["fas", "circle"]} />
							<span className="text-uppercase text-muted">
								&nbsp;<FormattedMessage id={"Blog.type." + post.type} />
							</span>
							<span className={"text-muted"}>
								&nbsp;|&nbsp;
									<FormattedMessage id="Blog.lastupdated" />
								&nbsp;
									{lang.elapsed2str(post.date)}
							</span>
						</small>
					</p>
					<Row>
						<Col xs={8}>
							<span dangerouslySetInnerHTML={{ __html: post.body }} />
						</Col>
						{post.image &&
							<Col xs={4}>
								<Image onClick={this.toggleShowImage} width="100%" src={post.image} />
							</Col>
						}
					</Row>
				</Container>

				<ModalShowImage src={post.image} show={this.state.showImage} onHide={this.toggleShowImage} />}

			</React.Fragment>
		)
	}
}
