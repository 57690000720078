/**
 * App: Modal for the user to provide application feedback
 * 
 * @module components/modal-feedback
 * 
 * @requires NPM:react
 * @requires NPM:react-router-dom
 * @requires NPM:react-intl
 * @requires module:utils/auth
 * @requires module:utils/modalcomp
 * 
 * @requires module:components/feedbackform
 */

import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import auth from '../utils/auth';
import Modalcomp from "../utils/modalcomp";

import FeedbackForm from "./feedbackform";

/**
 * Modal with a form, Renders a Feedbackform wrapped 
 * in a Modalcomp component. Activate the modal from a link with 
 * the toggle function for example. Currently used in the footer if
 * logged in.
 * 
 * @export
 * @class FeedBackModal
 * @extends {Component} 
 */
export default class FeedBackModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            open: false, 
            name: '', 
            team: '', 
            country: '' 
        };
    }

    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     * @method 
     */
    toggle = () => {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        return (
            <React.Fragment>
                {auth.loggedIn() && <React.Fragment>
                    <FormattedMessage id="Feedback.feedbacktext" />
                    &nbsp;
                    <Link to="#" onClick={this.toggle}>
                        <FormattedMessage id="Feedback.feedbackinsideurl" />
                    </Link>
                    {this.state.open &&
                        <Modalcomp show={true} parentAction={this.toggle} titleIntl={"Feedback.feedbacktitle"} >
                            <FeedbackForm parentAction={this.toggle} />
                        </Modalcomp>
                    }
                </React.Fragment>}
            </React.Fragment>
        )
    }
}