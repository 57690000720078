/**
 * App: The general page footer
 * 
 * @module components/footer
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * 
 * @requires module:components/modal-feedback
 */

import React, { Component } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';

import Feedbackmodal from "./modal-feedback";

/**
 * Footer. Render a footer class Container to be used as a footer.
 *  
 * @export
 * @class Footer
 * @extends {Component}
 */
export default class Footer extends Component {
    render() {
        const version = "v: " + process.env.REACT_APP_VERSION + " " + process.env.NODE_ENV;
        return (
            <Container fluid className="footer text-dark mt-5 mb-2">
                <Container>
                    <Row >
                        <Col lg="6"><Feedbackmodal /></Col>
                        <Col sm="auto" lg="3">
                            <p className="text-uppercase">
                                <strong>
                                    <FormattedMessage id="Footer.headline" />
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    <FormattedMessage id="Footer.company" />
                                </strong>
                                <br />
                                <FormattedMessage id="Footer.location" />
                            </p>
                            <p>
                                <a href="tel:+468203310"><FormattedMessage id="Footer.phone" /></a>
                                <br />
                                <a href="mailto:info@envirio.co"><FormattedMessage id="Footer.email" /></a>
                            </p>
                        </Col>
                        <Col sm lg="3">
                            <p className="text-uppercase">
                                <strong>
                                    <FormattedMessage id="Footer.secondheadline" />
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    <FormattedMessage id="Footer.firstcompany" />
                                </strong>
                                &nbsp;
                                <FormattedMessage id="Footer.and" />
                                <br />
                                <span className="text-nowrap">
                                    <strong>
                                        <FormattedMessage id="Footer.secondcompany" />
                                    </strong>
                                    <small>
                                        <FormattedMessage id="Footer.secondcompanyslogan" />
                                    </small>
                                </span>
                            </p>
                            <p>
                                <a href="https://www.vinnova.se/en" target="_blank" rel="noopener noreferrer">
                                    <FormattedMessage id="Footer.abouturltext" />
                                </a>
                                <br />
                                <a href="https://www.vinnova.se/en/p/margin-and-collateral-network-marconet/" target="_blank" rel="noopener noreferrer">
                                    <FormattedMessage id="Footer.aboutfundingurltext" />
                                </a>
                            </p>
                            <div className="version-info">
                                {version}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        )
    }
}
