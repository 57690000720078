/**
 * Authentication module
 *
 * @module utils/auth
 *
 * @requires NPM:axios
 * @requires module:utils/config
 * @requires module:utils/stats
 * @requires module:utils/api
 * @requires module:utils/global
 */

import axios from 'axios';
import config from './config';
import stats from './stats';
import api from './api';
import { global } from './global';

/**
 * The Authenticator class supports user authentication through API calls and bearer token. All logic and states resides with the API.
 */
class Authenticator {

    /**
    * The constructor just defines some internal variables
    */
    constructor() {
        this._fetched = false;
        this._token = undefined;
        this._userName = undefined;
        this._user = undefined;
        this._cb = [];
    }

    /**
    * Run authentication towards the API with given uuid and pw. Session token is stored in the sessionStorage
    * 
    * @param {Object} ptr - object pointer (this) to allow the callback function to retaining calling object's scope
    * @param {string} user_id - user id to be logged in
    * @param {string} user_pw - user password for the login
    * @param {Requester~requestCallback} cb - callback after the login has completed or failed cb(this, authorized: bool, other_error: bool)
	* @return {void} result is provided via callback when the API call has completed
    */
    authenticate(ptr, user_id, user_pw, cb) {
        const authUser = {
            authName: user_id,
            authPW: user_pw
        };

        stats.sendStats("api", "POST/auth/" + user_id);
        axios.post(config.apiUrl + "/auth", authUser, this.getAuthHeader())
            .then((res) => {
                console.log('Successful login');
                global.token = res.data;
                sessionStorage.setItem('authtoken', res.data);
                sessionStorage.setItem('username', authUser.authName);
                this.getUserInfo();
                cb(ptr, true, false);
            }).catch((e) => {
                const err_status = e.response ? e.response.status : (e.request ? 504 : 500);
                console.log('Bad login, status ' + err_status);
                this.removeToken();
                cb(ptr, false, err_status !== 401);
            });
    }

    /**
    * Gets the current API session token 
    * @param {function} cb - callback to run after token has been successfully fetched
	* @return {string} token as a string
    */
    getToken(cb = undefined) {
        if (cb) {
            this._cb.push(cb);
        }
        if (!this._fetched) {
            // Get token from local storarge
            this._fetched = true;
            this._token = sessionStorage.getItem('authtoken');
            this._userName = sessionStorage.getItem('username');
            global.token = this._token;
            if (this._token) {
                // Get user info from API
                var options = { filter: "name:\"" + this._userName + "\"" };
                api.list("/user", options).then(res => {
                    this._user = res[0];
                    // console.log("User info:", this._user);
                    options = { filter: "name:\"" + this._user.organisation + "\"" };
                    api.list("/organisation", options).then(res => {
                        this._user.organisation = res[0];
                        global.user = this._user;
                        // console.log("Company info:", this._user);
                        global.load(); // Get the data that should be globally available
                        if (this._cb.length) {
                            for (var i = 0; i < this._cb.length; i++) {
                                this._cb[i](this._user);
                            }
                            this._cb = [];
                        }
                    });
                });
            }
        }
        return global.token;
    }

    /**
    * Gets the name of the current logged in user 
	* @return {string} user name as a string
    */
    getUserName() {
        this.getToken();
        return global.user ? global.user.name : undefined;
    }

    /**
    * Gets the information about the current logged in user 
	* @return {Object} user info as an object
    */
    getUserInfo(cb = undefined) {
        this.getToken(cb);
        return global.user;
    }

    /**
     * Revokes the current API session token 
     * 
     * @return {void}
     */
    removeToken() {
        global.token = undefined;
        global.user = undefined;
        sessionStorage.removeItem('authtoken');
        sessionStorage.removeItem('username');
        this._fetched = false;
        this._token = undefined;
    }

    /**
    * Checks if the user is logged in 
    * 
	* @return {boolean} logged in, true or false
    */

    loggedIn() {
        return this.getToken() ? true : false;
    }

    /**
    * Convenience function providing the API authorization header for Axios 
    * 
	* @return {string} authorization header
    */
    getAuthHeader() {
        const token = this.getToken();
        if (token) {
            return {
                headers: { 'Authorization': token }
            };
        };
    }

    /**
    * Sign out from the API and remove the session token 
    * 
    * @param {Function} cb - callback after the logout has completed
	* @return {void}
    */
    logOut(cb) {
        if (this.loggedIn()) {
            console.log('Logging out');

            stats.sendStats("api", "DELETE/auth");
            axios.delete(config.apiUrl + "/auth", this.getAuthHeader())
                .then((res) => {
                    cb();
                }).catch((e) => {
                    console.log("Error. " + e);
                    cb();
                });
            this.removeToken();
        }
    }
};

// Instantiate and export global authentication object

var localAuth = new Authenticator();
export default localAuth;