// -1 api not found, 0 found
// www.marconet.eu, www.marconet.se 
let apiUrl = '';
let wsUrl = '';
if (window.location.hostname.indexOf("www.marconet.eu") === 0 || window.location.hostname.indexOf("www.marconet.se") === 0) {
    apiUrl = 'https://api.marconet.eu';
    wsUrl = 'wss://api.marconet.eu';
} else {
    apiUrl = 'http://' + window.location.hostname + ':3000';
    wsUrl = 'ws://' + window.location.hostname + ':3000';
};

let config = { 
    apiUrl: apiUrl,
    wsUrl: wsUrl 
};

export default config;