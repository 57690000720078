/**
 * App: Display an image in a modal
 * 
 * @module components/modal-show-image
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/modalcomp
 */

import React from 'react';
import { Image, Modal, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';
import ModalComp from "../utils/modalcomp";

/**
 * Display an image in a modal
 * 
 * @param {Object} props React properties
 * @param {boolean} props.show show (or hide) modal
 * @param {Function} props.onHide pass in a function that changes props.show to false
 * @param {string} props.src Source image to display
 */
export default function ModalShowImage(props) {
    return (
        <ModalComp size="lg" show={props.show} onHide={props.onHide} name={"show "+props.src}>
            <Modal.Body onClick={props.close}>
                <Image width="100%" src={props.src} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={props.close}>
                    <FormattedMessage id="Close" />
                </Button>
            </Modal.Footer>
        </ModalComp>
    )
}
