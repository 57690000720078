/**
 * App: Cancel ongoing collateral request
 * 
 * @module components/modal-colr-cancel
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/api
 * @requires module:utils/modalcomp
 */

import React, { Component } from 'react';
import { Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import api from '../utils/api';
import Modalcomp from "../utils/modalcomp";
import PostMessage from "../utils/post-message";
import { Redirect } from 'react-router-dom';
/**
 * Show the form in a modal with cancel information
 *
 * @class CancelModal
 * @extends {Component} 
 */
export default class CancelModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            confirmed: false,
            textvalue: '',
            confirm: false,
            submitted: false,
            validated: false,
            transaction: this.props.transaction
        };
        this.onChangeValue = this.onChangeValue.bind(this);
        this.toggle = this.toggle.bind(this);
        this.confirmCancel = this.confirmCancel.bind(this);
    }

    // Go to the correct Dashboard when selected
    goDashboard() {
         window.location.replace('/dashboard');
        //this.followLink('/dashboard')
    }

    followLink = (link) => {
        if (link && link.length > 0)
            this.setState({ link: link })
    }

    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     */
    toggle() {
        this.setState({
            open: !this.state.open,
            response: false,
            confirm: false,
            submitted: false
        });


        //this.goDashboard();
        // Clear everything if modal is closed and no error
        /*  if (this.state.errormessage) {
              this.setState({
                  open: true,
                  submitted: false,
                  response: false,
                  confirm: false
              });
          } else {
              this.setState({
                  submitted: false,
                  response: false,
                  validated: false,
                  confirm: false
              });
          }*/
    }

    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     */
    closeAfterDelete() {
        // Clear everything if modal is closed and no error
        this.setState({
            open: false,
            submitted: false,
            response: false,
            confirm: false
        });
        this.goDashboard();
    }

    confirmIntentinon() {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    confirmCancel(e) {
        e.preventDefault();
        //api delete
        const code = e.keyCode || e.which;
        if (!this.state.validated || code === 13) {
        } else if (this.state.validated && this.state.textvalue) {
            let body = {
                message: this.state.text
            }

            api.update("/colr/cancel/" + this.state.transaction._id, body)
                .then(res => {
                    this.setState({
                        open: true,
                        submitted: true,
                        textvalue: '',
                        confirm: true,
                        response: "CcpTransaction.details.cancel.response.sucess." + this.state.transaction.name
                    });
                   // this.goDashboard();
                })
                .catch(err => {
                    this.setState({
                        open: true,
                        textvalue: '',
                        submitted: false,
                        confirm: true,
                        response: "CcpTransaction.details.cancel.response.error." + this.state.transaction.name,
                        errormessage: err.message
                    });
                });
        } else {
            this.setState({
                open: false,
                submitted: false,
                value: null
            });
        }
    }

    /**
     * Compare field value with value in transaction
     * @class onChangeValue
     * @param {*} e 
     */
    onChangeValue(e) {
        let textdata = e.target.value;
        let result = e.target.value.localeCompare(this.state.transaction.marginPos);
        // if 0 they are the same and validated true
        if (result === 0) {
            this.setState({
                validated: true,
                textvalue: textdata
            })
        }
        this.setState({
            textvalue: textdata
        })
    }

    postMessage = (evt) => {
        this.setState({
            text: evt.target.value
        });
    }

    render() {

        if (this.state.link && this.state.submitted) {
            return <Redirect to={this.state.link} />
        }
        const { response, submitted, validated, errormessage,confirm } = this.state;
        let transaction = this.state.transaction;
        if (!transaction)
            return null;
        return (
            <>
                <Button variant="outline-danger" className="mb-1" block size="sm" onClick={this.toggle}>
                    <FormattedMessage id="Action.cancel.button" />
                </Button>
                <Modalcomp show={this.state.open} parentAction={this.toggle} titleIntl={"Action.cancel.button"} backdrop={'static'}>
                    <>
                        
                            {!submitted && !confirm ? (
                                <>
                               <Form noValidate onSubmit={this.confirmCancel}>
                                    <Modal.Body>
                                        <React.Fragment>
                                            <Row className="mt-3">
                                                <Col>
                                                    <h4> <FormattedMessage id={"CcpTransaction.details.cancel.headline." + transaction.name} /> {transaction.marginPos}
                                                    </h4>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FormattedMessage id="CcpTransaction.details.cancelfinalwarning" /> {transaction.name} for<b> {transaction.marginPos}</b>?
                                                    </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col>
                                                    <Form.Label><FormattedMessage id="CcpTransaction.details.cancelconfirm" /></Form.Label>
                                                    <Form.Group controlId="textvalue">
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.textvalue}
                                                            onChange={this.onChangeValue}
                                                            required
                                                            autoFocus
                                                        />
                                                        <b className="text-danger">{!validated && this.state.Error}</b>
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid input.
                                                            </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <PostMessage onTextChange={this.postMessage} />
                                        </React.Fragment>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="outline-danger" className="mb-1" size="sm" type="submit">
                                            <FormattedMessage id="Confirm" />
                                        </Button>
                                    </Modal.Footer>
                                    
</Form>                                    
</>
                               

                            ) : (
                                    <>
                                        <Modal.Body>
                                            <React.Fragment>
                                                <Row className="mt-3">
                                                    <Col>
                                                        <h4>
                                                            <FormattedMessage id={"CcpTransaction.details.cancel.headline." + transaction.name} /> {transaction.marginPos}
                                                        </h4>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>

                                                        {/* Show response messages and eventual error */}
                                                        {response &&
                                                            <>
                                                                <p><b>{errormessage}</b></p>

                                                                <b>
                                                                    <FormattedMessage id={response} defaultMessage={response}
                                                                        values={{
                                                                            a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                                        }}
                                                                    />
                                                                </b>
                                                            </>}
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        </Modal.Body>

                                        <Modal.Footer>Close
                                            <Button variant="outline-danger" className="mb-1" size="sm" onClick={this.closeAfterDelete}>
                                                <FormattedMessage id="Close" />
                                            </Button>
                                        </Modal.Footer>
                                    </>
                                )}
                        
                    </>
                </Modalcomp>
            </>
        )
    }
}