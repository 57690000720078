/**
 * App: Display a project blog fetched from API /blog
 * @module components/info-blog
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-router-dom
 * @requires NPM:react-intl
 * @requires NPM:@fortawsome
 * @requires module:locales/language
 * 
 * @requires module:components/modal-show-image
 */

import React, { Component } from 'react';
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import lang from "../locales/language";

import ModalShowImage from "./modal-show-image";

library.add(faCircle);

/**
 * Subview to display the project blog
 *
 * @class InfoBlog
 * @extends {Component}
 * 
 * @param {Object} props React properties
 * @param {integer} props.background Background color of the blog section
 * @param {Array} props.allBlogPosts All home page blog posts
 * @param {boolean} props.pageLinks If true, make the heading a link to the full page post
 */
export default class InfoBlog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expand: props.expand ? props.expand : -1,
            showImage: false,
            image: undefined
        };
    }

    toggleFullText = (id) => {
        this.setState({ 
            expand: this.state.expand === id ? undefined : id
        });
    }

    toggleShowImage = (image) => {
        this.setState({
            showImage: !this.state.showImage,
            image: this.state.showImage ? undefined : image
        });
    }

    /** 
     * Render blog posts 
     */
    render() {
        var i = 0;
        const collapseStyle = { height: "105px", overflow: "hidden" };
        return (
            <React.Fragment>
                <Container className="mt-0 pt-4 mb-0 pb-4">
                    <h1 className="my-3">
                        <FormattedMessage id="Blog.title" />
                    </h1>
                    {this.props.allBlogPosts && this.props.allBlogPosts.map(post => {
                        const expanded = post._id === this.state.expand;
                        const postDate = lang.elapsed2str(post.date);
                        return (
                            <React.Fragment key={i++}>
                                <div style={!expanded ? collapseStyle : null} className="mt-3">
                                    {post.image &&
                                        <Image onClick={() => { this.toggleShowImage(post.image) }} className="float-right ml-2" height="80px" src={post.image} />
                                    }
                                    <div onClick={() => { this.toggleFullText(post._id) }}>
                                        <h4 className={expanded ? "" : "overflow-ellipsis"}>
                                            {this.props.pageLinks ?
                                                <Link to={'/blogpost/' + post.link} className="text-body">{post.title}</Link>
                                                :
                                                post.title
                                            }
                                        </h4>
                                        <small><FontAwesomeIcon className={"text-" + post.color} icon={["fas", "circle"]} />
                                            <span className="text-uppercase text-muted">
                                                &nbsp;
                                                <FormattedMessage id={"Blog.type." + post.type} />
                                                &nbsp;
                                            </span>
                                        </small>
                                        <span dangerouslySetInnerHTML={{ __html: post.body }} style={{ zIndex: 1, position: "relative" }} />
                                    </div>
                                </div>
                                <div className="text-right pb-0 border-bottom" onClick={() => { this.toggleFullText(post._id) }} style={expanded ? {} : { marginTop: "-1.5rem", zIndex: 3, position: "relative", background: this.props.background + "bf" }}>
                                    <small className={"text-muted pb-0"} style={{ background: this.props.background }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <FormattedMessage id="Blog.lastupdated" />
                                        &nbsp;
                                        {postDate}
                                    </small>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </Container>

                <ModalShowImage src={this.state.image} show={this.state.showImage} onHide={this.toggleShowImage} />}

            </React.Fragment>
        )
    }
}
