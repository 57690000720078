/**
 * App: Display transaction details
 * 
 * @module components/details-progress
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/collapse-icon
 * @requires module:locales/language
 */

import React, { Component } from 'react';
import { Col, Row, Collapse } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CollapseIcon } from '../utils/collapse-icon';
import lang from "../locales/language";

const widthLeft  = { xs: 7, sm: 6, md: 5, lg: 5, xl: 5 };
const widthRight = { xs: 5, sm: 4, md: 3, lg: 3, xl: 3 };

/**
 * Subview of transaction detils page: Section shows details of the transaction progress
 *
 * @class TransactionProgress
 * @extends {Component}
 */
export default class TransactionProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    toggleCollapse = () => {
        this.setState({ show: !this.state.show });
    }

    render() {
        const transaction = this.props.transaction;
        if(!transaction) return null;

        return (
            <>
                <Row onClick={this.toggleCollapse} className="mt-4">
                    <Col className="pl-2">
                        <h5>
                            <CollapseIcon in={this.state.show} />
                            &nbsp;
                            <FormattedMessage id="CcpTransaction.details.progress" />
                        </h5>
                    </Col>
                </Row>
                <Collapse in={this.state.show}>
                    <div>
                        {transaction.stateLog.map((item, i) => {
                            return (
                                <Row key={i}>
                                    <Col {...widthLeft}>
                                        <b>
                                            -&nbsp;
                                            <FormattedMessage id={item.state === 'requested' ? item.state + "." + transaction.name : item.state} />
                                        </b>
                                    </Col>
                                    <Col className="text-center" {...widthRight}>
                                        {item.timeStamp ? lang.time2str(item.timeStamp) : "-"}
                                    </Col>
                                </Row>
                            );

                        })}
                        <Row>
                            <Col {...widthLeft}>
                                <b>
                                    -&nbsp;<FormattedMessage id="deadline" />
                                </b>
                            </Col>
                            <Col className="text-center" {...widthRight}>
                                {lang.time2str(transaction.deadline)}
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </>
        )
    }
}