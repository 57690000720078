/**
 * Utils: Display a modal dialog
 * 
 * @module utilis/modalcomp
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/stats
 */

import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import stats from '../utils/stats.js';

/**
 * ModalComp component: Pass modal body and footer as children. The close callback should set props.show to false
 *
 * @param {Object} props React properties
 * @param {boolean} props.show show (or hide) modal
 * @param {Function} props.onHide pass in a function that changes props.show to false
 * @param {string} props.titleIntl HeaderIntl Id 
 * @param {string} props.titleText Header title
 * @param {string} props.name Modal name for stats
 * @param {'lg'|'sm'} props.size You can specify a bootstrap large (lg) or small(sm) modal by using the "size" prop
 * @param {'static'|boolean} props.backdrop Include a backdrop component, 'static' | true | false. Specify 'static' for a backdrop that doesn't trigger an "onHide" when clicked.
 * @param {ReactChildren} props.children modal body content provided as JSX between <ModalComp> and </ModalComp>.
*/

export default function ModalComp(props) {
    // Keep compatibility between new "close" and old "parentAction" callbacks
    const onHide = props.onHide || props.parentAction;

    // Send stats when showing the modal. Use state to track it.
    const [statsSent, setStatsSent] = useState(false);
    if (props.show) {
        if (!statsSent) {
            stats.sendStats("modal", window.location.pathname + "/" + props.name ? props.name : props.titleIntl);
            setStatsSent(true);
        }
    } else {
        statsSent && setStatsSent(false);
    }

    if(!props.show) return null;
    return (
        <React.Fragment>
            <Modal show={true} onHide={onHide} size={props.size} backdrop={props.backdrop}>
                {(props.titleText || props.titleIntl) &&
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {props.titleIntl ? <FormattedMessage id={props.titleIntl} /> : props.titleText}
                        </Modal.Title>
                    </Modal.Header>
                }
                {props.children}
            </Modal>
        </React.Fragment>
    );
}