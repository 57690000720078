/**
 * App: Display the public home page
 * 
 * @module components/home
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/stats
 * 
 * @requires module:components/info-blog
 * @requires module:components/home-interest
 */

import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron } from "react-bootstrap";
import {FormattedMessage} from 'react-intl';
import stats from '../utils/stats.js';

import InfoBlog from "./info-blog";
import RegInterest from "./home-interest";

/**
 * The public homepage
 * 
 * @class HomePage
 * @extends {Component}
 * 
 * @param {Array} props.allBlogPosts All home page blog posts
 */
export default class HomePage extends Component {
	constructor(props) {
		super(props);
		stats.sendStats();
	}

    render() {
        return (
            <React.Fragment>
                <Jumbotron fluid className="min-vh-100 d-flex flex-column justify-content-center">
                    <Container className="col-12 p-0">
                        <Row className="grid-divider text-white text-center">
                            <Col sm={{ order: 2, span: 7 }} className="text-sm-left d-flex flex-column justify-content-center">
                                <h1 className="jumboheadline">MarcoNet</h1>
                            </Col>
                            <Col sm={{ order: 1, offset: 2, span: 3 }} className="text-sm-right d-flex flex-column justify-content-center">
                                <h3 className="description"><FormattedMessage  id="Home.subheading"  defaultMessage="Margin and collateral network"/></h3>
                            </Col>
                        </Row>  
                    </Container>
                </Jumbotron>

                <Container className="mt-5">
                <h1><FormattedMessage  id="Home.welcome" defaultMessage="Welcome to MarcoNet"/></h1>
                <p><FormattedMessage  id="Home.MaincontentP1" /></p>
                <p><FormattedMessage  id="Home.MaincontentP2" /></p>
                <p><FormattedMessage  id="Home.MaincontentP3" /></p>
                <p><FormattedMessage  id="Home.MaincontentP4" /></p>
                </Container>

                <div className="alt-background">
                    <InfoBlog background="#F4F2EC" allBlogPosts={this.props.allBlogPosts} pageLinks={true}/>
                </div>

                <RegInterest />
            </React.Fragment>
        )
    }
}
