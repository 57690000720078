/**
 * Component: Form with Textarea
 * 
 * @module utils/post-message
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 */

import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

/**
 * A form with a textarea that callbacks with a function that it has been handed in the onTextChange prop.
 * Import it and the use it in jsx:  <PostMessage onTextChange={this.postMessage}  />
 * 
 * @property {string} onTextChange - a function that handles the callback. Example:
 *  postMessage = (evt) =>{
 *       this.setState({
 *           chatmessage:  evt.target.value
 *       });
 *   }
 * Then for example use this.state.chatmessage in your API.
 * @property {string} headingIntl - label default: CcpTransaction.escalation.approve.body 
 */
export default class PostMessage extends Component {
    render() {
        const { onTextChange, headingIntl } = this.props;
        return (
            <Form.Group controlId="formTextarea" className="mt-3">
                <Form.Label>
                    {!headingIntl ? (
                        <b><FormattedMessage id={'CcpTransaction.escalation.approve.body'} defaultMessage="Message" /></b>
                    ) : (
                       <b> <FormattedMessage id={headingIntl} defaultMessage="Message" /></b>
                    )
                }</Form.Label>
                <Form.Control
                    as="textarea"
                    type="text"
                    onChange={onTextChange} />
            </Form.Group>
        )
    }
}