/**
* App: Table display utility
* @module utils/countdown
*/

import React, { Component } from 'react';
import { Button } from 'react-bootstrap'

/**
 * Countdown button coloured based om remaning time.
 * Other props are passed down to the underlying Button.
 * 
 * @class CountdownButton
 * @extends {Component}
 * 
 * @param {number} props.remaining Either provide remining time in seconds
 * @param {string} props.deadline or provide the deadline in UTC milliseconds
 */
export class CountdownButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tick: Date.now()
		};
	}

	componentDidMount() {
		this.timerID = setInterval(
			() => this._tick(),
			1000
		);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	_tick() {
		this.setState({ tick: Date.now() })
	}

	render() {
		if(!this.props.deadline && !this.props.remaining) {
			return null;
		}

		let deadline = this.props.deadline ? this.props.deadline : this.state.tick + this.props.remaining * 1000 ;
		var remaining = deadline - this.state.tick;
		let color = remaining < 0 ? 'danger' : 'success';
		let size = this.props.size ? this.props.size : "sm";
		return <Button {...this.props} variant={color} size={size} disabled>
			{formatMillis2hhmmss(remaining)}
		</Button>;
	}
}

/**
 * React countdown component providing a hh:mm:ss counter continuing past the deadline
 *
 * @class Countdown
 * @extends {Component}
 * 
 * @param {integer} props.remining Either provide remining time in seconds
 * @param {integer} props.deadline or provide the deadline in UTC milliseconds
 */
export class Countdown extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this._tick();
		this.timerID = setInterval(
			() => this._tick(),
			1000
		);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	_tick() {
		if (!this.state.deadline) {
			// If the class was created without starting value, due to API promise, 
			// it needs to be checked every tick
			if (this.props.remaining || this.props.deadline) {
				let deadline = this.props.remaining ? Date.now() + this.props.remaining * 1000 : this.props.deadline;
				this.setState({ deadline: deadline });
			}
		}
		if (this.state.deadline) {
			this.setState({ remaining: this.state.deadline - Date.now()});
		}
	}

	render() {
		return formatMillis2hhmmss(this.state.remaining);
	}
}

function formatMillis2hhmmss(millis) {
	if (millis === undefined || isNaN(millis)) {
		return "-";
	} else {
		let sign = millis < 0 ? "-" : " ";
		const sec = Math.trunc(Math.abs(millis)/1000);
		let hoursleft = Math.trunc(sec / 3600);
		hoursleft = (hoursleft < 10 ? " " : "") + hoursleft + ":";
		let minleft = Math.trunc(sec % 3600 / 60);
		minleft = (minleft < 10 ? "0" : "") + minleft + ":";
		let secleft = sec % 3600 % 60;
		secleft = (secleft < 10 ? "0" : "") + secleft;

		return sign + hoursleft + minleft + secleft;
	}
}

export function makeDeadline(millis) {
	// If input is a date string, parse to millis
	if(typeof millis === 'string') {
		millis = Date.parse(millis);
	}

	const aDay = 24 * 60 * 60 * 1000;
	let today = Math.trunc(Date.now() / aDay) * aDay;
	let deadline = today + millis % aDay;

	return deadline;
}
