import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { IntlProvider } from 'react-intl';
import App from './App';
import * as serviceWorker from './serviceWorker';
import localeData from "./locales/messages.json";
import lang from './locales/language.js';
//import stats from './utils/stats.js';

// Try previosly set locale, browser locale and lastly fallback to 'en'
const fallbackLanguage = 'en';
var language = lang.getLanguage();
var messages = localeData[language];
if(!language || !messages) {
  language = fallbackLanguage;
  lang.setLanguage(language);
  messages = localeData[language];
}

//stats.sendStats("re-render");
console.log("Re-rendering the App"); 

ReactDOM.render(
  <IntlProvider locale={language} defaultLocale={fallbackLanguage} messages={messages}>
    <App />
  </IntlProvider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
