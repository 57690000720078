/**
 * App: Display first info page, after logging in
 * @module components/info
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/stats
 * 
 * @requires module:components/info-blog
 * @requires module:components/info-docs
 */

import React, { Component } from 'react';
import { Container } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import stats from '../utils/stats.js';

import InfoBlog from './info-blog';
import InfoDocs from './info-docs';

/**
 * Subview to display info about the project
 *
 * @class Info
 * @extends {Component}
 * 
 * @param {Array} props.allBlogPosts All home page blog posts
 */
export default class Info extends Component {
    constructor(props) {
        super(props);
        stats.sendStats();
    }

    render() {
        return (
            <React.Fragment>
                <Container className="mt-0 pt-5 mb-0 pb-4"> {/* General introduction */}
                    <h1><FormattedMessage id="Info.headline" defaultMessage="Building MarcoNet, with your help" /></h1>
                    <p><FormattedMessage id="Info.MaincontentP1" /></p>
                    <p><FormattedMessage id="Info.MaincontentP2" /></p>
                </Container>

                <div className="alt-background">
                    <InfoBlog background="#F4F2EC" allBlogPosts={this.props.allBlogPosts} />
                </div>

                <InfoDocs />

            </React.Fragment>
        )
    }
}
