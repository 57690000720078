/**
 * App: Show a feedbackform
 * 
 * @module components/feedbackform
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires NPM:history
 * 
 * @requires module:utils/auth
 * @requires module:utils/api
 * @requires module:utils/countdown
 */
import React, { Component } from 'react';
import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import api from '../utils/api.js';
import auth from './../utils/auth';
import { createBrowserHistory } from "history";

const history = createBrowserHistory()
/**
 * Feedbackform when users are logged in.
 * @export
 * @class Feedbackform
 * @extends {Component}
 */
export class Feedbackform extends Component {

    constructor(props) {
        super(props);
        console.log('history:', history.location.pathname);

        this.intl = props.intl;
        this.state = {
            page: '',
            category: '',
            subject: '',
            text: '',
            openinfo: false,
            show: props.show,
            pageRef: window.location.href
        }

        this.onChangePageName = this.onChangePageName.bind(this);
        this.onChangeCategory = this.onChangeCategory.bind(this);
        this.onChangeSubject = this.onChangeSubject.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    toggleModal = () => {
        this.setState({ open: !this.state.open });
    }

    onChangePageName(e) {
        this.setState({ page: e.target.value });
    }

    onChangeCategory(e) {
        this.setState({ category: e.target.value });
    }

    onChangeSubject(e) {
        this.setState({ subject: e.target.value });
    }

    onChangeText(e) {
        this.setState({ text: e.target.value });
    }

    toggleAPIInfo() {
        this.setState({
            openinfo: !this.state.openinfo
        });
    }

    closeModal = () => {
        this.props.parentAction();
    }

    toggleInfoModal = () => {
        this.setState({ openinfo: !this.state.openinfo });
    }

    onSubmit(e) {
        e.preventDefault();

        const postvalues = {
            "page": this.state.pageRef,
            "category": this.state.category,
            "subject": this.state.subject,
            "text": this.state.text
        }

        api.create("/feedback", postvalues)
            .then(res => {

                this.setState({
                    page: '',
                    category: '',
                    subject: '',
                    text: '',
                    open: false,
                    submitted: true,
                    response: "Feedback.successful" 
                });
            })
            .catch(err => {

                this.setState({
                    open: false,
                    openinfo: true,
                    submitted: false,
                    response: "Feedback.unsuccessful" 
                });
            });
    }

    render() {
        const { response, submitted } = this.state
        const selectCategory = this.intl.formatMessage({ id: "Feedback.categoryselect" });
        const enhancement = this.intl.formatMessage({ id: "Feedback.categoryenhancement" });
        const bug = this.intl.formatMessage({ id: "Feedback.categorybug" });
        const design = this.intl.formatMessage({ id: "Feedback.categorydesign" });
        const help = this.intl.formatMessage({ id: "Feedback.categoryhelp" });
        const question = this.intl.formatMessage({ id: "Feedback.categoryquestion" });
        const praise = this.intl.formatMessage({ id: "Feedback.categorypraise" });
        const other = this.intl.formatMessage({ id: "Feedback.categoryother" });
        const subjectPlaceholder = this.intl.formatMessage({ id: "Feedback.subjectplaceholder" });
        const textPlaceholder = this.intl.formatMessage({ id: "Feedback.textareaplaceholder" });

        return auth.loggedIn() ? (
            !submitted ? (
                <React.Fragment>
                    <Form onSubmit={this.onSubmit} >
                        <Modal.Body>
                            {response === "Feedback.unsuccessful" && <Form.Group controlId="formCategory">
                                <b className="text-danger">
                                    <FormattedMessage id={response} defaultMessage="Thanks for your feedback"
                                        values={{
                                            a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                        }}
                                    />
                                </b>
                            </Form.Group>}

                            <Form.Group className="mt-3" controlId="formCategory">
                                <p><FormattedMessage id="Feedback.pageref" defaultMessage="Page ref:" /> {this.state.pageRef}</p>
                                <Form.Label><FormattedMessage id="Feedback.category" defaultMessage="Category of feedback" /></Form.Label>

                                <Form.Control as="select" value={this.state.category} onChange={this.onChangeCategory} required>
                                    <option value="">{selectCategory}</option>
                                    <option value="enhancement">{enhancement}</option>
                                    <option value="bug">{bug}</option>
                                    <option value="design">{design}</option>
                                    <option value="help">{help}</option>
                                    <option value="question">{question}</option>
                                    <option value="praise">{praise}</option>
                                    <option value="other">{other}</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formSubject">
                                <Form.Label><FormattedMessage id="Feedback.subjectlabel" defaultMessage="Subject" /></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={subjectPlaceholder}
                                    value={this.state.subject}
                                    onChange={this.onChangeSubject}
                                    required />
                                <Form.Control.Feedback
                                    type="invalid">
                                    <FormattedMessage id="Feedback.subjectinvalid" defaultMessage="Please add a subject!" />
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formTextarea">
                                <Form.Label><FormattedMessage id="Feedback.textarealabel" defaultMessage="Your feedback" /></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    value={this.state.text}
                                    onChange={this.onChangeText}
                                    placeholder={textPlaceholder}
                                    required />
                                <Form.Control.Feedback type="invalid">
                                    <FormattedMessage id="Feedback.textareainvalid" defaultMessage="Please add feedback!" />
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="outline-primary" type="submit">
                                <FormattedMessage id="Feedback.submit" defaultMessage="Submit" />
                            </Button>
                        </Modal.Footer>
                    </Form>
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        <Modal.Body>
                            <Row className="mt-3 pb-4">
                                <Col>{response &&
                                    <FormattedMessage id={response} defaultMessage="Thanks for your feedback"
                                        values={{
                                            a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                        }}
                                    />}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={this.closeModal}>
                                <FormattedMessage id="Feedback.close" defaultMessage="Close" />
                            </Button>
                        </Modal.Footer>
                    </React.Fragment>
                )
        ) : (null)
    };

}

export default injectIntl(Feedbackform);