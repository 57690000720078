/**
 * App: Display form to register interest to be added to the mailing list
 * 
 * @module components/home-interest
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/modalcomp
 * @requires module:utils/stats
 * @requires module:utils/api
 */

import React, { Component } from 'react';
import { Container, Modal, Button, ProgressBar, Form, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ModalComp from "../utils/modalcomp";
import stats from '../utils/stats'
import api from '../utils/api'

/**
 * Present a short form with a submit button to register interest and to be added to mailing list
 *
 * @class RegInterest
 * @extends {Component}
 */
export default class RegInterest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            showModal: false
        }
    }

    onHide = () => {
        this.setState({ showModal: false });
    }

    onChangeEmail = e => {
        this.setState({ email: e.target.value });
    }

    onSubmit = e => {
        e.preventDefault();
        const session = stats.session();
        const newInterest = {
            email: this.state.email,
            language: session.language,
            session: session.uid,
        };

        api.create("/interest", newInterest)
            .then(res => {
                this.setState({
                    response: "Register.successful",
                    progress: undefined,
                    email: ''
                });
                clearInterval(this.timerID);
            }).catch(err => {
                this.setState({
                    response: "Register.unsuccessful",
                    progress: undefined
                });
                clearInterval(this.timerID);
            });

        this.setState({
            response: "Register.working",
            progress: 1,
            showModal: true
        });

        // Make progressbar progress
        this.timerID = setInterval(
            () => this._tick(),
            100
        );
    }

    _tick() {
        this.setState({ progress: this.state.progress + 2 % 100 })
    }

    render() {
        return (
            <Container className="mt-5">

                <ModalComp show={this.state.showModal} onHide={this.onHide} name="processing-interest">
                    <Modal.Body className="py-4 text-center">
                        <FormattedMessage id={this.state.response}
                            values={{
                                a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                            }}
                        />
                        <br />
                        {this.state.progress &&
                            <ProgressBar animated now={this.state.progress} />
                        }
                    </Modal.Body>
                    <Modal.Footer className="py-2">
                        <Button variant="primary" onClick={this.onHide}>
                            <FormattedMessage id="Close" />
                        </Button>
                    </Modal.Footer>
                </ModalComp>

                <h1><FormattedMessage id="Register.headline" /></h1>
                <Form onSubmit={this.onSubmit}>
                    <p><FormattedMessage id="Register.infotext" /></p>
                    <Form.Group>
                        <Col sm={10} md={8} lg={6} xl={5} className="pl-0">
                            <Form.Label>
                                <FormattedMessage id="Register.labelemail" />
                            </Form.Label>
                            <Form.Control
                                type="email"
                                autoComplete="section-sign-up email"
                                value={this.state.email}
                                onChange={this.onChangeEmail}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <br />
                    <Form.Group>
                        <Button variant="primary" type="submit">
                            <FormattedMessage id="Register.submit" />
                        </Button>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}