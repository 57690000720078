/**
 * App: Display a sign in form
 * 
 * @module components/modal-sign-in
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires NPM:fortawsome
 * @requires module:utils/modalcomp
 * @requires module:utils/auth
 */

import React, { Component } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modalcomp from "../utils/modalcomp";
import auth from '../utils/auth'

/**
 * 
 *  
 * @class SignIn
 * @extends {Component}
 */
export class SignIn extends Component {

    constructor(props) {
        super(props);

        this.intl = props.intl;
        this.toggle = this.toggle.bind(this);
        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangeUserPassword = this.onChangeUserPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            open: false,
            errors: [],
            user_name: '',
            user_password: '',
        }
    }

    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     * @method
     */
    toggle() {
        this.setState({
            open: !this.state.open
        });
        // this.isToggled();
    }

    /**
     * Set state when username changes
     * @method
     * @param {*} e 
     */
    onChangeUserName(e) {
        this.setState({ user_name: e.target.value });
    }

    /**
     * Set state for password
     * @method
     * @param {*} e 
     */
    onChangeUserPassword(e) {
        this.setState({ user_password: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        auth.authenticate(this, this.state.user_name, this.state.user_password, function (ptr, ok, api_error) {
            if (ok) {
                console.log('Signed in');
                window.location.replace('/');
            } else {
                const errors = ptr.validate(api_error);
                if (errors.length > 0) {
                    ptr.setState({ errors });
                }
            }
        });
    }

    /**
     * Validate 
     * @param {*} api_error 
     */
    validate(api_error) {
        // we are going to store errors for all fields
        // in a signle array
        const errors = [];

        if(api_error)
            errors.push(this.intl.formatMessage({id:"Login.apiError"}));
        else
            errors.push(this.intl.formatMessage({id:"Login.wrongUserPassword"}));
        return errors;
    }

    componentDidUpdate(prevProps) {
        if(this.props.show && !prevProps.show) {
            this.setState({open: true});
        }
    }

    render() {
        const { errors } = this.state;
        const userNamePlaceholder = this.intl.formatMessage({id:"Login.userNamePlaceholder"});
        const passwordPlaceholder = this.intl.formatMessage({id:"Login.passwordPlaceholder"});

        return (
            <Modalcomp size="sm" show={this.state.open} parentAction={this.toggle} titleText={'Contacts at ' + this.state.orgname} titleIntl={"Login.headline"} >
                <Form onSubmit={this.onSubmit}>
                <Modal.Body>
                    {errors.map(error => (
                        <p key={error} className="bg-warning text-white"> {error}</p>
                    ))}
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label><FormattedMessage id="Login.userName" defaultMessage="User name" /></Form.Label>
                        <Form.Control 
                            placeholder={userNamePlaceholder}
                            value={this.state.user_name}
                            autoComplete="section-login username"
                            autoFocus
                            onChange={this.onChangeUserName} />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label><FormattedMessage id="Login.password" defaultMessage="Password" /></Form.Label>
                        <Form.Control type="password"
                            placeholder={passwordPlaceholder}
                            value={this.state.user_password}
                            onChange={this.onChangeUserPassword}
                            autoComplete="section-login current-password" />
                    </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="outline-primary" type="submit">
                        <FormattedMessage id="Login.submit" defaultMessage="Submit" />
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modalcomp>
        )
    }
}

export default injectIntl(SignIn);