/**
 * App: Load and display a Container with margin positions as a table
 * 
 * @module components/table-marginpos
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires module:utils/tableview
 * @requires module:utils/global
 * @requires module:utils/api
 */

import React, { Component } from 'react';
import { Container } from 'react-bootstrap'
import { TableView } from '../utils/tableview';
import { global } from '../utils/global';

/**
 * Subview to display margin position overview
 *
 * @class MarginPosTable
 * @extends {Component}
 * 
 * @param {string} props.headingIntl React-Intl id for the heading over the table
 * @param {Array}  props.allMarginPositions Contains source data, array with all marginpositions
 * @param {string} props.marginPos Optional string with name of the margin position to display
 * @param {string} props.giver Optional string with the clearing member to select
 * @param {string} props.taker Optional string with the CCP to select
 */
export class MarginPosTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			format: [
				{
					field: "position",
					headerIntl: "Table.marginPos.position", headerAlign: "text-left text-uppercase",
					bodyType: "Link", bodyAlign: "text-left"
				},
				{
					field: "collateral",
					headerIntl: "Table.marginPos.collateral", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
					bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
				},
				{
					field: "im",
					headerIntl: "Table.marginPos.im", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
					bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
				},
				{
					field: "vm",
					headerIntl: "Table.marginPos.vm", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
					bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
				},
				{
					field: "netPosition",
					headerIntl: "Table.marginPos.netPosition", headerAlign: "text-right text-uppercase",
					bodyType: "NumberRed", bodyAlign: "text-right"
				}
			],
			data: []
		}
	}

	static getDerivedStateFromProps(props) {
		var data = [];
		let isCCP = global.user && global.user.organisation && (global.user.organisation.role === 'ccp');

		if (props.allMarginPositions) {
			// Iterate through the margin positions and prepare display data
			props.allMarginPositions.forEach(element => {
				var keep = true;
				keep = props.marginPos && props.marginPos !== element.name ? false : keep;
				keep = props.giver && props.giver !== element.giver ? false : keep;
				keep = props.taker && props.taker !== element.taker ? false : keep;
				if (keep) {
					var row = {
						position: [(isCCP ? element.giver : element.taker) + ": " + element.name, "/marginpos/" + element._id],
						collateral: element.collValue,
						im: element.margin[0].requirement,
						vm: element.margin[1].requirement,
						netPosition: element.netCollMargin
					}
					data.push(row);
				}
			});
		}
		
		return {
			data: data
		};
	}

	render() {
		return (
			<Container className="mt-5">
				<TableView headingIntl={this.props.headingIntl} format={this.state.format} data={this.state.data} />
			</Container>
		)
	}
}
