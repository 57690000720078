/**
 * App: Display confirm view
 * 
 * @module components/modal-colr-confirm
 * 
 * @requires NPM:react
 * @requires NPM:react-router-dom
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/api
 * @requires module:utils/modalcomp
 * @requires module:utils/tableview
 * @requires module:utils/post-message
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import api from '../utils/api';
import Modalcomp from "../utils/modalcomp";
import { numberRedFormat } from '../utils/tableview';
import PostMessage from "../utils/post-message";

/**
 * Show the form in a modal with confirm information
 *
 * @class ModalConfirm
 * @extends {Component}
 */
export default class ModalConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            confirmed: false,
            confirm: false,
            submitted: false,
            validated: false,
            transaction: this.props.transaction
        };
        this.toggle = this.toggle.bind(this);
        this.confirmIntentinon = this.confirmIntentinon.bind(this);
        this.confirmChange = this.confirmChange.bind(this);
        this.closeAfterDelete = this.closeAfterDelete.bind(this);
    }

    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     */
    toggle() {
        this.setState({
            open: !this.state.open
        });

        // Clear everything if modal is closed and no error
        if (this.state.errormessage) {
            this.setState({
                open: true,
                submitted: false,
                response: false,
                confirm: false
            });
        } else {
            this.setState({
                submitted: false,
                response: false,
                validated: true,
                confirm: false
            });
        }
    }

    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     */
    closeAfterDelete() {
        this.setState({
            open: false
        });

        // Clear everything if modal is closed and no error
        this.setState({
            submitted: false,
            response: false,
            confirm: false
        });
        //  window.location.replace('/dashboard');
        this.followLink('/dashboard')
    }

    followLink = (link) => {
        if (link && link.length > 0)
            this.setState({ link: link })
    }

    /**
     * confirmIntentinon method for button: confirm
     */
    confirmIntentinon() {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    /**
     * confirmChange method for button confirm. Change transaction object, update transaction. 
     * List marginposition for the current transactions margin position, update collateral in the margin position
     * and update margin position if there is no error.
     * @param {*} e 
     */
    confirmChange(e) {
        e.preventDefault();

        if (this.state.validated) {

            let body = {
                message: this.state.text
            }

            api.update("/colr/complete/" + this.state.transaction._id, body
            ).then(res => {
                this.setState({
                    open: true,
                    submitted: true,
                    textvalue: '',
                    confirm: true
                });
                this.setState({ response: "CcpTransaction.details.confirm.response.sucess" })
            })
                .catch(err => {
                    this.setState({
                        open: true,
                        textvalue: '',
                        submitted: false,
                        confirm: true
                    });
                    this.setState({
                        response: "CcpTransaction.details.confirm.response.error",
                        errormessage: err.message
                    })
                });
        }
    }

    postMessage = (evt) => {
        this.setState({
            text: evt.target.value
        });
    }

    render() {
        if (this.state.link) {
            return <Redirect to={this.state.link} />
        }
        const { response, submitted, validated } = this.state;
        let transaction = this.state.transaction;
        if (!transaction)
            return null;
        return (
            <>
                <Button variant="outline-success" className="mb-1" block size="sm" onClick={this.toggle}>
                    <FormattedMessage id="Confirm" />
                </Button>
                <Modalcomp show={this.state.open} parentAction={this.toggle} titleIntl={"Confirm"} backdrop={'static'}>
                    {!submitted ? (
                        <>
                            <Form noValidate validated={validated} onSubmit={this.confirmChange}>
                                <Modal.Body>
                                    <React.Fragment>
                                        <Row className="mt-3">
                                            <Col>
                                                <h4><FormattedMessage id={"CcpTransaction.escalation.confirmreceipt.heading"} />
                                                    &nbsp;{transaction.name} {transaction.marginPos}</h4>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <TransactionDetails transaction={transaction} />
                                            </Col>
                                        </Row>
                                        <PostMessage onTextChange={this.postMessage} />
                                    </React.Fragment>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-primary" className="mb-1" size="sm" type="submit">
                                        <FormattedMessage id="Confirm" />
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </>
                    ) : (
                            <React.Fragment>
                                <Modal.Body>
                                    <Row>
                                        <Col>
                                            <>
                                                <p><b>{this.state.errormessage}</b></p>
                                                {response &&
                                                    <b>
                                                        <FormattedMessage id={response} defaultMessage={response}
                                                            values={{
                                                                a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                            }}
                                                        />
                                                    </b>}
                                            </>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-danger" className="mb-1" size="sm" onClick={this.closeAfterDelete}>
                                        <FormattedMessage id="Close" />
                                    </Button>
                                </Modal.Footer>
                            </React.Fragment>
                        )
                    }
                </Modalcomp>
            </>
        )
    }
}


/**
 * Subview of New margin position table component
 * @class TransactionDetails
 * @extends {Component}
 */
class TransactionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    }

    toggleCollapse = () => {
        this.setState({ show: !this.state.show });
    }

    render() {
        let transaction = this.props.transaction;
        // console.log('tr: ', transaction)
        if (!transaction) {
            return null;
        }

        return (
            <>
                <Row className="mt-2">
                    <Col>
                        <b>
                            <FormattedMessage id={transaction.netCollMargin < 0 ? "deficit" : "surplus"} />
                        </b>
                    </Col>
                    <Col className="text-right">
                        {numberRedFormat(transaction.netCollMargin)}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-left">
                        <b>
                            <FormattedMessage id="Table.holdings.collValue" />
                        </b>
                    </Col>
                    <Col className="mb-1 border-bottom text-right">
                        {numberRedFormat(transaction.collValueChange)}
                    </Col>
                </Row>

                <Row>
                    <Col className="text-left">
                        <b><FormattedMessage id="CcpTransaction.escalation.approve.collvaluechange" /></b>
                    </Col>
                    <Col className="text-right mb-4">
                        {numberRedFormat(transaction.netCollMargin + transaction.collValueChange)}
                    </Col>
                </Row>

            </>
        )
    }
}