/**
 * App: Display Dashboard 
 * 
 * @module components/dashboard
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/global
 * 
 * @requires module:components/table-transactions
 * @requires module:components/table-marginpos
 * @requires module:components/table-assets
 */

import React, { Component } from 'react';
import { Container } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';
import {global} from '../utils/global';

import {TransactionTable} from './table-transactions'
import {MarginPosTable} from './table-marginpos'
import {AssetTable} from './table-assets'

/**
 * Present an actionable dashboard for a clearing member
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 */
export default class Dashboard extends Component {
	render() {
		const org = global.user && global.user.organisation && global.user.organisation.name;
		const account = global.user && global.user.organisation && global.user.organisation.account;
		return (
			<React.Fragment>
				<Container className="mt-5">
					<h1><FormattedMessage id="Dashboard.heading" defaultMessage="Dashboard" /> {org}</h1>
				</Container>

				<TransactionTable 
					myHeadingIntl="Heading.myTransactions" 
					headingIntl="Heading.allCollRequests"
					allCollRequests={this.props.allCollRequests} />

				<MarginPosTable 
					headingIntl="Heading.allMarginPositions" 
					allMarginPositions={this.props.allMarginPositions} />

				<AssetTable 
					headingIntl="Heading.allAssets" 
					account={account} />
			</React.Fragment>
		)
	}
}
