/**
 * App: Display details of obligations towards a CCP
 * 
 * @module components/overview-counterparty
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/collapse-icon
 * 
 * @requires module:components/table-transactions
 * @requires module:components/table-marginpos
 * @requires module:components/table-holdings
 */

import React, { Component } from 'react';
import { Container, Collapse } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CollapseIcon } from '../utils/collapse-icon'

import { TransactionTable } from './table-transactions'
import { MarginPosTable } from './table-marginpos'
import { HoldingTable } from './table-holdings'

/**
 * Present an overview of a counterparty
 *
 * @export
 * @class CounterpartyOverview
 * @param {string} props.headingIntl React-Intl id for the section heading 
 * @param {string} props.counterpartyName Name of the counterparty name to display
 * @param {Array} props.allCollRequests Array with collateral requests to display
 * @param {Array} props.allMarginPositions Array with maring positions to display
 * @extends {Component}
 */
export class CounterpartyOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    }

    /** Toggle display/hide counterparty overview */
    toggleCollapse = () => {
        this.setState({show: !this.state.show});
    }

    render() {
        return (
            <React.Fragment>
                {this.props.headingIntl &&
                    <Container className="my-5" onClick={this.toggleCollapse}>
                        <h1>
                            <CollapseIcon in={this.state.show} />
                            &nbsp;
                            <FormattedMessage id={this.props.headingIntl} />
                            &nbsp;
                            {this.props.counterpartyName}
                        </h1>
                    </Container>
                }

                <Collapse in={this.state.show}>
                    <>
                        <TransactionTable 
                            headingIntl="Heading.allCollRequests" 
                            allCollRequests={this.props.allCollRequests}
                            taker={this.props.counterpartyName} />

                        <TransactionTable 
                            headingIntl="Heading.allCollRequests" 
                            allCollRequests={this.props.allCollRequests}
                            giver={this.props.counterpartyName} />

                        <MarginPosTable 
                            headingIntl="Heading.allMarginPositions" 
                            allMarginPositions={this.props.allMarginPositions}
                            taker={this.props.counterpartyName} />
    
                        <MarginPosTable 
                            headingIntl="Heading.allMarginPositions" 
                            allMarginPositions={this.props.allMarginPositions}
                            giver={this.props.counterpartyName} />
    
                        <HoldingTable 
                            headingIntl="Heading.allCollateral" 
                            allMarginPositions={this.props.allMarginPositions}
                            taker={this.props.counterpartyName} />

                        <HoldingTable 
                            headingIntl="Heading.allCollateral" 
                            allMarginPositions={this.props.allMarginPositions}
                            giver={this.props.counterpartyName} />
                    </>
                </Collapse>
            </React.Fragment>
        );
    }
}
