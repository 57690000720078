/**
 * Global data storage for the MarcoNet App
 *
 * @module utils/global
 */

import api from './api';

/** 
 * Global data available after loading. It includes:
 * - language selected for the app
 * - access token of the session login
 * - user info
 * - all blog posts
 * - all collateral requests, that the user has access to
 * - all margin positions, that the user has access to
*/
class GlobalData {
    language = undefined;
    token = undefined;
    user = undefined;

    _subscriptions = [];

    /**
     * Make initial call to populate globals from the API
     */
    load() { /*
        // Load  blog posts 
        // The callback for the api.list will be saved and called again
        // if updates are announced by the api
        console.log("Requesting all blog posts GET /blog/" + this.language);
        let options = { sort: "-date" };
        api.list("/blog/" + this.language, options, (err, res) => {
            if (err) {
                console.log("API GET/blog/" + this.language + " error:", err);
            } else {
                console.log("Blog posts received");
                this.allBlogPosts = res;
                this._inform('/blog');
            }
        }); */
    }

    /**
     * Subscribe to updates on any of the globals
     * @param {string} name - The name of the global to watch for updates
     * @param {function} callback - The callback function to call when there is an update
     * @returns {string} A unique ID of the subscription so it can be revoked
     */
    subscribe(name, callback, options = undefined) {
        const prefix = name ? name : 'all';
        name = name && name[0] && name[0] !== '/' ? '/' + name : name; // Add leading / to make valid API path

        // Check if there is already a subscription with the same name and options
        let optionStr = JSON.stringify(options);
        const subs = this._subscriptions.findIndex(subscription => name === subscription.name && optionStr === subscription.optionStr );

        var id;
        var pos;
        if (subs < 0) {
            id = prefix + Math.floor(10000 + Math.random() * 90000);
            pos = 0;
            console.log("Creating subscription", id+","+pos);
            this._subscriptions.push({
                id: id,
                name: name,
                callbacks: [callback],
                optionStr: optionStr
            });
            api.list(name, options, (err, res) => {
                if (err) {
                    console.log("API GET"+name+" error:", err);
                } else {
                    console.log(name+" received");
                    this[id] = res;
                    this._inform(id);
                }
            });
        } else {
            id = this._subscriptions[subs].id;
            pos = this._subscriptions[subs].callbacks.length;
            console.log("Extending subscription", id+","+pos);
            this._subscriptions[subs].callbacks.push(callback);
            this._doCallback(id, pos, name, callback);
        } 
        return id+","+pos;
    }

    /**
     * Call the callback of any subscriber when their watch global has an update
     * @param {string} id 
     */
    _inform(id) {
        let subscription = this._subscriptions.find(subscription => subscription.id === id);
        for(let i = 0; i < subscription.callbacks.length; i++) {
            if(subscription.callbacks[i]) {
                this._doCallback(id, i, subscription.name, subscription.callbacks[i]);
            }
        }
    }

    _doCallback(id, pos, name, callback) {
        var data = this[id];
        if (data) {
            try {
                console.log("Informing", id+","+pos, "about", name);
                callback(name, data);
            }
            catch (err) {
                console.log("Subscription", id+","+pos, "callback failed with error", err);
                this.unsubscribe(id+","+pos);
            }
        }
    }

    /**
     * Revoke a subscription but keep the api connected
     * @param {string} idPos - Unique ID of the subscription
     */
    unsubscribe(idPos) {
        const parts = idPos.split(",");
        const id = parts[0];
        const pos = parseInt(parts[1], 10);
        let i = this._subscriptions.findIndex(subscription => subscription.id === id);
        if (i >= 0 && this._subscriptions[i].callbacks[pos]) {
            console.log("Unsubscribing to", this._subscriptions[i].id+","+pos);
            this._subscriptions[i].callbacks[pos] = undefined;
        } else {
            console.log("No subscription with id", id+","+pos);
        }
    }

    /**
     * Revoke a subscription and clean up if there are no subscriptions left
     * @param {string} idPos - Unique ID of the subscription
     */
    remove(idPos) {
        this.unsubscribe(idPos);
        const parts = idPos.split(",");
        const id = parts[0];
        let i = this._subscriptions.findIndex(subscription => subscription.id === id);
        var cleanUp = true;
        for(let j = 0; j < this._subscriptions[i].callbacks.length; j ++) {
            cleanUp = this._subscriptions[i].callbacks[j] ? false : cleanUp;
        }
        if(cleanUp) {
            console.log("Removing data for id", this._subscriptions[i].id);
            this[this._subscriptions[i].id] = undefined;
            this._subscriptions.splice(i, 1);
        }
    }
}

export var global = new GlobalData();