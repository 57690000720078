/**
 * App: Display transaction details
 * 
 * @module components/modal-colr-extend
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/api
 * @requires module:utils/modalcomp
 * @requires module:utils/countdown
 * @requires module:utils/post-message
 */

import React, { Component } from 'react';
import { Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import api from '../utils/api';
import Modalcomp from "../utils/modalcomp";
import { CountdownButton, makeDeadline } from '../utils/countdown';
import PostMessage from "../utils/post-message";

/**
 * Show the form in a modal with deadline information
 *
 * @class ExtendDeadlineModal
 * @extends {Component}
 */
export class ExtendDeadlineModal extends Component {

    constructor(props) {
        super(props);
        this.intl = props.intl;
        this.state = {
            open: false,
            transaction: this.props.transaction,
            time: 0,
            id: this.props.id,
            submitted: false,
            response: '',
            validated: false,
            errormessage: null
        };

        this.toggle = this.toggle.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
    }


    /**
     * Toggle function to pass in as parentAction props to the modal to toggle state 
     */
    toggle() {
        this.setState({
            open: !this.state.open
        });

        // Clear everything if modal is closed and no error
        if (this.state.errormessage) {
            this.setState({
                submitted: false,
                response: false
            });
        } else {
            this.setState({
                submitted: false,
                response: false,
                time: 0,
                validated: false
            });
        }

    }

    /**
     * onChangeTime()
     * @param {*} e validate time value field 
     */
    onChangeTime(e) {
        if (e.target.value === null) {
            this.setState({
                Error: '',
                validated: false
            })
        } else if (e.target.value === 0) {


            this.setState({
                open: false,
                submitted: false,
                value: null,
                validated: false

            })
        } else if (isNaN(e.target.value)) {
            this.setState({
                Error: 'Not a number',
                validated: false
            })
        } else if (!e.target.value) {
            this.setState({
                Error: '',
                validated: false
            })
        }
        else if (e.target.value < 0) {
            this.setState({
                Error: 'Value is to low',
                validated: false
            })
        }
        else {
            this.setState({
                Error: null,
                validated: true
            })
            this.setState({ time: e.target.value });
        }
    }

    /**
     * onSubmit()
     * Post to API if validated 
     * @param {*} e 
     */
    onSubmit(e) {
        e.preventDefault();
        // ditch the state!
        let newTransaction = this.state.transaction;
        if (this.state.validated === false) {
            this.setState({
                open: true,
                submitted: false,
                value: null
            })
        } else if (this.state.time === 0) {
            
            this.setState({
                open: false,
                submitted: true,
                value: null
            });
        } else if (this.state.validated === true && this.state.time > 0) {
      
            let body = {
                change: parseInt(this.state.time),
                message: this.state.text
            };


            api.update("/colr/deadline/" + newTransaction._id, body)
                .then(res => {

                    this.setState({
                        open: true,
                        submitted: true,
                        value: null
                    });
                    this.setState({ response: "CcpTransaction.escalation.response.success" })
                })
                .catch(err => {

                    this.setState({
                        open: true,
                        submitted: false
                    });
                    this.setState({
                        response: "CcpTransaction.escalation.response.error",
                        errormessage: err.message
                    })
                });

        } else {
            this.setState({
                open: false,
                submitted: false,
                value: null
            });
        }

    }

    postMessage = (evt) =>{
        this.setState({
            text: evt.target.value
        });
    }

    /*
     * Deadline has been extended 
     */

    /**
     * Render the view. Show a button that trigger a modal
     * render()
     */
    render() {
        const { response, submitted, validated } = this.state
        const extendDeadline = this.intl.formatMessage({ id: "CcpTransaction.escalation.extenddeadlinemodal" });
        let transaction = this.state.transaction;
        const modalText = extendDeadline + ' ' + transaction.marginPos;
        let remaining = makeDeadline(transaction.deadline);
        if (!transaction)
            return null;
        let time = (this.state.time * 60000);
        let newtime = makeDeadline(remaining + time);
       
        return (

            <>
                <Button variant="outline-warning" className="mb-1" block size="sm" onClick={this.toggle}>
                    <FormattedMessage id="Action.extend.button" />
                </Button>


                <Modalcomp show={this.state.open} parentAction={this.toggle}
                    titleIntl={"Action.extend.button"} >
                    {/* Show form if not submitted noValidate */}
                    {!submitted ? (
                        <Form onSubmit={this.onSubmit} validated={validated}>
                            <Modal.Body>
                                {/* Show response messages and eventual error */}
                                {response && <Form.Group controlId="formCategory">
                                    <p><b className="text-danger">{this.state.errormessage}</b></p>

                                    <b className="text-danger">
                                        <FormattedMessage id={response} defaultMessage={response}
                                            values={{
                                                a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                            }}
                                        />
                                    </b>

                                </Form.Group>}

                                <React.Fragment>
                                    <Row className="mt-3">
                                        <Col><h4>{modalText}</h4></Col></Row>
                                    <Row>
                                        <Col xs={7}>
                                            <b><FormattedMessage id="Colr.extend.addtime" /></b>
                                        </Col>
                                        <Col xs={5}>
                                            <Form.Group controlId="timeID">

                                                <Form.Control
                                                    type="number"
                                                    step="10"
                                                    min="0"
                                                    value={this.state.time}
                                                    onChange={this.onChangeTime}
                                                    required
                                                    autoFocus
                                                />
                                                <b className="text-danger">{!validated && this.state.Error}</b>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={7}>
                                            <b>
                                                <FormattedMessage id="ExtendDeadline.currenttext" defaultMessage="Current deadline" />
                                            </b>
                                        </Col>
                                        <Col xs={5} className="mt-2">
                                            <CountdownButton block size="sm" deadline={remaining} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={7}>
                                            <b>
                                                <FormattedMessage id="ExtendDeadline.newtext" defaultMessage="New deadline" />
                                            </b>
                                        </Col>
                                        <Col xs={5} className="mt-2">
                                            <CountdownButton block size="sm" deadline={newtime} />
                                        </Col>
                                    </Row>
                                    <PostMessage onTextChange={this.postMessage}  />
                                </React.Fragment>

                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="outline-primary" size="sm" type="submit">
                                    <FormattedMessage id="CcpTransaction.escalation.extend.button" defaultMessage="Submit" />
                                </Button>

                            </Modal.Footer>
                        </Form>

                    )
                        :
                        (
                            <>{/*Show message after api request */}
                                <Modal.Body>
                                    <Row className="mt-3 pb-4">
                                        <Col>{response &&
                                            <FormattedMessage id={response} defaultMessage={response}
                                                values={{
                                                    a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                }}
                                            />}

                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-primary" onClick={this.toggle}>
                                        <FormattedMessage id="ExtendDeadline.close" defaultMessage="Close"
                                        />
                                    </Button>
                                </Modal.Footer>
                            </>
                        )}
                </Modalcomp>

            </>

        )
    }


}

export default injectIntl(ExtendDeadlineModal);

