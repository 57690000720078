/**
 * App: Display details of available assets to use as collateral
 * 
 * @module components/table-assets
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires module:utils/tableview
 * @requires module:utils/api
 */

import React, { Component } from 'react';
import { Container } from 'react-bootstrap'
import { TableView } from '../utils/tableview';
import api from '../utils/api.js';

/**
 * Display available assets to use as collateral
 *
 * @class AssetTable
 * @extends {Component}
 * 
 * @param {string} props.headingIntl React-Intl id for the heading over the table
 * @param {string} props.account Account to display
 */
export class AssetTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			format: [
				{
					field: "account",
					headerIntl: "Table.assets.account", headerAlign: "text-left text-uppercase",
					bodyType: "Text", bodyAlign: "text-left"
				},
				{
					field: "asset",
					headerIntl: "Table.assets.asset", headerAlign: "d-table-cell d-sm-none text-left text-uppercase",
					bodyType: "Text", bodyAlign: "d-table-cell d-sm-none text-left"
				},
				{
					field: "description",
					headerIntl: "Table.assets.description", headerAlign: "d-none d-sm-table-cell text-left text-uppercase",
					bodyType: "Text", bodyAlign: "d-none d-sm-table-cell text-left"
				},
				{
					field: "value",
					headerIntl: "Table.assets.marketValue", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
					bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
				},
				{
					field: "haircut",
					headerIntl: "Table.assets.haircut", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
					bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
				},
				{
					field: "collValue",
					headerIntl: "Table.assets.collValue", headerAlign: "text-right text-uppercase",
					bodyType: "Number", bodyAlign: "text-right"
				}
			],
			data: []
		}
	}

	componentDidMount() {
		this.loadData();
	}

	loadData() {
		console.log("Asset account", this.props.account)
		if(!this.props.account) return;

		let options = { filter: "name:"+this.props.account };
		api.list("/account", options).then(res => {
			var data = [];
			// Iterate through the accounts
			res.forEach(account => {
				if (account) {
					// Iterate through the assets of an accounts
					account.holding.forEach(asset => {
						data.push({
							account: account.name,
							asset: asset.name,
							description: asset.description,
							value: asset.value,
							haircut: asset.haircut,
							collValue: asset.collValue
						});
					})
				}
			})
			this.setState({
				dataLoaded: true,
				data: data
			});
		}).catch(err => { console.log(err) });
	}

	render() {
		return (
			<Container className="mt-5">
				<TableView headingIntl={this.props.headingIntl} format={this.state.format} data={this.state.data} />
			</Container>
		);
	}
}
