/**
 * App: Display transaction details
 * 
 * @module components/modal-colr-propose
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires NPM:react-bootstrap-typeahead
 * @requires NPM:fortawsome
 * @requires module:utils/api
 * @requires module:utils/modalcomp
 * @requires module:utils/tableview
 */

import React, { Component } from 'react';
import { Button, Col, Row, Modal, Form, Table } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import api from '../utils/api';
import Modalcomp from "../utils/modalcomp";
import { TableView, numberRedFormat } from '../utils/tableview';
import PostMessage from "../utils/post-message";
import { Redirect } from 'react-router-dom';
library.add(faPencilAlt);

// Responsive width of left and right column in the modal at different screen sizes
const widthLeft = { xs: 7, sm: 6, md: 5, lg: 4, xl: 3 };
const widthRight = { xs: 5, sm: 4, md: 3, lg: 2, xl: 2 };

/**
 * Show the form in a modal to edit actions 
 * Modal to show Propose collateral view with overview,and a field to add proposed securitys to
 * collateral.
 * marginPos - Margin position being used for the proposal
 * transaction - The ongoing transaction to have a proposal added 
 *  
 * @class ModalPropose
 * @extends {Component}
 */
export class ModalPropose extends Component {
    constructor(props) {
        super(props);
        this.intl = props.intl;
        let marginPos = this.props.marginPos;
        if (!this.props.marginPos && this.props.transaction && props.allMarginPositions) {
            marginPos = props.allMarginPositions.find(({ name }) =>
                name === this.props.transaction.marginPos
            );
        }
        console.log("-- props", props)
        console.log("-- marginPos", marginPos)
        let type = this.props.transaction ? this.props.transaction.name : this.props.type;
        this.state = {
            chooseVolume: '',
            tableEvent: '',
            validated: false,
            chooseAsset: '',
            submitted: false,
            urlHash: '',
            defaultvalue: undefined,
            minvalue: 0,
            maxvalue: 0,
            multiple: false,
            colHoldingvalue: '',
            step: 1,
            addNumber: 0,
            toggledvolumeFocus: false,
            selectedItem: {},
            selectedItemValue: '',
            selectedItemVolume: '',
            open: false,
            openapprove: false,
            opendecline: false,
            sumProposed: 0,
            response: '',
            text: '',
            thevalue: '',
            selectedObjects: [],
            tbl: [],
            subject: '',
            type: type,
            headingintl: "CcpTransaction.escalation.approvemodal",
            transaction: this.props.transaction,
            marginPos: marginPos,
            format: [
                {
                    field: "asset",
                    headerIntl: "Table.assets.asset", headerAlign: "text-left text-uppercase",
                    bodyType: "Text", bodyAlign: "text-left"
                },
                {
                    field: "volume",
                    headerIntl: "Table.assets.volume", headerAlign: "text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "text-right"
                },
                {
                    field: "collValue",
                    headerIntl: "Table.assets.collateralvalue", headerAlign: "text-right text-uppercase",
                    bodyType: "Number", bodyAlign: " text-right"
                },
                {
                    field: "Remove",
                    headerAlign: "text-right text-uppercase",
                    bodyType: "Link", bodyAlign: " text-right"
                }
            ]
        };

        this.close = this.close.bind(this);
        this.getItem = this.getItem.bind(this);
        this.numberInput = React.createRef();
        this.focus = this.focus.bind(this);
        this.volumeFocus = this.volumeFocus.bind(this);
        this.onChangeVolume = this.onChangeVolume.bind(this);
        this.calculateCollateralItem = this.calculateCollateralItem.bind(this);
        this.calculateValue = this.calculateValue.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.isPlainObject = this.isPlainObject.bind(this);
        this.loadColHoldning = this.loadColHoldning.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.hashChange = this.hashChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
        this.loadColHoldning();
        console.log('marginalen: ', this.state.marginPos);
    }

    /**
     * Load available assets to be used as collateral
     */
    loadData() {
		let options = { filter: "owner:"+this.state.marginPos.giver };
        api.list("/account", options).then(res => {
            let data = [];
			// Iterate through the accounts and load display data specific to the table
			res.forEach(account => {
				if (account) {
					// Iterate through the assets of an accounts
					account.holding.forEach(asset => {
						data.push({
							account: account.name,
							asset: asset.asset,
							description: asset.description,
                            volume: asset.volume,
							value: asset.value,
							haircut: asset.haircut,
							collValue: asset.collValue,
                            currency: asset.currency
						});
					})
				}
			})
            this.setState({
                dataLoaded: true,
                data: data
            });
        }).catch(err => { console.log(err) });
    }

    /**
     * Load list of colHolding accounts
     */
    loadColHoldning() {
        this.setState({
            colaccount: this.state.marginPos.collHolding
        });
    }

    /**
    * Toggle function to pass in as parentAction props to the modal to toggle state 
    */
    toggle = () => {
        this.setState({
            open: !this.state.open,
        });
        // Clear everything if modal is closed and no error
        if (this.state.errormessage) {
            this.setState({
                open: true,
                submitted: false,
                response: false,
                confirm: false
            });
        } else {
            this.setState({
                submitted: false,
                response: false,
                validated: false,
                confirm: false
            });
        }
    }

    /**
     * Close the modal
     */
    close() {
        this.setState({
            open: false,
            response: '',
            sumProposed: 0,
            submitted: true,
            validated: true,
            tbl: []
        });
        //  window.location.replace('/dashboard');
        this.followLink('/dashboard')
    }

    followLink = (link) => {
        this.linkToFollow = link;
        // scroll to top
        if (link) {
            window.scrollTo(0, 0);
        }

        //this.forceUpdate();
    }

    /**
     * Set focus with ref
     */
    focus() {
        this.numberInput.current.focus();
    }

    /**
     * Anv stegar upp föreslaget coll value. Beräkna det genom att ta 
     * Collvalue/totala volym för att veta värdet per asset. (Collvalue/volym) * volym = föreslagen collvalue. 
     * Föreslagna ska vara högre än marginalkravet men inte mer än Collvalue som finns för den valda säkerheten. 
     * 
     * @param {*} e 
     */
    onChangeVolume(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            addNumber: e.target.value
        });
        // Calculate value when user change volume
        this.calculateValue(e.target.value);
    }

    /**
     * Get the value from the dropdown
     * @param {*} e 
     */
    onChangeDropdown(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value) {
            this.setState({
                colHoldingvalue: e.target.value,
                //validated: true
            });
        } else {
            this.setState({
                validated: false
            });
        }
    }

    /**
     * Check if user press tab or return in the volume field. 
     * Then run calculateCollateralItem. 
     * @param {*} e 
     */
    volumeFocus(e) {
        // Check if user press the tab key 
        const code = e.keyCode || e.which;
        if (code === 9 || code === 13) {
            this.setState({
                toggledvolumeFocus: !this.state.toggledvolumeFocus
            });
            // stop return from closing the modal
            e.preventDefault();
            this.calculateCollateralItem();
        }
    }

    onSubmit(e) {
        e.preventDefault();
        // När cm vill skicka in säkerheter (cm margincall): 
        // if this.props.transaction the update
        // if !this.props.transaction then create
        /* Med create addera följande till body:
                let body = {
            marginPos: this.state.marginPos.name, 
            Defaults to 60 minutes.
            remaining: 60,
            remaining time). ISO date-time string
            deadline: undefined,
            margin requirement
            netValue: undefined,
            type: "margincall" eller "recall" eller "substitution"
        };
*/

        if (this.state.transaction && this.state.transaction._id && this.state.tbl && this.state.colHoldingvalue && this.state.sumProposed > 0) {
            let body = {
                collHolding: this.state.colHoldingvalue,
                collSubmit: this.state.tbl,
                message: this.state.text
            };
            api.update("/colr/propose/" + this.state.transaction._id, body)
                .then(res => {
                    this.setState({
                        open: true,
                        submitted: true,
                        textvalue: '',
                        confirm: true,
                        response: "Modal.propose.response.success", errormessage: res.message
                    });
                })
                .catch(err => {
                    this.setState({
                        open: true,
                        submitted: false,
                        response: "CcpMarginPos.propose.collateral.response.error",
                        errormessage: err.message
                    });
                });
        } else if (!this.state.transaction) {
            console.log('Create')
            let body = {
                type: 'margincall',
                marginPos: this.state.marginPos.name,
                //Defaults to 60 minutes.
                remaining: 60,
                //remaining time). ISO date-time string
                deadline: undefined,
                //margin requirement
                netValue: undefined,
                //name: "margincall" eller "recall" eller "substitution"
                collHolding: this.state.colHoldingvalue,
                collSubmit: this.state.tbl
            };
            api.create("/colr", body)
                .then(res => {
                    this.setState({
                        open: true,
                        submitted: true,
                        textvalue: '',
                        confirm: true,
                        response: "Modal.propose.response.success", errormessage: res.message
                    });
                })
                .catch(err => {
                    this.setState({
                        open: true,
                        submitted: false,
                        response: "CcpMarginPos.propose.collateral.response.error",
                        errormessage: err.message
                    });
                });
        }
    }

    /**
     * Get selected item from the dropdown list.
     * Hide the dropdown list by setting it to none first and move
     * focus to the volume field.
     * After volume is added and the user has tabbed past the last field, 
     * caculate asset * volume and add the value in the json and
     * put it in a new object to avoid refs, then push
     * it to the this.state.tbl and show it in a table view below.
     * 
     * @method getItem()
     * @param {*} e 
     */
    getItem(e) {
        // set focus to the volume field with a function that uses a ref..
        if (e && e.length) {
            let resulten = e;
            this.setState({
                selectedItem: resulten
            })
            // not empty 
            this.focus();
        }
    };

    /**
     * User selects amount of securitys and the value for the selected is
     * calculated based on each assets value.
     * @method calculateValue()
     * @param {*} input 
     */
    calculateValue(input) {
        // Selected item from assets
        let grunkan = this.state.selectedItem;

        if (!Array.isArray(grunkan)) {
            grunkan = [grunkan];
        }
        let value = 0;
        let minvalue = 0;
        let haircut = 0;
        let collValue = 0;
        let intValue = parseInt(input);

        if (this.state.dataLoaded && grunkan[0]) {
            value = grunkan[0].value * intValue / grunkan[0].volume
            haircut = grunkan[0].haircut * intValue / grunkan[0].volume
            collValue = grunkan[0].collValue * intValue / grunkan[0].volume
            value = parseInt(value);
            this.setState({
                minvalue: minvalue,
                maxvalue: grunkan[0].collValue,
                selectedItemVolume: intValue,
                step: 1
            });
        }
        this.setState({
            selectedItemValue: value,
            calcHaircut: haircut,
            calccolValue: collValue
        });
    }

    /**
     * Check if it's an object
     * @param {*} obj 
     * @method isPlainObject()
     */
    isPlainObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    };

    /**
     * Create a new object and Add value and volume to it.
     * Clear the fields and calculate sumProposed.  
     * @method calculateCollateralItem()
     * 
     */
    calculateCollateralItem() {
        let obj = this.state.selectedItem;
        let d = new Date();

        if (!Array.isArray(obj)) {
            obj = [obj];
        }

        let uid = obj[0].asset + d.getMilliseconds()

        // create new object to skip the reference to the old object.
        if (this.state.selectedItemValue && obj[0].asset && this.state.selectedItemVolume && Array.isArray(this.state.tbl)) {

            let newobj = {
                'volume': this.state.selectedItemVolume,
                'asset': obj[0].asset,
                '_id': obj[0]._id,
                'collValue': this.state.calccolValue,
                'description': obj[0].description,
                'haircut': this.state.calcHaircut,
                'value': this.state.selectedItemValue,
                'Remove': [<FontAwesomeIcon icon={["fas", "pencil-alt"]} />, "#" + uid],
                'uid': uid // unique id set so that we can remove items 
            }

            if (this.isPlainObject(newobj)) {
                // add object to the table   
                let tblArray = this.state.tbl;
                tblArray.push(newobj);
                this.setState({
                    tbl: tblArray,
                    addNumber: '',
                    selectedItemValue: '',
                    selectedItem: {}
                })

                this._typeahead.clear();
                // Calculate sumProposed
                let array = this.state.tbl;
                let sumProposed = array.reduce((acc, curr) => acc + curr.collValue, 0);
                this.setState({
                    sumProposed: sumProposed,
                    validated: false
                })
                if (this.state.marginPos.netCollMargin + sumProposed > 0) {
                    this.setState({ validated: true });
                }
            }
        }
    }

    /**
     * Get address hash from browser and remove item from the tableview.
     * @param {*} hash 
     * @method hashChange()
     */
    hashChange(hash) {
        hash = hash.slice(1);
        // delete from tbl
        let removeIndex = this.state.tbl.map(function (item) { return item.uid; }).indexOf(hash);
        // get the object so we can move it to the input field.
        let newTbl = this.state.tbl;
        const result = newTbl.find(({ uid }) => uid === hash);
        // Place info in input field
        if (result) {
            this.getItem([result]);
            this.setState({
                selectedItem: result,
                addNumber: result.volume,
                selectedItemValue: result.collValue,
                defaultvalue: [result]
            });
            // remove object
            let spliceArray = this.state.tbl;
            spliceArray.splice(removeIndex, 1);
            this.setState({
                tbl: spliceArray,
                validated: false
            });
            // clear hash in url
            if (removeIndex) {
                window.location.hash = '';
            }
        }
    }

    postMessage = (evt) => {
        this.setState({
            text: evt.target.value
        });
    }

    render() {
        /*if(this.state.link){
			return <Redirect to={this.state.link} />
        }*/
        if (this.linkToFollow) {
            console.log("Redirecting to", this.linkToFollow);
            const link = this.linkToFollow;
            this.linkToFollow = undefined;
            return <Redirect to={link} />
        }
        const { colaccount, submitted, sumProposed, response, errormessage, data, selectedItemValue, tbl, multiple, validated, marginPos } = this.state;


        /*let marginname = {};
        let marginposname = {};
        if(transaction){
        marginname = transaction.marginPos;
        }else{
        marginposname = marginPos.name;
        }*/
        const chooseAsset = this.intl.formatMessage({ id: "CcpTransaction.escalation.modal.inputasset" }); // Choose asset...
        const chooseVolume = this.intl.formatMessage({ id: "CcpTransaction.escalation.modal.inputvolume" }); // add value...
        const selectAccount = this.intl.formatMessage({ id: "CmTransaction.propose.colatteral.modal.selectaccount" }); // add value...
        const defaultAccount = this.state.colHoldingvalue;
        let buttonText = "CmMarginPos.escalation.post.button";
        /* if(!transaction){
             buttonText = "CmMarginPos.escalation.substitution.button";
         } else{
             if (transaction.name === 'substitution') {
                 buttonText = "CmMarginPos.escalation.substitution.button";
             } else if (transaction.name === 'margincall') {
                 buttonText = "CmTransaction.escalation.propose.button";
             } else if (transaction.name === 'recall') {
                 buttonText = 'CmMarginPos.escalation.post.button';
             };
         }*/
        buttonText = "CmTransaction.escalation.propose.button";
        // detect if browser address field hash changed 
        if (window.location.hash) {
            this.hashChange(window.location.hash);
        }

        return (
            <>
                <Button variant="outline-primary" className="mb-1" block size="sm" onClick={this.toggle}>
                    <FormattedMessage id={buttonText} />
                </Button>

                <Modalcomp show={this.state.open} parentAction={this.toggle} titleText={buttonText} titleIntl={buttonText} size="lg" backdrop={"static"}>
                    <Form noValidate validated={validated} onSubmit={this.onSubmit} >
                        {!submitted ? (
                            <>
                                <Modal.Body>

                                    {response &&
                                        <>  <b className="text-danger">{errormessage}</b><br />
                                            <b><FormattedMessage id={response} defaultMessage={response}
                                                values={{
                                                    a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                }}
                                            /></b>
                                            <br /><br />
                                        </>}
                                    <Row className="mt-3">
                                        <Col>
                                            <h4>
                                                <FormattedMessage id="CmTransaction.propose.colatteral.modal.heading" />&nbsp;
                                                {marginPos.name}
                                            </h4>
                                        </Col>
                                    </Row>

                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label><FormattedMessage id="CmTransaction.propose.colatteral.modal.selectlabel" /></Form.Label>
                                        {colaccount &&
                                            <Form.Control as="select" autoFocus={true} onChange={this.onChangeDropdown} defaultValue={defaultAccount} required>
                                                <option value="" >{selectAccount}</option>
                                                {colaccount.map((item, i) => {
                                                    return <option key={i} value={item.name}>{item.name}, {item.description}</option>
                                                })}
                                            </Form.Control>
                                        }
                                    </Form.Group>

                                    <Row className="mt-2">
                                        <Col {...widthLeft}>
                                            <b>
                                                <FormattedMessage id="margincall" />
                                            </b>
                                        </Col>
                                        <Col {...widthRight} className="text-right">
                                            {numberRedFormat(marginPos.netCollMargin)}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col {...widthLeft} className="text-left">
                                            <b>
                                                <FormattedMessage id="CmTransaction.escalation.proposed" />
                                            </b>
                                        </Col>
                                        <Col  {...widthRight} className="mb-1 border-bottom text-right">
                                            {numberRedFormat(sumProposed)}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col {...widthLeft} className="text-left">
                                            <b><FormattedMessage id="CmTransaction.escalation.proposed.position" /></b>
                                        </Col>
                                        <Col {...widthRight} className="text-right mb-4">
                                            {numberRedFormat(marginPos.netCollMargin + sumProposed)}
                                        </Col>
                                    </Row>

                                    <Table className="table table-hover table-sm table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th className="text-left text-uppercase">
                                                    <FormattedMessage id="Table.assets.asset" />
                                                </th>
                                                <th className="text-right text-uppercase">
                                                    <FormattedMessage id="Table.assets.volume" />
                                                </th>
                                                <th className="text-right text-uppercase">
                                                    <FormattedMessage id="Table.assets.collValue" />
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className="text-right">
                                                    {this.state.dataLoaded && (
                                                        <React.Fragment>
                                                            <Form.Group>
                                                                <Typeahead
                                                                    id="typeaheadid"
                                                                    labelKey="description"
                                                                    multiple={multiple}
                                                                    selected={this.state.defaultvalue}
                                                                    options={data}
                                                                    selectHintOnEnter={true}
                                                                    onChange={(e) => this.getItem(e)}
                                                                    onInputChange={() => this.setState({ defaultvalue: undefined })}
                                                                    placeholder={chooseAsset}
                                                                    ref={(ref) => this._typeahead = ref}
                                                                />

                                                            </Form.Group>
                                                        </React.Fragment>
                                                    )}
                                                </td>

                                                <td className="text-right">
                                                    <Form.Group controlId="value">
                                                        <Form.Control
                                                            type="number"
                                                            min={this.state.minvalue}
                                                            max={this.state.maxvalue}
                                                            step={this.state.step}
                                                            ref={this.numberInput}
                                                            placeholder={chooseVolume}
                                                            value={this.state.addNumber}
                                                            onChange={this.onChangeVolume}
                                                            onKeyDown={this.volumeFocus}
                                                        />
                                                    </Form.Group>
                                                </td>

                                                <td className="text-right">
                                                    {selectedItemValue}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </Table>

                                    <TableView headingIntl={this.props.headingIntl} format={this.state.format} data={tbl} />

                                    <PostMessage onTextChange={this.postMessage} />
                                </Modal.Body>

                                <Modal.Footer>
                                    {!submitted && <Button variant="outline-primary" className="mb-1" size="sm" type="submit">
                                        <FormattedMessage id={buttonText} defaultMessage="Submit" />
                                    </Button>}
                                    {submitted && <Button variant="outline-primary" className="mb-1" size="sm" onClick={(e) => this.close(e)}>
                                        <FormattedMessage id="CcpMarginPos.escalation.close" defaultMessage="Close" />
                                    </Button>}
                                </Modal.Footer>
                            </>
                        ) : (
                                <>
                                    <Modal.Body>
                                        {response &&
                                            <>  <b className="text-danger">{errormessage}</b><br />
                                                <b><FormattedMessage id={response} defaultMessage={response}
                                                    values={{
                                                        a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                    }}
                                                />  {marginPos.name} </b>
                                                <br /><br />
                                            </>}
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="outline-primary" className="mb-1" size="sm" onClick={(e) => this.close(e)}>
                                            <FormattedMessage id="CcpMarginPos.escalation.close" defaultMessage="Close" />
                                        </Button>
                                    </Modal.Footer>
                                </>
                            )}
                    </Form>
                </Modalcomp>
            </>
        )
    }
}

export default injectIntl(ModalPropose);