/**
 * App: Display details of available assets to use as collateral
 * 
 * @module components/user-sign-out
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires module:utils/auth
 */

import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import auth from './../utils/auth'

/**
 * Sign out the current user and return to home page
 *
 * @class SignOut
 * @extends {Component}
 */
export default class SignOut extends Component {
    render() {
        auth.logOut(function () {
            console.log('Signed out');
            window.location.replace('/');
        });
        return (
            <Container className="mt-5">
                <h3>Logout</h3>
            </Container>
        );
    }
}