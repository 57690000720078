/**
 * App: Display clearing member margin position details
 * 
 * @module components/details-marginpos
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/tableview
 */

import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { numberRedFormat, numberFormat } from '../utils/tableview'

const widthLeft  = { xs: 7, sm: 6, md: 5, lg: 4, xl: 3 };
const widthRight = { xs: 5, sm: 4, md: 3, lg: 2, xl: 2 };

/**
 * Subview of margin position detils page: Section shows details of the margin position
 *
 * @class MarginPosDetails
 * @extends {Component}
 */
export default class MarginPosDetails extends Component {
    render() {
        let marginPos = this.props.marginPos;

        return (
            <Container className="mt-5">
                <h2>
                    <FormattedMessage id={this.props.headingIntl} />
                    &nbsp;
                    {marginPos.taker + ": " + marginPos.name}
                </h2>
                {marginPos.description ? <h5>{marginPos.description}</h5> : null}
                <Row>
                    <Col {...widthLeft}>
                        <b>
                            <FormattedMessage id="CcpMarginPos.details.collateralvalue" />
                        </b>
                    </Col>
                    <Col {...widthRight} className="text-right">
                        {numberFormat(marginPos.collValue)}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>
                            <FormattedMessage id="CcpMarginPos.details.marginreq" />
                        </b>
                    </Col>
                </Row>
                {marginPos.margin.map((margin, i) => {
                    return <Row key={i}>
                        <Col {...widthLeft}>
                            <b>
                                -&nbsp;
                                <FormattedMessage
                                    id={"CcpMarginPos.details.margintype." + margin.type}
                                    defaultMessage={margin.type}
                                />
                            </b>
                        </Col>
                        <Col {...widthRight} className="text-right">
                            {numberFormat(margin.requirement)}
                        </Col>
                    </Row>
                })}
                <Row>
                    <Col {...widthLeft} className="border-top border-light pt-1">
                        <b>
                            <FormattedMessage id={marginPos.state} />
                        </b>
                    </Col>
                    <Col {...widthRight} className="text-right border-top border-light pt-1">
                        {numberRedFormat(marginPos.netCollMargin)}
                    </Col>
                </Row>
            </Container>
        )
    }
}