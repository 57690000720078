/**
 * App: Display for MarcoApp dev docs, MarcoAPI dev docs and MarcoAPI OpenAPI specification in an iframe
 * @module components/docs
 * @requires NPM:react
 * @require utils/display-html
 */

import React, { Component } from 'react';
import { DisplayHtml } from '../utils/display-html';
import config from '../utils/config';

/**
 * eact component that displays the MarcoApp jsDoc documentation in an iframe
 * @export
 *
 * @class DocsApp
 * @example 
 * <DocsApp />
 * @extends {Component}
 */
export class DocsApp extends Component {
    render() {
        return (
            <DisplayHtml src="/docs/app" />
        )
    }
}

/**
 * React component that displays the MarcoAPI jsDoc documentation in an iframe
 * @export
 *
 * @class DocsApi
 * @example 
 * <DocsApi />
 * @extends {Component}
 */
export class DocsApi extends Component {
    render() {
        return (
            <DisplayHtml src="/docs/api" />
        )
    }
}

/**
 * React component that displays the MarcoAPI OpenAPI specification in an iframe
 * @export
 *
 * @class DocsOpenApi
 * @example 
 * <DocsOpenAPI />
 * @extends {Component}
 */
export class DocsOpenApi extends Component {
    render() {
        return (
            <DisplayHtml src={config.apiUrl + "/docs"} />
        )
    }
}
