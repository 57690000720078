/**
 * App: Load and display a Container with collateral holding as a table
 * 
 * @module components/table-holdings
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires module:utils/tableview
 * @requires module:utils/global
 */

import React, { Component } from 'react';
import { Container } from 'react-bootstrap'
import { TableView } from '../utils/tableview';
import { global } from '../utils/global';

/**
 * Display collateral holdings overview
 *
 * @class HoldingTable
 * @extends {Component}
 * 
 * @param {string} props.headingIntl React-Intl id for the heading over the table
 * @param {Array}  props.allMarginPositions Contains source data, array with all margin positions
 * @param {string} props.marginPos Optional string with name of the margin position to display
 * @param {string} props.giver Optional string with the clearing member to select
 * @param {string} props.taker Optional string with the CCP to select
 */
export class HoldingTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			format: [
				{
					field: "position",
					headerIntl: "Table.holdings.position", headerAlign: "text-left text-uppercase",
					bodyType: "Link", bodyAlign: "text-left"
				},
				{
					field: "account",
					headerIntl: "Table.holdings.account", headerAlign: "text-left text-uppercase",
					bodyType: "Text", bodyAlign: "text-left"
				},
				{
					field: "asset",
					headerIntl: "Table.holdings.asset", headerAlign: "d-table-cell d-sm-none text-left text-uppercase",
					bodyType: "Text", bodyAlign: "d-table-cell d-sm-none text-left"
				},
				{
					field: "description",
					headerIntl: "Table.holdings.description", headerAlign: "d-none d-sm-table-cell text-left text-uppercase",
					bodyType: "Text", bodyAlign: "d-none d-sm-table-cell text-left"
				},
				{
					field: "marketValue",
					headerIntl: "Table.holdings.marketValue", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
					bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
				},
				{
					field: "haircut",
					headerIntl: "Table.holdings.haircut", headerAlign: "d-none d-sm-table-cell text-right text-uppercase",
					bodyType: "Number", bodyAlign: "d-none d-sm-table-cell text-right"
				},
				{
					field: "collValue",
					headerIntl: "Table.holdings.collValue", headerAlign: "text-right text-uppercase",
					bodyType: "Number", bodyAlign: "text-right"
				}
			],
			data: []
		};
	}

	static getDerivedStateFromProps(props) {
		var data = [];
		let isCCP = global.user && global.user.organisation && (global.user.organisation.role === 'ccp');

		if (props.allMarginPositions) {
			// Iterate through the margin positions and load display data
			props.allMarginPositions.forEach(element => {
				var keep = true;
				// Checkif there is a specific  margin position name, giver or taker that should be displayed
				keep = props.marginPos && props.marginPos !== element.name ? false : keep;
				keep = props.giver && props.giver !== element.giver ? false : keep;
				keep = props.taker && props.taker !== element.taker ? false : keep;
				if (keep && element.collHolding) {
					// Iterate through the collateral accounts of a margin position
					element.collHolding.forEach(account => {
						if (account.holding) {
							// Iterate through the assets of an account
							account.holding.forEach(asset => {
								const row = {
									position: [(isCCP ? element.giver : element.taker) + ": " + element.name, "/marginpos/" + element._id],
									account: account.name,
									asset: asset.asset,
									description: asset.description,
									marketValue: asset.value,
									haircut: asset.haircut,
									collValue: asset.collValue
								};
								data.push(row);
							})
						}
					})
				}
			})
		}

		return {
			data: data
		};
	}

	render() {
		return (
			<Container className="mt-5">
				<TableView headingIntl={this.props.headingIntl} format={this.state.format} data={this.state.data} />
			</Container>
		)
	}
}
