/**
 * Support functions for <Collapse> </Collapse>
 * 
 * @module utils/collapse-icon
 * 
 * @requires NPM:react
 * @requires NPM:@fortawsome
 */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';

library.add(faAngleDown, faAngleRight)

/**
 * Provide JSX to display a collapse icon that toggles by parameter in
 * 
 * @function CollapseIcon
 * @param {boolean} in true shows the "open" icon and false shows the "collapsed" icon
 */
export function CollapseIcon(show) {
    return <FontAwesomeIcon style={{width: "1.7rem"}} icon={["fas", show.in ? "angle-down" : "angle-right"]} />
}
