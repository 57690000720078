/**
 * App: Display dev docs, after logging in
 * @module components/info-docs
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 */

import React, { Component } from 'react';
import { Container, Button} from "react-bootstrap";
import {FormattedMessage} from 'react-intl';

/**
 * Subview to display the dev docs
 *
 * @export
 * @class InfoDocs
 * @extends {Component}
 */
export default class InfoDocs extends Component {
    /** Render the view */
    render() {
        return (            
                <Container className="mt-0 pt-4 mb-0 pb-4"> {/* Documentation */}
                    <h1>
                        <FormattedMessage  
                            id="Docs.title"  
                            defaultMessage="Documentation"/>
                    </h1>
                    <p>
                        <FormattedMessage
                            id="Docs.intro"
                            defaultMessage="MarcoNet consists of three main components:"/>
                    </p>

                    <ul> {/* First li-bullet has to be on a single line. Else, the li-tags will not work !? */}
                    <FormattedMessage
                        id="Docs.intro-2" 
                        defaultMessage="
                            <li>The App or user interface. That is what you currently look at. It is a modern Web App that can be used on a PC, tablet or smartphone. To make it safe, the App do not store or maintain any information locally with the browser. It is all on the servers on the other side of the API.</li>
                            <li>The API server. That is where the processing and logic resides. The API can 
                                be called from the App or a custom App to provide the best user experience. 
                                It can also be called from other systems to integrate and automate. The API
                                is kept secure through login, authentication, encryption, auto logout etc.</li>
                            <li>The Hyperledger blockchain. That is the shared infrastructure where the
                                margin call transactions are executed and maintained. The purpose of using
                                a blockchain is to allow the parties in the market, somtimes with
                                conflicting intresest, to transact in the same system. The blockchain
                                provides integrity of transactions, traceability and a balance between 
                                transparency and commercial integrity.</li>"
                                values={{
                                    li: msg => ( <li>{msg}</li> )
                                }}
                            />
                    </ul>
                    <h3>App</h3>
                    <p>
                        <FormattedMessage
                            id="Docs.app"
                            defaultMessage="The App docmentation is still to be developed."
                        />
                    </p>
                    <p>
                        <Button variant="outline-primary" href="/docsapp">MarcoNet App</Button>
                    </p>
                    <h3>API</h3>
                    <p>
                        <FormattedMessage
                            id="Docs.api"
                            defaultMessage="The API is documentet using <a>OpenAPI version 3</a>. There is a verbal 
                                description, a downloadable definition and sample API calls. With OpenAPI there is
                                a plethora of tools and documentation to make development more efficient."
                            values={{
                                a: msg => (
                                    <a className="external_link" target="_blank" rel="noopener noreferrer" href="https://www.openapis.org/">{msg}</a>
                                )
                            }}
                        />
                    </p>
                    <p>
                        <Button variant="outline-primary" href="/docsopenapi">MarcoNet OpenAPI</Button>
                    </p>
{/*                    
                    <h4><FormattedMessage id="Docs.dltheading" defaultMessage="Blockchain" /></h4>
                    <p>
                        <FormattedMessage
                            id="Docs.dlt"
                            defaultMessage="The blockchain docmentation is still to be developed."
                        />
                    </p>
                        */}
                 </Container>
        )
    }
}
