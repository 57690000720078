/**
 * App: Display modal substitution
 * 
 * @module components/modal-colr-substitution
 * 
 * @requires NPM:react
 * @requires NPM:react-router-dom
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires NPM:react-bootstrap-typeahead
 * @requires NPM:@fortawesome
 * 
 * @requires module:utils/api
 * @requires module:utils/modalcomp
 * @requires module:utils/tableview
 * @requires module:utils/post-message
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Table, Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typeahead } from 'react-bootstrap-typeahead';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import api from '../utils/api';
import Modalcomp from "../utils/modalcomp";
import { TableView, numberRedFormat } from '../utils/tableview'
import PostMessage from "../utils/post-message";
library.add(faPencilAlt);

const widthLeft = { xs: 7, sm: 6, md: 5, lg: 4, xl: 3 }
const widthRight = { xs: 5, sm: 4, md: 3, lg: 2, xl: 2 }

/**
 * CM making substitution request. In first modalview user select collateral return and in last view user select collateralsubmit.
 * 
 * @class ModalSubstitution
 * @extends Component
 */
export class ModalSubstitution extends Component {
    constructor(props) {
        super(props);
        this.intl = props.intl;
        this.state = {
            validationmessage: '',
            validationProposemessage: '',
            headlineStep: "1",
            substitution: false,
            numberInputisValid: false,
            numberInputisInvalid: false,
            chooseVolume: '',
            chooseProposeVolume: '',
            tableEvent: '',
            validated: false,
            chooseAsset: '',
            submitted: false,
            urlHash: '',
            defaultvalue: undefined,
            minvalue: 0,
            maxvalue: 0,
            multiple: false,
            colHoldingvalue: '',
            step: 1,
            addNumber: 0,
            toggledvolumeFocus: false,
            selectedItem: {},
            selectedItemValue: '',
            selectedProposeItemValue: '',
            selectedProposeItemVolume: '',
            selectedItemVolume: '',
            calcProposeHaircut: '',
            calcProposecolValue: '',
            open: false,
            openapprove: false,
            opendecline: false,
            sumProposed: 0,
            sumProposeProposed: 0,
            response: '',
            text: '',
            thevalue: '',
            selectedObjects: [],
            tbl: [],
            tblpropose: [],
            subject: '',
            headingintl: "CcpTransaction.escalation.approvemodal",
            transaction: this.props.transaction,
            marginPos: this.props.marginpos, // marginpos name
            marginpos: {}, // marginpos obj,
            format: [
                {
                    field: "asset",
                    headerIntl: "Table.holdings.asset", headerAlign: "text-left text-uppercase",
                    bodyType: "Text", bodyAlign: "text-left"
                },
                {
                    field: "volume",
                    headerIntl: "Table.holdings.volume", headerAlign: "d-none d-lg-table-cell text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "d-none d-lg-table-cell text-right"
                },
                {
                    field: "collValue",
                    headerIntl: "Table.holdings.collValue", headerAlign: "text-right text-uppercase",
                    bodyType: "Number", bodyAlign: " text-right"
                },
                {
                    field: "Remove",
                    headerAlign: "text-right text-uppercase",
                    bodyType: "Link", bodyAlign: " text-right"
                }
            ]
        };

        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
        this.back = this.back.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getItem = this.getItem.bind(this);
        this.numberInput = React.createRef();
        this.numberProposeInput = React.createRef();
        this.focus = this.focus.bind(this);
        this.focusPropose = this.focusPropose.bind(this);
        this.volumeFocus = this.volumeFocus.bind(this);
        this.volumeProposeFocus = this.volumeProposeFocus.bind(this);
        this.onChangeVolume = this.onChangeVolume.bind(this);
        this.onProposeChangeVolume = this.onProposeChangeVolume.bind(this);
        this.calculateCollateralItem = this.calculateCollateralItem.bind(this);
        this.calculateProposeItem = this.calculateProposeItem.bind(this);
        this.calculateValue = this.calculateValue.bind(this);
        this.onSubmitRecall = this.onSubmitRecall.bind(this);
        //this.onSubmitPropose = this.onSubmitPropose.bind(this);
        this.isPlainObject = this.isPlainObject.bind(this);
        this.loadColHoldning = this.loadColHoldning.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.hashChange = this.hashChange.bind(this);
        this.goDashboard = this.goDashboard.bind(this);
        this.getAssets = this.getAssets.bind(this);
        this.ProposeCollReturn = this.ProposeCollReturn.bind(this);
        this.ProposeView = this.ProposeView.bind(this);

    }

    componentDidMount() {
        this.loadColHoldning();
        this.loadData();
    }

    /**
    * Toggle function to pass in as parentAction props to the modal to toggle state 
    */
    toggle() {
        this.setState({
            open: !this.state.open,
        });
        // Clear everything if modal is closed and no error
        if (this.state.errormessage) {
            this.setState({
                open: true,
                submitted: false,
                response: false,
                confirm: false
            });
        } else {
            this.setState({
                submitted: false,
                response: false,
                validated: false,
                confirm: false,
                substitution: false
            });
        }
    }

    // Go to the correct Dashboard when selected
    goDashboard() {
        // window.location.replace('/dashboard');
        this.followLink('/dashboard')
    }

    followLink = (link) => {
        if (link && link.length > 0)
            this.setState({ link: link })
    }

    close() {
        this.setState({
            open: !this.state.open,
            response: '',
            sumProposed: 0,
            submitted: true,
            validated: true,
            tbl: []
        });
        this.goDashboard()
    }

    back() {
        console.log('back')
        this.setState({
            open: true,
            response: '',
            submitted: false,
            validated: false,
            headlineStep: "1"
        });
    }

    /**
     * Set focus with ref
     */
    focus() {
        this.numberInput.current.focus();
    }

    /**
     * Set focus with ref
     */
    focusPropose() {
        this.numberProposeInput.current.focus();
    }

    /**
     * Anv stegar upp föreslaget coll value. Beräkna det genom att ta 
     * Collvalue/totala volym för att veta värdet per asset. (Collvalue/volym) * volym = föreslagen collvalue. 
     * Föreslagna ska vara högre än marginalkravet men inte mer än Collvalue som finns för den valda säkerheten. 
     * 
     * @param {*} e 
     */
    onChangeVolume(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState(
            { addNumber: e.target.value }
        );
        // Calculate value when user change volume
        this.calculateValue(e.target.value);
    }

    onProposeChangeVolume(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState(
            { addProposeNumber: e.target.value }
        );
        // Calculate value when user change volume
        this.calculateProposeValue(e.target.value);
    }

    /**
     * Get the value from the dropdown
     * @param {*} e 
     */
    onChangeDropdown(e) {
        this.loadColHoldning();
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value) {
            this.setState(
                {
                    colHoldingvalue: e.target.value,
                    //validated: true
                }
            );
        } else {
            this.setState(
                { validated: false }
            );
        }

        this.getAssets(e.target.value)
    }

    /**
     * Check if user press tab or return in the volume field. 
     * Then run calculateCollateralItem. 
     * @param {*} e 
     */
    volumeFocus(e) {
        // Check if user press the tab key 
        const code = e.keyCode || e.which;
        if (code === 9 || code === 13) {
            this.setState(
                {
                    toggledvolumeFocus: !this.state.toggledvolumeFocus
                }
            );
            // stop return from closing the modal
            e.preventDefault();
            //e.stopPropagation();
            this.calculateCollateralItem();
        }
    }

    /**
  * Check if user press tab or return in the volume field. 
  * Then run calculateCollateralItem. 
  * @param {*} e 
  */
    volumeProposeFocus(e) {
        // Check if user press the tab key 
        const code = e.keyCode || e.which;
        if (code === 9 || code === 13) {
            this.setState(
                {
                    toggledvolumeFocus: !this.state.toggledvolumeFocus
                }
            );
            // stop return from closing the modal
            e.preventDefault();
            //e.stopPropagation();
            this.calculateProposeItem();
        }
    }

    /**
     * Get list of colHolding accounts and assets for each. For the first view
     */
    loadColHoldning() {
        let options = { filter: "name:\"" + this.state.marginPos + "\"" };
        api.list("/marginpos", options).then(res => {
            var i = 0;
            var colaccount = [];
            // Iterate through the actions and load display data for the select
            res.forEach(marginPos => {
                marginPos.collHolding.forEach(holding => {
                    colaccount[i] = {};
                    colaccount[i] = holding;
                    colaccount[i].description = holding.description;
                    colaccount[i].asset = holding.asset;
                    i++;
                })
            })

            this.setState({
                colaccount: colaccount,
                marginpos: res
            });

        }).catch(err => { });
    }

    getAssets(collHoldingvalue) {
        // Pass in array with name of this.state.colHoldingvalue from dropdown and find all the collateral in the marginpos.
        // ladda typeaheaden med data
        const getArray = this.state.colaccount.find(el => el.name === collHoldingvalue);
        console.log('getArray', getArray)

        this.setState({
            dataLoaded: true,
            //data: getArray.collateral
            data: getArray.holding
        });
    }

    /**
     * Populate second typeahead with assets
     * @param {*} e 
     */
    loadData() {
        console.log("-- Modal substitution loading data")
        let options = { filter: "owner:" + this.state.marginPos.giver };
        api.list("/account", options).then(res => {
            let data = [];
            // Iterate through the accounts and load display data specific to the table
            res.forEach(account => {
                if (account) {
                    // Iterate through the assets of an accounts
                    account.holding.forEach(asset => {
                        data.push({
                            account: account.name,
                            asset: asset.asset,
                            description: asset.description,
                            volume: asset.volume,
                            value: asset.value,
                            haircut: asset.haircut,
                            collValue: asset.collValue,
                            currency: asset.currency
                        });
                    })
                }
            })
            this.setState({
                dataProposeLoaded: true,
                dataPropose: data
            });
        }).catch(err => { console.log(err) });
    }


    /**
     * change state so the next view is visible
     * @param {*} e 
     */
    ProposeCollReturn(e) {
        e.preventDefault();
        this.setState({
            open: true,
            submitted: true,
            headlineStep: "2",
            substitution: false
        });
    }

    /**
     * Submit the substitution request.
     * @param {*} e 
     */
    onSubmitRecall(e) {
        e.preventDefault();
        let theSum = this.state.transaction.netCollMargin + this.state.sumProposeProposed - this.state.sumProposed;
        if (this.state.tbl && this.state.tblpropose) {
            if (theSum > 0) {
                this.setState({
                    validated: true,
                    substitution: true
                });
            }
            // create action
            if (this.state.validated && theSum > 0) {
                //  e.preventDefault();
                let marginPos = this.state.marginPos;
                /*let data = {
                    "name": "substitution",
                    "state": "proposed",
                    "stateLog": [
                        { "state": "requested", "timeStamp": Date.now() },
                        { "state": "proposed", "timeStamp": Date.now() },
                        { "state": "approved", "timeStamp": 0 },
                        { "state": "transferring", "timeStamp": 0 },
                        { "state": "completed", "timeStamp": 0 }
                    ],
                    "deadline": Date.now() + 3600 * 1000,
                    "giver": this.state.transaction.giver,
                    "taker": this.state.transaction.taker,
                    "marginPos": marginPos,
                    "netCollMargin": theSum, // this.state.transaction.netCollMargin
                    "collReturn": this.state.tbl,
                    "collSubmit": this.state.tblpropose,
                    "nextState": "approved",
                    //"nextStateUser": "approved",
                    "collValueChange": this.state.sumProposeProposed - this.state.sumProposed,
                    "collHolding": this.state.colHoldingvalue,


                };*/

                let body = {
                    "marginPos": marginPos, // marginPos name
                    "type": "substitution",
                    "remaining": 60,
                    "message": this.state.text
                }

                api.create("/colr", body)
                    .then(res => {
                        this.setState({
                            open: true,
                            submitted: true,
                            response: "Created new request for substitution!"
                        });
                    });
            }
        }
    }

    /**
     * Get selected item from the dropdown list.
     * Hide the dropdown list by setting it to none first and move
     * focus to the volume field.
     * After volume is added and the user has tabbed past the last field, 
     * caculate asset volume and add the value in the json and
     * put it in a new object to avoid refs, then push
     * it to the this.state.tbl and show it in a table view below.
     * @method getItem()
     * @param {*} e 
     */
    getItem(e) {
        // set focus to the volume field with a function that uses a ref..
        if (e && e.length) {
            let resulten = e;
            this.setState({ selectedItem: resulten })
            // not empty 
            this.focus();
        }
    };


    getProposedItem(e) {
        // set focus to the volume field with a function that uses a ref..
        if (e && e.length) {
            let resulten = e;
            this.setState({ selectedProposeItem: resulten })
            // not empty 
            this.focusPropose();
        }
    };

    /**
     * User selects amount of securitys and the value for the selected is
     * calculated based on each assets value.
     *  
     * @method calculateValue()
     * @param {*} input 
     */
    calculateValue(input) {
        let grunkan = this.state.selectedItem;
        if (!Array.isArray(grunkan)) {
            grunkan = [grunkan];
        }
        //  let units = 0;
        let value = 0;
        let minvalue = 0;
        let haircut = 0;
        let collValue = 0;
        let intValue = parseInt(input);

        if (this.state.dataLoaded && grunkan[0]) {
            value = grunkan[0].value * intValue / grunkan[0].volume
            haircut = grunkan[0].haircut * intValue / grunkan[0].volume
            collValue = grunkan[0].collValue * intValue / grunkan[0].volume
            value = parseInt(value);
            this.setState({ minvalue: minvalue })
            this.setState({ maxvalue: grunkan[0].collValue })
            this.setState({ selectedItemVolume: intValue })
            this.setState({ step: 1 })
        }

        // let calcValue = parseInt(units * input);

        if (value > grunkan[0].value) {
            this.setState(
                {
                    validated: false,
                    validationmessage: "To large value, try something else!",
                    numberInputisValid: false,
                    numberInputisInvalid: true
                }
            )
        } else {
            this.setState(
                {
                    validated: true,
                    validationmessage: "",
                    numberInputisValid: true,
                    numberInputisInvalid: false
                }
            )
        }
        this.setState(
            {
                selectedItemValue: value,
                calcHaircut: haircut,
                calccolValue: collValue
            }
        )
    }

    /**
    * User selects amount of securitys and the value for the selected is
    * calculated based on each assets value.
    *  
    * @method calculateValue()
    * @param {*} input 
    */
    calculateProposeValue(input) {
        let grunkan = this.state.selectedProposeItem;
        if (!Array.isArray(grunkan)) {
            grunkan = [grunkan];
        }
        // let units = 0;
        let value = 0;
        //let minvalue = 0;
        let haircut = 0;
        let collValue = 0;
        let intValue = parseInt(input);

        if (this.state.dataLoaded && grunkan[0]) {

            // units = grunkan[0].collValue / grunkan[0].volume;
            value = grunkan[0].value * intValue / grunkan[0].volume
            haircut = grunkan[0].haircut * intValue / grunkan[0].volume
            collValue = grunkan[0].collValue * intValue / grunkan[0].volume
            value = parseInt(value);
            this.setState({ selectedProposeItemVolume: intValue })
            this.setState({ step: 1 })
        }

        // let calcValue = parseInt(units * input);

        if (value > grunkan[0].value) {
            this.setState(
                {
                    validated: false,
                    validationmessage: "To large value, try something else!",
                    numberInputisValid: false,
                    numberInputisInvalid: true
                }
            )
        } else {
            this.setState(
                {
                    validated: true,
                    validationmessage: "",
                    numberInputisValid: true,
                    numberInputisInvalid: false
                }
            )
        }
        this.setState(
            {
                selectedProposeItemValue: value,
                calcProposeHaircut: haircut,
                calcProposecolValue: collValue
            }
        )
    }

    /**
     * Check if it's a object
     * @param {*} obj 
     */
    isPlainObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    };

    /**
     * Create a new object and Add value and volume to it.
     * Clear the fields and calculate sumProposed. 
     * 
     * @method calculateCollateralItem()
     *  
     */
    calculateCollateralItem() {
        let obj = this.state.selectedItem;
        let d = new Date();

        if (!Array.isArray(obj)) {
            obj = [obj];
        }

        let uid = obj[0].asset + d.getMilliseconds()

        // create new object to skip the reference to the old object.
        if (this.state.selectedItemValue && obj[0].asset && this.state.selectedItemVolume && Array.isArray(this.state.tbl) && this.state.validated) {

            let newobj = {
                'volume': this.state.selectedItemVolume,
                'asset': obj[0].asset,
                '_id': obj[0]._id,
                'collValue': this.state.calccolValue,
                'description': obj[0].description,
                'haircut': this.state.calcHaircut,
                //'marketValue': obj[0].marketValue,
                'value': this.state.selectedItemValue,
                'Remove': [<FontAwesomeIcon icon={["fas", "pencil-alt"]} />, "#" + uid],
                'uid': uid // unique id set so that we can remove items 
            }

            if (this.isPlainObject(newobj)) {
                // add object to the table   
                let tblArray = this.state.tbl;
                tblArray.push(newobj);
                this.setState({
                    tbl: tblArray
                })
                // Clear the fields
                this.setState({
                    addNumber: '',
                    selectedItemValue: '',
                    selectedItem: {}
                });
                this._typeahead.clear();
                // Calculate sumProposed
                let array = this.state.tbl;
                let sumProposed = array.reduce((acc, curr) => acc + curr.collValue, 0);
                this.setState({
                    sumProposed: sumProposed,
                    validated: false,
                    validationmessage: "Value is to high"
                })
                if (this.state.transaction.netCollMargin + sumProposed > 0) {
                    this.setState({
                        validated: true,
                        validationmessage: ""
                    });
                }
            }
        }
    }

    /**
     * Create a new object and Add value and volume to it.
     * Clear the fields and calculate sumProposed. 
     * 
     * @method calculateProposeItem()
     *  
     */
    calculateProposeItem() {
        let obj = this.state.selectedProposeItem;
        let d = new Date();

        if (!Array.isArray(obj)) {
            obj = [obj];
        }

        let uid = obj[0].asset + d.getMilliseconds()

        // create new object to skip the reference to the old object.
        if (this.state.selectedProposeItemValue && obj[0].asset && this.state.selectedProposeItemVolume && Array.isArray(this.state.tblpropose) && this.state.validated) {

            let newproposeobj = {
                'volume': this.state.selectedProposeItemVolume,
                'asset': obj[0].asset,
                '_id': obj[0]._id,
                'collValue': this.state.calcProposecolValue,
                'description': obj[0].description,
                'haircut': this.state.calcProposeHaircut,
                //'marketValue': obj[0].marketValue,
                'value': this.state.selectedProposeItemValue,
                'Remove': [<FontAwesomeIcon icon={["fas", "pencil-alt"]} />, "#" + uid],
                'uid': uid // unique id set so that we can remove items 
            }

            if (this.isPlainObject(newproposeobj)) {
                // add object to the table   
                let tblArray = this.state.tblpropose;
                tblArray.push(newproposeobj);
                this.setState({
                    tblpropose: tblArray
                })
                // Clear the fields
                this.setState({
                    addProposeNumber: '',
                    //selectedItemValue: '',
                    selectedProposeItemValue: '',
                    selectedProposeItem: {}
                });
                this._typeProposeahead.clear();
                // Calculate sumProposed
                let array = this.state.tblpropose;
                let sumProposed = array.reduce((acc, curr) => acc + curr.collValue, 0);
                this.setState({
                    sumProposeProposed: sumProposed,
                    validated: false,
                    validationmessage: "Value is to high"
                })
                if (this.state.transaction.netCollMargin + sumProposed > 0) {
                    this.setState({
                        validated: true,
                        validationmessage: ""
                    });
                }
                console.log('Validated: ', this.state.validated)
            }
        }
    }

    /**
     * Get address hash from browser and remove item from the tableview.
     * @method hashChange()
     * @param {*} hash 
     */
    hashChange(hash) {

        hash = hash.slice(1);
        //delete from tbl
        let removeIndex = this.state.tbl.map(function (item) { return item.uid; }).indexOf(hash);
        // get the object so we can move it to the input field..
        let newTbl = this.state.tbl;
        const result = newTbl.find(({ uid }) => uid === hash);
        // Place info in input field
        if (result) {
            this.getItem([result]);
            this.setState({ selectedItem: result });
            this.setState({ addNumber: result.volume });
            this.setState({ selectedItemValue: result.collValue });
            this.setState({ defaultvalue: [result] })
            // remove object
            let spliceArray = this.state.tbl;
            spliceArray.splice(removeIndex, 1);
            this.setState(
                {
                    tbl: spliceArray
                }
            )
            this.setState({ validated: false })
            // clear hash in url
            if (removeIndex) {
                window.location.hash = '';
            }
        }

    }

    /**
         * Get address hash from browser and remove item from the tableview.
         * @method hashChange()
         * @param {*} hash 
         */
    hashProposeChange(hash) {

        hash = hash.slice(1);
        //delete from tbl
        let removeIndex = this.state.tblpropose.map(function (item) { return item.uid; }).indexOf(hash);
        // get the object so we can move it to the input field..
        let newTbl = this.state.tblpropose;
        const result = newTbl.find(({ uid }) => uid === hash);
        // Place info in input field
        if (result) {
            this.getProposedItem([result]);
            this.setState({ selectedProposeItem: result });
            this.setState({ addProposeNumber: result.volume });
            this.setState({ selectedProposeItemValue: result.collValue });
            this.setState({ defaultvalue: [result] })
            // remove object
            let spliceArray = this.state.tblpropose;
            spliceArray.splice(removeIndex, 1);
            this.setState(
                {
                    tblpropose: spliceArray,
                    substitution: true
                }
            )
            this.setState({ validated: false })
            // clear hash in url
            if (removeIndex) {
                window.location.hash = '';
            }
        }
    }

    postMessage = (evt) => {
        this.setState({
            text: evt.target.value
        });
    }

    /**
     * Second view to show in modal
     * @method ProposeView
     */
    ProposeView = () => {
        const { transaction, sumProposed, sumProposeProposed, dataPropose, selectedProposeItemValue, tblpropose, multiple, validated, marginPos, validationProposemessage } = this.state;
        const chooseAsset = this.intl.formatMessage({ id: "CcpTransaction.escalation.modal.inputasset" }); // Choose asset...
        const chooseVolume = this.intl.formatMessage({ id: "CcpTransaction.escalation.modal.inputvolume" }); // add value...

        let theSum = sumProposeProposed - sumProposed;
        console.log('tblpropose ', tblpropose)

        return (

            <>
                <Row className="mt-3">
                    <Col>
                        <h4>
                            <FormattedMessage id="CmTransaction.escalation.propose.button" /> {marginPos}
                        </h4>
                    </Col>
                </Row>
                <Form noValidate validated={validated}  >
                    {/*  <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label><FormattedMessage id="CmTransaction.propose.colatteral.modal.selectlabel" /></Form.Label>
                                            {colaccount &&
    
                                                <Form.Control as="select" autoFocus={true} onChange={this.onChangeDropdown} required>
                                                    <option value="" >{selectAccount}</option>
                                                    {colaccount.map((item, i) => {
                                                        return <option key={i} value={item.holding}>{item.holding}, {item.description}</option>
                                                    })}
                                                </Form.Control>
    
    
                                            }
                                        </Form.Group>*/}
                    <FormattedMessage id="CmTransaction.propose.colatteral.modal.selectlabel" /> {": " + this.state.colHoldingvalue}
                    <Row className="mt-2">
                        <Col {...widthLeft}>
                            <b>
                                <FormattedMessage id="currentposition" />
                            </b>
                        </Col>
                        <Col {...widthRight} className="text-right">
                            {numberRedFormat(transaction.netCollMargin)}
                        </Col>
                    </Row>
                    <Row>
                        <Col {...widthLeft} className="text-left">
                            <b>
                                <FormattedMessage id="CmTransaction.escalation.recall.collateral" />
                            </b>
                        </Col>
                        <Col  {...widthRight} className="mb-1 border-bottom text-right">
                            {numberRedFormat(-sumProposed)}
                        </Col>
                    </Row>
                    <Row>
                        <Col {...widthLeft} className="text-left">
                            <b>
                                <FormattedMessage id="CmTransaction.escalation.substitution.collateral" />
                            </b>
                        </Col>
                        <Col  {...widthRight} className="mb-1 border-bottom text-right">
                            {numberRedFormat(sumProposeProposed)}
                        </Col>
                    </Row>
                    <Row>
                        <Col {...widthLeft} className="text-left">
                            <b><FormattedMessage id="CmTransaction.escalation.substitution.position" /></b>
                        </Col>
                        <Col {...widthRight} className="text-right mb-4">
                            {numberRedFormat(transaction.netCollMargin + theSum)}
                        </Col>
                    </Row>

                    <React.Fragment>
                        <Table className="table table-hover table-sm table-responsive-md">
                            <thead>
                                <tr>
                                    <th className="text-left text-uppercase">
                                        <FormattedMessage id="Table.holdings.asset" />
                                    </th>
                                    <th className="text-right text-uppercase">
                                        <FormattedMessage id="Table.holdings.volume" />
                                    </th>
                                    <th className="text-right text-uppercase">
                                        <FormattedMessage id="Table.holdings.collValue" />
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td className="text-right">
                                        {this.state.dataLoaded && (
                                            <React.Fragment>
                                                <Form.Group>
                                                    <Typeahead
                                                        id="typeProposeaheadid"
                                                        labelKey="asset"
                                                        multiple={multiple}
                                                        selected={this.state.defaultvalue}
                                                        options={dataPropose}
                                                        selectHintOnEnter={true}
                                                        onChange={(e) => this.getProposedItem(e)}
                                                        onInputChange={() => this.setState({ defaultvalue: undefined })}
                                                        placeholder={chooseAsset}
                                                        ref={(ref) => this._typeProposeahead = ref}
                                                    />
                                                </Form.Group>
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td className="text-right">
                                        <Form.Group controlId="value">
                                            <Form.Control
                                                type="number"
                                                step={this.state.stepPropose}
                                                ref={this.numberProposeInput}
                                                placeholder={chooseVolume}
                                                value={this.state.addProposeNumber}
                                                onChange={this.onProposeChangeVolume}
                                                onKeyDown={this.volumeProposeFocus}
                                                isValid={this.state.numberProposeInputisValid}
                                                isInvalid={this.state.numberProposeInputisInvalid}
                                                required
                                            />
                                        </Form.Group>
                                    </td>
                                    <td className="text-right">
                                        {selectedProposeItemValue}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Row className="mt-3">
                            <Col>
                                <p>{validationProposemessage}</p>
                            </Col>
                        </Row>
                    </React.Fragment>

                    <TableView headingIntl={this.props.headingIntl} format={this.state.format} data={tblpropose} />
                    <PostMessage onTextChange={this.postMessage} />
                </Form>
            </>
        )
    }

    render() {
        if (this.state.link) {
            return <Redirect to={this.state.link} />
        }
        const { colaccount, transaction, submitted, substitution, sumProposed, sumProposeProposed, response, errormessage, data, selectedItemValue, tbl, multiple, validated, marginPos, validationmessage, headlineStep } = this.state;
        /*const chooseAsset = "Choose asset";
        const chooseVolume = "add value..";
        const selectAccount = "add value...";*/
        const chooseAsset = this.intl.formatMessage({ id: "CcpTransaction.escalation.modal.inputasset" }); // Choose asset...
        const chooseVolume = this.intl.formatMessage({ id: "CcpTransaction.escalation.modal.inputvolume" }); // add value...
        const selectAccount = this.intl.formatMessage({ id: "CmTransaction.propose.colatteral.modal.selectaccount" }); // add value...
        console.log('CollValueChange: ', this.state.sumProposed)

        // detect if browser address field hash changed 
        if (window.location.hash) {
            this.hashChange(window.location.hash);
            this.hashProposeChange(window.location.hash);
        }
        console.log('Submitted: ', submitted)
        console.log('Subsitutet: ', substitution)
        return (
            <>
                <Button variant="outline-primary" className="mb-1" block size="sm" onClick={this.toggle}>
                    <FormattedMessage id={"Action.substitution.button"} />
                </Button>

                <>
                    <Modalcomp show={this.state.open} parentAction={this.toggle} titleIntl={"Action.substitution.button" + headlineStep} size="lg" backdrop={"static"}>

                        {!submitted ? (
                            <>
                                <Modal.Body>

                                    {response &&
                                        <>  <b className="text-danger">{errormessage}</b><br />
                                            <b><FormattedMessage id={response} defaultMessage={response}
                                                values={{
                                                    a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                }}
                                            /></b>
                                            <br /><br />
                                        </>}
                                    <Row className="mt-3">
                                        <Col>
                                            <h4>
                                                <FormattedMessage id="Action.substitution.button" /> {marginPos}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Form noValidate validated={validated}>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label><FormattedMessage id="CmTransaction.propose.substitution.modal.selectlabel" /></Form.Label>
                                            {colaccount &&

                                                <Form.Control as="select" autoFocus={true} onChange={this.onChangeDropdown} required>
                                                    <option value="" >{selectAccount}</option>
                                                    {colaccount.map((item, i) => {
                                                        return <option key={i} value={item.name}>{item.name}, {item.description}</option>
                                                    })}
                                                </Form.Control>


                                            }
                                        </Form.Group>
                                        <Row className="mt-2">
                                            <Col {...widthLeft}>
                                                <b>
                                                    <FormattedMessage id="currentposition" />
                                                </b>
                                            </Col>
                                            <Col {...widthRight} className="text-right">
                                                {numberRedFormat(transaction.netCollMargin)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col {...widthLeft} className="text-left">
                                                <b>
                                                    <FormattedMessage id="CmTransaction.escalation.recall.collateral" />
                                                </b>
                                            </Col>
                                            <Col  {...widthRight} className="mb-1 border-bottom text-right">
                                                {numberRedFormat(-sumProposed)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col {...widthLeft} className="text-left">
                                                <b>
                                                    <FormattedMessage id="CmTransaction.escalation.substitution.collateral" />
                                                </b>
                                            </Col>
                                            <Col  {...widthRight} className="mb-1 border-bottom text-right">
                                                {numberRedFormat(sumProposeProposed)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col {...widthLeft} className="text-left">
                                                <b><FormattedMessage id="CmTransaction.escalation.substitution.position" /></b>
                                            </Col>
                                            <Col {...widthRight} className="text-right mb-4">
                                                {numberRedFormat(transaction.netCollMargin - sumProposed)}
                                            </Col>
                                        </Row>



                                        <React.Fragment>
                                            <Table className="table table-hover table-sm table-responsive-md">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left text-uppercase">
                                                            <FormattedMessage id="Table.holdings.asset" />
                                                        </th>
                                                        <th className="text-right text-uppercase">
                                                            <FormattedMessage id="Table.holdings.volume" />
                                                        </th>
                                                        <th className="text-right text-uppercase">
                                                            <FormattedMessage id="Table.holdings.collValue" />
                                                        </th>

                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    <tr>
                                                        <td className="text-right">

                                                            {this.state.dataLoaded && (

                                                                <React.Fragment>
                                                                    <Form.Group>
                                                                        <Typeahead
                                                                            id="typeaheadid"
                                                                            labelKey="asset"
                                                                            multiple={multiple}
                                                                            selected={this.state.defaultvalue}
                                                                            options={data}
                                                                            selectHintOnEnter={true}
                                                                            onChange={(e) => this.getItem(e)}
                                                                            onInputChange={() => this.setState({ defaultvalue: undefined })}
                                                                            placeholder={chooseAsset}
                                                                            ref={(ref) => this._typeahead = ref}

                                                                        />

                                                                    </Form.Group>
                                                                </React.Fragment>
                                                            )}

                                                        </td>
                                                        <td className="text-right">
                                                            <Form.Group controlId="value">

                                                                <Form.Control
                                                                    type="number"
                                                                    step={this.state.step}
                                                                    ref={this.numberInput}
                                                                    placeholder={chooseVolume}
                                                                    value={this.state.addNumber}
                                                                    onChange={this.onChangeVolume}
                                                                    onKeyDown={this.volumeFocus}
                                                                    isValid={this.state.numberInputisValid}
                                                                    isInvalid={this.state.numberInputisInvalid}
                                                                    required
                                                                />

                                                            </Form.Group>

                                                        </td>
                                                        <td className="text-right">
                                                            {selectedItemValue}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                            <Row className="mt-3">
                                                <Col>
                                                    <p>{validationmessage}</p>
                                                </Col>
                                            </Row>

                                        </React.Fragment>

                                        <TableView headingIntl={this.props.headingIntl} format={this.state.format} data={tbl} />
                                    </Form>
                                </Modal.Body>

                                <Modal.Footer>
                                    {!submitted &&

                                        <Button variant="outline-primary" className="mb-1" size="sm" onClick={(e) => this.ProposeCollReturn(e)}>
                                            <FormattedMessage id={"CmMarginPos.escalation.substitution.next"} defaultMessage=" Next " />
                                        </Button>
                                    }
                                    {/*submitted && <Button variant="outline-primary" className="mb-1" size="sm" onClick={(e) => this.next(e)}>
                                            <FormattedMessage id="CcpMarginPos.substitution.next" defaultMessage="back" />
                                        </Button>*/}
                                </Modal.Footer>
                            </>
                        ) : (<>
                            <Modal.Body>

                                {response &&
                                    <>  <b className="text-danger">{errormessage}</b><br />
                                        <b><FormattedMessage id={response} defaultMessage={response}
                                            values={{
                                                a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                            }}
                                        />  {marginPos}</b>
                                        <br /><br />
                                    </>
                                }
                                {/*View for propose collateral */}
                                {!substitution &&
                                    < this.ProposeView />
                                }

                            </Modal.Body>
                            <Modal.Footer>
                                {!substitution &&
                                    <Button variant="outline-primary" className="mb-1" size="sm" onClick={(e) => this.back(e)}>
                                        <FormattedMessage id="CcpMarginPos.escalation.substitution.back" defaultMessage="Back" />
                                    </Button>
                                }
                                {!substitution &&
                                    <Button variant="outline-primary" className="mb-1" size="sm" onClick={(e) => this.onSubmitRecall(e)}>
                                        <FormattedMessage id="CmMarginPos.escalation.substitution.button" defaultMessage="Substitute" />
                                    </Button>
                                }
                                {submitted &&
                                    <Button variant="outline-primary" className="mb-1" size="sm" onClick={(e) => this.close(e)}>
                                        <FormattedMessage id="CmMarginPos.escalation.substitution.close" defaultMessage="Close" />
                                    </Button>
                                }
                            </Modal.Footer>
                        </>
                            )}

                    </Modalcomp>
                </>

            </>
        )

    }


}

export default injectIntl(ModalSubstitution);