/**
 * App: Subview of transaction detils page
 * 
 * @module components/details-transaction
 * 
 * @requires NPM:react
 * @requires NPM:react-intl
 * @requires NPM:react-bootstrap
 * @requires module:utils/tableview
 * @requires module:utils/countdown
 */

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { numberRedFormat } from '../utils/tableview';
import { CountdownButton, makeDeadline } from '../utils/countdown';

const widthLeft  = { xs: 7, sm: 6, md: 5, lg: 5, xl: 5 }
const widthRight = { xs: 5, sm: 4, md: 3, lg: 3, xl: 3 }

/**
 * Subview of transaction detils page: Section shows details of the transaction
 *
 * @class TransactionDetailsSubview
 * @extends {Component}
 */
export default class TransactionDetailsSubview extends Component {
    render() {
        console.log("Props", this.props)
        const transaction = this.props.transaction;
        if (!transaction) return null;

        let deadline = makeDeadline(transaction.deadline);
        if (transaction.state === 'completed' || transaction.state === 'cancelled') {
            deadline = undefined;
        }

        return (
            <>
                <h2>
                    <FormattedMessage id={"CcpTransaction.heading." + transaction.name} />
                    &nbsp;
                    {transaction.taker + ": " + transaction.marginPos}
                </h2>

                <Row>
                    <Col {...widthLeft}>
                        <b>
                            <FormattedMessage id={transaction.netCollMargin < 0 ? "deficit" : "surplus"} />
                        </b>
                    </Col>
                    <Col {...widthRight} className="text-left">
                        {numberRedFormat(transaction.netCollMargin)}
                    </Col>
                </Row>
                <Row>
                    <Col {...widthLeft}>
                        <b>
                            <FormattedMessage id="CcpTransaction.details.next" />
                        </b>
                    </Col>
                    <Col className="mb-1">
                        <FormattedMessage id={"next." + transaction.nextState} />
                    </Col>
                </Row>
                {deadline &&
                    <Row>
                        <Col {...widthLeft}>
                            <b>
                                <FormattedMessage id="CcpTransaction.details.deadline" />
                            </b>
                        </Col>
                        <Col {...widthRight} className="mb-1">
                            <CountdownButton block size="sm" deadline={deadline} />
                        </Col>
                    </Row>
                }
            </>

        )
    }
}