/**
 * App: Display an overview of a selected collateral requests
 * 
 * @module components/overview-transaction
 * 
 * @requires NPM:react
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires module:utils/global
 * 
 * @requires module:components/details-transaction
 * @requires module:components/details-progress
 * @requires module:components/details-chat
 * @requires module:components/table-marginpos
 * @requires module:components/table-holdings
 * @requires module:components/table-assets
 * @requires module:components/overview-counterparty
 * 
 * @requires module:components/modal-contacts
 * @requires module:components/modal-colr-extend
 * @requires module:components/modal-colr-propose
 * @requires module:components/modal-colr-review
 * @requires module:components/modal-colr-transfer
 * @requires module:components/modal-colr-confirm
 * @requires module:components/modal-colr-cancel
 */

import React, { Component } from 'react';
import { Container, Button, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { global } from '../utils/global';

import TransactionDetails from './details-transaction';
import TransactionProgress from './details-progress';
import TransactionChat from './details-chat';
import { MarginPosTable } from './table-marginpos';
import { HoldingTable } from './table-holdings'
import { AssetTable } from './table-assets'
import { CounterpartyOverview } from './overview-counterparty';

import ContactDetails from "./modal-contacts";
import ExtendDedlineModal from "./modal-colr-extend";
import ModalPropose from './modal-colr-propose';
import ReviewModal from './modal-colr-review';
import ModalTransfer from './modal-colr-transfer';
import ModalConfirm from './modal-colr-confirm';
import CancelModal from './modal-colr-cancel';

const widthLeft = { xs: 7, sm: 6, md: 5, lg: 5, xl: 5 }
const widthRight = { xs: 5, sm: 4, md: 3, lg: 3, xl: 3 }

/**
 * Present a single transaction with relevant overviews
 *
 * @export
 * @class CmTransactionDetails
 * @extends {Component}
 */
export default class TransactionOverview extends Component {
    render() {
        const transaction = this.props.allCollRequests &&
            this.props.allCollRequests.find(({ _id }) => _id === this.props.match.params.id);
        if (!transaction) return null;
        const isTaker = transaction.taker === global.user.organisation.name;
        const counterpartyName = isTaker ? transaction.giver : transaction.taker;

        return (
            <React.Fragment>
                <Container className="mt-5">
                    <Row>
                        <Col> <h1><FormattedMessage id="CcpTransaction.heading" /></h1></Col>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col className="mt-5" lg={7}>
                            <TransactionDetails transaction={transaction} />
                            <TransactionEscalation transaction={transaction} allMarginPositions={this.props.allMarginPositions} />
                            <TransactionProgress transaction={transaction} />
                        </Col>

                        <Col className="mt-5" lg={5}>
                            <TransactionChat user={global.user} transaction={transaction} reverse={true} headingIntl="CcpTransaction.chat.heading" />
                        </Col>
                    </Row>
                </Container>

                <MarginPosTable
                    headingIntl="Heading.allMarginPositions"
                    allMarginPositions={this.props.allMarginPositions}
                    marginPos={transaction.marginPos} />

                <HoldingTable
                    headingIntl="Heading.allCollateral"
                    allMarginPositions={this.props.allMarginPositions}
                    marginPos={transaction.marginPos} />

                <AssetTable 
                    headingIntl="Heading.allAssets" 
                    account={global.user.organisation.account} />

                <div className="alt-background mt-5 py-2">
                    <CounterpartyOverview
                        headingIntl="Heading.counterparty"
                        allCollRequests={this.props.allCollRequests}
                        allMarginPositions={this.props.allMarginPositions}
                        counterpartyName={counterpartyName} />
                </div>
            </React.Fragment>
        )
    }
}

/**
 * Subview of transaction detils page: Section shows different actions to take relevant 
 * to the current transaction state
 *
 * @class TransactionEscalation
 * @extends {Component}
 */
class TransactionEscalation extends Component {
    render() {
        const transaction = this.props.transaction;
        if (!transaction) return null;
        const isTaker = transaction.taker === global.user.organisation.name;
        const counterpartyName = isTaker ? this.props.transaction.giver : this.props.transaction.taker;

        // Logic for which actions to propose
        let showPropose = transaction.nextStateUser === 'proposed';
        let showReview = transaction.nextStateUser === 'approved';
        let showTransfer = transaction.nextStateUser === 'transferring';
        let showConfirm = transaction.nextStateUser === 'completed';
        let showContact = true;
        let showExtend = false;
        let showCancel = false;
        let showDirectDebit = false;
        let showDefault = false;
        if (isTaker) {
            showExtend = transaction.name === 'margincall' && ['requested', 'proposed', 'approved', 'transferring'].indexOf(transaction.state) > -1;
            showCancel = transaction.name === 'margincall' && ['requested', 'proposed', 'approved', 'transferring'].indexOf(transaction.state) > -1;
            showDirectDebit = transaction.netCollMargin < 0 && ['requested', 'proposed', 'approved'].indexOf(transaction.state) > -1;
            //showDefault = transaction.netCollMargin < 0 && ['requested', 'proposed', 'approved', 'transferring'].indexOf(transaction.state) > -1;
        } else {
            showExtend = ['substitution', 'callback'].indexOf(transaction.name) > -1 && ['requested', 'proposed', 'approved', 'transferring'].indexOf(transaction.state) > -1;
            showCancel = ['substitution', 'callback'].indexOf(transaction.name) > -1 && ['requested', 'proposed', 'approved', 'transferring'].indexOf(transaction.state) > -1;
        }

        return (
            <>
                <Row className="mt-4">
                    <Col>
                        <h5>
                            <FormattedMessage id="Action.heading" />
                        </h5>
                    </Col>
                </Row>

                {showPropose &&
                    <Row>
                        <Col {...widthLeft}>
                            <b>
                                -&nbsp;
                            <FormattedMessage id={"CmTransaction.escalation.propose"} />
                            </b>
                        </Col>
                        <Col {...widthRight}>{/* ModalPropose */}
                            <ModalPropose transaction={transaction} allMarginPositions={this.props.allMarginPositions} />
                        </Col>
                    </Row>
                }

                {showReview &&
                    <Row>
                        <Col {...widthLeft}>
                            <b>
                                -&nbsp;
                            <FormattedMessage id={"Action.review." + transaction.name} />
                            </b>
                        </Col>
                        <Col {...widthRight}>
                            <ReviewModal transaction={this.props.transaction} />
                        </Col>
                    </Row>
                }

                {showTransfer &&
                    <Row>
                        <Col {...widthLeft}>
                            <b>
                                -&nbsp;
                            <FormattedMessage id={"CmTransaction.escalation.transfer.button"} />
                            </b>
                        </Col>
                        <Col {...widthRight}>

                            <ModalTransfer transaction={transaction} />
                        </Col>
                    </Row>
                }

                {showConfirm &&
                    <Row>
                        <Col {...widthLeft}>
                            <b>
                                -&nbsp;
                            <FormattedMessage id={"Confirm"} />
                            </b>
                        </Col>
                        <Col {...widthRight}>
                            <ModalConfirm transaction={this.props.transaction} />
                        </Col>
                    </Row>
                }

                {showContact && 
                <Row>
                    <Col {...widthLeft}>
                        <b>
                            -&nbsp;
                            <FormattedMessage id="Action.contact" />
                            &nbsp;
                            {counterpartyName}
                        </b>
                    </Col>
                    <Col {...widthRight}>
                        < ContactDetails orgname={counterpartyName} />
                    </Col>
                </Row>
                }

                {showExtend &&
                    <Row>
                        <Col {...widthLeft}>
                            <b>
                                -&nbsp;
                                <FormattedMessage id="Action.extend" />
                            </b>
                        </Col>
                        <Col {...widthRight}>
                            <ExtendDedlineModal transaction={transaction} />
                        </Col>
                    </Row>
                }

                {showDirectDebit &&
                    <Row>
                        <Col {...widthLeft}>
                            <b>
                                -&nbsp;
                                <FormattedMessage id="Action.directDebit" />
                            </b>
                        </Col>
                        <Col {...widthRight}>
                            <Button variant="outline-warning" className="mb-1" block size="sm">
                                <FormattedMessage id="Action.directDebit.button" />
                            </Button>
                        </Col>
                    </Row>
                }

                {showCancel &&
                    <Row>
                        <Col {...widthLeft}>
                            <b>
                                -&nbsp;
                            <FormattedMessage id="Action.cancel" />
                            </b>
                        </Col>
                        <Col {...widthRight}>
                            < CancelModal transaction={transaction} />
                        </Col>
                    </Row>
                }

                {showDefault &&
                    <Row>
                        <Col {...widthLeft}>
                            <b>
                                -&nbsp;
                                <FormattedMessage id="Action.default" />
                            </b>
                        </Col>
                        <Col {...widthRight}>
                            <Button variant="outline-warning" className="mb-1" block size="sm">
                                <FormattedMessage id="Action.default.button" />
                            </Button>
                        </Col>
                    </Row>
                }
            </>
        )
    }
}
