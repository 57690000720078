/**
 * App: Display modal request substitution
 * 
 * @module components/modal-colr-requestsubst
 * 
 * @requires NPM:react
 * @requires NPM:react-router-dom
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires NPM:react-bootstrap-typeahead
 * @requires NPM:@fortawesome
 * 
 * @requires module:utils/api
 * @requires module:utils/modalcomp
 * @requires module:utils/tableview
 * @requires module:utils/post-message
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Table, Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import api from '../utils/api';
import Modalcomp from "../utils/modalcomp";
import { TableView, numberRedFormat } from '../utils/tableview';
import PostMessage from "../utils/post-message";
library.add(faPencilAlt);

const widthLeft = { xs: 7, sm: 6, md: 5, lg: 4, xl: 3 }
const widthRight = { xs: 5, sm: 4, md: 3, lg: 2, xl: 2 }

/**
 * Button and modal: CCP to request substitution
 * 
 * @class ModalRequestSubstitution
 * @extends {Component}
 * 
 * @param {string} props.headingIntl Heading ID
 * @param {object} props.transaction The current collateral request transaction
 * @param {object} props.marginPos The margin position to to substitute collateral
 */
export class ModalRequestSubstitution extends Component {
    constructor(props) {
        super(props);
        this.intl = props.intl;
        this.state = {
            validationmessage: '',
            numberInputisValid: false,
            numberInputisInvalid: false,
            chooseVolume: '',
            tableEvent: '',
            validated: false,
            chooseAsset: '',
            submitted: false,
            urlHash: '',
            defaultvalue: undefined,
            minvalue: 0,
            maxvalue: 0,
            multiple: false,
            colHoldingvalue: '',
            step: 1,
            addNumber: 0,
            toggledvolumeFocus: false,
            selectedItem: {},
            selectedItemValue: '',
            selectedItemVolume: '',
            open: false,
            openapprove: false,
            opendecline: false,
            sumProposed: 0,
            response: '',
            text: '',
            thevalue: '',
            selectedObjects: [],
            tbl: [],
            subject: '',
            transaction: this.props.transaction,
            marginPos: this.props.marginPos, // marginpos array
            marginpos: {}, // marginpos obj
            format: [
                {
                    field: "asset",
                    headerIntl: "Table.holdings.asset", headerAlign: "text-left text-uppercase",
                    bodyType: "Text", bodyAlign: "text-left"
                },
                {
                    field: "volume",
                    headerIntl: "Table.holdings.volume", headerAlign: "text-right text-uppercase",
                    bodyType: "Number", bodyAlign: "text-right"
                },
                {
                    field: "collValue",
                    headerIntl: "Table.holdings.collValue", headerAlign: "text-right text-uppercase",
                    bodyType: "Number", bodyAlign: " text-right"
                },
                {
                    field: "Remove",
                    headerAlign: "text-right text-uppercase",
                    bodyType: "Link", bodyAlign: " text-right"
                }
            ]
        };

        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
        // this.loadData = this.loadData.bind(this);
        this.getItem = this.getItem.bind(this);
        this.numberInput = React.createRef();
        this.focus = this.focus.bind(this);
        this.volumeFocus = this.volumeFocus.bind(this);
        this.onChangeVolume = this.onChangeVolume.bind(this);
        this.calculateCollateralItem = this.calculateCollateralItem.bind(this);
        this.calculateValue = this.calculateValue.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.isPlainObject = this.isPlainObject.bind(this);
        this.loadColHoldning = this.loadColHoldning.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.hashChange = this.hashChange.bind(this);
        this.goDashboard = this.goDashboard.bind(this);
        this.getAssets = this.getAssets.bind(this);
    }



    componentDidMount() {
        this.loadColHoldning();
        // this.loadData();
    }

    /**
    * Toggle function to pass in as parentAction props to the modal to toggle state 
    */
    toggle() {
        this.setState({
            open: !this.state.open,
        });
        // Clear everything if modal is closed and no error
        if (this.state.errormessage) {
            this.setState({
                open: true,
                submitted: false,
                response: false,
                confirm: false
            });
        } else {
            this.setState({
                submitted: false,
                response: false,
                validated: false,
                confirm: false
            });
        }
    }

    // Go to the correct Dashboard when selected
    goDashboard() {
        window.location.replace('/dashboard');
        //this.followLink('/dashboard');
    }


    followLink = (link) => {
        if (link && link.length > 0)
            this.setState({ link: link })
    }

    close() {
        this.setState({
            open: !this.state.open,
            response: '',
            sumProposed: 0,
            submitted: true,
            validated: true,
            tbl: []
        });
        this.goDashboard();
    }

    /**
     * Set focus with ref
     */
    focus() {
        this.numberInput.current.focus();
    }

    /**
     * Anv stegar upp föreslaget coll value. Beräkna det genom att ta 
     * Collvalue/totala volym för att veta värdet per asset. (Collvalue/volym) * volym = föreslagen collvalue. 
     * Föreslagna ska vara högre än marginalkravet men inte mer än Collvalue som finns för den valda säkerheten. 
     * 
     * @param {*} e 
     */

    onChangeVolume(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState(
            { addNumber: e.target.value }
        );
        // Calculate value when user change volume
        this.calculateValue(e.target.value);
    }

    /**
     * Get the value from the dropdown
     * @param {*} e 
     */
    onChangeDropdown(e) {
        this.loadColHoldning();
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value) {
            this.setState(
                {
                    colHoldingvalue: e.target.value,
                    //validated: true
                }
            );
        } else {
            this.setState(
                { validated: false }
            );
        }

        this.getAssets(e.target.value)
    }

    /**
     * Check if user press tab or return in the volume field. 
     * Then run calculateCollateralItem. 
     * @param {*} e 
     */
    volumeFocus(e) {
        // Check if user press the tab key 
        const code = e.keyCode || e.which;
        if (code === 9 || code === 13) {
            this.setState(
                {
                    toggledvolumeFocus: !this.state.toggledvolumeFocus
                }
            );
            // stop return from closing the modal
            e.preventDefault();
            //e.stopPropagation();
            this.calculateCollateralItem();
        }
    }

    /**
     * Get list of colHolding accounts and assets for each
     */
    loadColHoldning() {
        this.setState({
            colaccount: this.state.marginPos.collHolding
        });
    }


    getAssets(collHoldingvalue) {
        // Pass in array with name of this.state.colHoldingvalue from dropdown and find all the collateral in the marginpos.
        // ladda typeaheaden med data
        const getArray = this.state.colaccount.find(el => el.name === collHoldingvalue);
        this.setState({
            dataLoaded: true,
            data: getArray.holding
        });
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.state.tbl) {
            let updateaction = {}
            updateaction = this.state.transaction;
            updateaction.state = "proposed";
            updateaction.nextState = "approved";
            //updateaction.nextStateUser = "approved";
            updateaction.collSubmit = this.state.tbl;
            updateaction.collValueChange = this.state.sumProposed;
            updateaction.collHolding = this.state.colHoldingvalue; // from dropdown

            if (this.state.transaction.netCollMargin + this.state.sumProposed > 0) {
                this.setState({ validated: true });
            }
            // create action
            if (this.state.validated && this.state.colHoldingvalue && (this.state.transaction.netCollMargin + this.state.sumProposed > 0)) {
                e.preventDefault();
                let marginPos = this.state.marginPos.name;
                /* let data = {
                     "name": "callback", 
                     "state": "proposed",
                     "stateLog": [
                         { "state": "requested", "timeStamp": Date.now() },
                         { "state": "proposed", "timeStamp": Date.now()  },
                         { "state": "approved", "timeStamp": 0 },
                         { "state": "transferring", "timeStamp": 0 },
                         { "state": "completed", "timeStamp": 0 }
                     ],
                     "deadline": Date.now() + 3600 * 1000,
                     "giver": this.state.transaction.giver,
                     "taker": this.state.transaction.taker,
                     "marginPos": marginPos,
                     "netCollMargin": this.state.transaction.netCollMargin - this.state.sumProposed,
                     "collReturn": this.state.tbl,
                     "nextState": "approved",
                     //"nextStateUser": "approved",
                     "collValueChange": -this.state.sumProposed,
                     "collHolding": this.state.colHoldingvalue,
                     
 
                     
 
                 };*/

                let body = {
                    "marginPos": marginPos, // narginPos name
                    "type": "substitution",
                    "remaining": 60,
                    //"deadline": "2020-01-23T11:05:17Z",
                    //  "netValue":  this.state.transaction.netCollMargin - this.state.sumProposed, // The net value taking the collateral value (after haircut) and subtracting the margin requirement
                    "message": this.state.text,
                    "collReturn": this.state.tbl //saknas just nu!
                }

                console.log('body: ', body)
                api.create("/colr", body)
                    .then(res => {
                        this.setState({
                            open: true,
                            submitted: true,
                            response: "Created new request recall!"
                        });

                        this.goDashboard();
                        // console.log('Transaction: ', data)
                        /*   if (res) {
   
                               api.read("/action/" + res).then(res => {
   
                                   if (res) {
                                       api.update("/action/" + res._id, res)
                                           .then(res => {
   
                                               this.setState({
                                                   open: true,
                                                   submitted: true,
                                                   textvalue: '',
                                                   confirm: true
                                               });
   
                                               this.setState({ response: "Modal.propose.response.success", errormessage: res.message })
                                           })
                                           .catch(err => {
   
                                               this.setState({
                                                   submitted: false,
                                                   open: true
                                               });
                                               this.setState({
                                                   response: "CcpMarginPos.propose.collateral.response.error",
                                                   errormessage: err.message
                                               })
                                           });
   
                                   }
                               }) // end read
   
                           }*/
                    }
                    ).catch(err => {
                        this.setState({
                            open: true,
                            textvalue: '',
                            submitted: false,
                            confirm: true
                        });
                        this.setState({
                            response: "CcpTransaction.escalation.response.error",
                            errormessage: err.message
                        })
                    }); // end create

            }
        }
    }


    /**
     * Get selected item from the dropdown list.
     * Hide the dropdown list by setting it to none first and move
     * focus to the volume field.
     * After volume is added and the user has tabbed past the last field, 
     * caculate asset volume and add the value in the json and
     * put it in a new object to avoid refs, then push
     * it to the this.state.tbl and show it in a table view below.
     * @method getItem()
     * @param {*} e 
     */

    getItem(e) {
        // set focus to the volume field with a function that uses a ref..
        if (e && e.length) {
            let resulten = e;
            this.setState({ selectedItem: resulten })
            // not empty 
            this.focus();
        }
    };

    calculateValue(input) {
        // Selected item from assets
        let asset = this.state.selectedItem;

        if (!Array.isArray(asset)) {
            asset = [asset];
        }
        let value = 0;
        let minvalue = 0;
        let haircut = 0;
        let collValue = 0;
        let intVolume = parseInt(input); //volume

        if (this.state.dataLoaded && asset[0]) {
            value = asset[0].value * intVolume / asset[0].volume
            haircut = asset[0].haircut * intVolume / asset[0].volume
            collValue = asset[0].collValue * intVolume / asset[0].volume
            value = parseInt(value);
            this.setState({
                minvalue: minvalue,
                maxvalue: asset[0].collValue,
                selectedItemVolume: intVolume,
                step: 1
            })
        }

        // check that the asset value is not to large
        if (value > asset[0].value) {
            this.setState(
                {
                    validated: false,
                    validationmessage: "To large value, try something else!",
                    numberInputisValid: false,
                    numberInputisInvalid: true
                }
            )
        } else {
            this.setState(
                {
                    validated: true,
                    validationmessage: "",
                    numberInputisValid: true,
                    numberInputisInvalid: false
                }
            )
        }

        this.setState(
            {
                selectedItemValue: value,
                calcHaircut: haircut,
                calccolValue: collValue

            }
        )
    }

    /**
     * Check if it's a object
     * @param {*} obj 
     */
    isPlainObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    };

    /**
     * Create a new object and Add value and volume to it.
     * Clear the fields and calculate sumProposed. 
     * 
     * @method calculateCollateralItem()
     *  
     */
    calculateCollateralItem() {
        let obj = this.state.selectedItem;
        let d = new Date();

        if (!Array.isArray(obj)) {
            obj = [obj];
        }

        let uid = obj[0].asset + d.getMilliseconds()

        // create new object to skip the reference to the old object.
        if (this.state.selectedItemValue && obj[0].asset && this.state.selectedItemVolume && Array.isArray(this.state.tbl) && this.state.validated) {

            let newobj = {
                'volume': this.state.selectedItemVolume,
                'asset': obj[0].asset,
                '_id': obj[0]._id,
                'collValue': this.state.calccolValue,
                'description': obj[0].description,
                // 'haircut': obj[0].haircut,
                'haircut': this.state.calcHaircut,
                'value': this.state.selectedItemValue,
                //'marketValue': obj[0].marketValue,
                //'value': obj[0].value,
                'Remove': [<FontAwesomeIcon icon={["fas", "pencil-alt"]} />, "#" + uid],
                'uid': uid // unique id set so that we can remove items 
            }

            if (this.isPlainObject(newobj)) {
                // add object to the table   
                let tblArray = this.state.tbl;
                tblArray.push(newobj);
                this.setState({
                    tbl: tblArray
                })
                // Clear the fields
                this.setState({
                    addNumber: '',
                    selectedItemValue: '',
                    selectedItem: {}
                });
                this._typeahead.clear();
                // Calculate sumProposed
                let array = this.state.tbl;
                let sumProposed = array.reduce((acc, curr) => acc + curr.collValue, 0);

                this.setState({
                    sumProposed: sumProposed,
                    validated: false,
                    validationmessage: "Value is to high"
                })

                if (this.state.transaction.netCollMargin + sumProposed > 0) {
                    this.setState({
                        validated: true,
                        validationmessage: ""
                    });
                }
            }
        }
    }

    /**
     * Get address hash from browser and remove item from the tableview.
     * @method hashChange()
     * @param {*} hash 
     */
    hashChange(hash) {
        hash = hash.slice(1);
        //delete from tbl
        let removeIndex = this.state.tbl.map(function (item) { return item.uid; }).indexOf(hash);
        // get the object so we can move it to the input field..
        let newTbl = this.state.tbl;
        const result = newTbl.find(({ uid }) => uid === hash);
        // Place info in input field
        if (result) {
            this.getItem([result]);
            this.setState({ selectedItem: result });
            this.setState({ addNumber: result.volume });
            this.setState({ selectedItemValue: result.collValue });
            this.setState({ defaultvalue: [result] })
            // remove object
            let spliceArray = this.state.tbl;
            spliceArray.splice(removeIndex, 1);
            this.setState(
                {
                    tbl: spliceArray
                }
            )
            this.setState({ validated: false })
            // clear hash in url
            if (removeIndex) {
                window.location.hash = '';
            }
        }

    }

    postMessage = (evt) => {
        this.setState({
            text: evt.target.value
        });
    }

    render() {
        if (this.state.link) {
            return <Redirect to={this.state.link} />
        }
        // let buttonText = "";
        //console.log("marginpos: ", this.state.marginpos)
        const { colaccount, transaction, submitted, sumProposed, response, errormessage, data, selectedItemValue, tbl, multiple, validated, marginPos, validationmessage } = this.state;
        const chooseAsset = this.intl.formatMessage({ id: "CcpTransaction.escalation.modal.inputasset" }); // Choose asset...
        const chooseVolume = this.intl.formatMessage({ id: "CcpTransaction.escalation.modal.inputvolume" }); // add value...
        const selectAccount = this.intl.formatMessage({ id: "CmTransaction.propose.colatteral.modal.selectaccount" }); // add value...
        //console.log('CollValueChange: ', this.state.sumProposed)

        // detect if browser address field hash changed 
        if (window.location.hash) {
            this.hashChange(window.location.hash);
        }

        return (
            <>
                <Button variant="outline-success" className="mb-1" block size="sm" onClick={this.toggle}>
                    <FormattedMessage id={"Action.substitution.button"} />
                </Button>

                <>
                    <Modalcomp show={this.state.open} parentAction={this.toggle} titleIntl={"Action.substitution.button"} size="lg" backdrop={"static"}>
                        <Form noValidate validated={validated} onSubmit={this.onSubmit} >
                            {!submitted ? (
                                <>
                                    <Modal.Body>

                                        {response &&
                                            <>  <b className="text-danger">{errormessage}</b><br />
                                                <b><FormattedMessage id={response} defaultMessage={response}
                                                    values={{
                                                        a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                    }}
                                                /></b>
                                                <br /><br />
                                            </>}
                                        <Row className="mt-3">
                                            <Col>
                                                <h4>
                                                    <FormattedMessage id="Action.substitution.button" /> {marginPos.name}
                                                </h4>
                                            </Col>
                                        </Row>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label><FormattedMessage id="CmTransaction.propose.colatteral.modal.selectlabel" /></Form.Label>
                                            {colaccount &&
                                                <Form.Control as="select" autoFocus={true} onChange={this.onChangeDropdown} required>
                                                    <option value="" >{selectAccount}</option>
                                                    {colaccount.map((item, i) => {
                                                        return <option key={i} value={item.name}>{item.name}, {item.description}</option>
                                                    })}
                                                </Form.Control>
                                            }
                                        </Form.Group>

                                        <Row className="mt-2">
                                            <Col {...widthLeft}>
                                                <b>
                                                    <FormattedMessage id="margincall" />
                                                </b>
                                            </Col>
                                            <Col {...widthRight} className="text-right">
                                                {numberRedFormat(transaction.netCollMargin)}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col {...widthLeft} className="text-left">
                                                <b>
                                                    <FormattedMessage id="CmTransaction.escalation.proposed" />
                                                </b>
                                            </Col>
                                            <Col  {...widthRight} className="mb-1 border-bottom text-right">
                                                {numberRedFormat(sumProposed)}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col {...widthLeft} className="text-left">
                                                <b><FormattedMessage id="CmTransaction.escalation.proposed.position" /></b>
                                            </Col>
                                            <Col {...widthRight} className="text-right mb-4">
                                                {numberRedFormat(transaction.netCollMargin + sumProposed)}
                                            </Col>
                                        </Row>

                                        <React.Fragment>
                                            <Table className="table table-hover table-sm table-responsive-md">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left text-uppercase">
                                                            <FormattedMessage id="Table.holdings.asset" />
                                                        </th>
                                                        <th className="text-right text-uppercase">
                                                            <FormattedMessage id="Table.holdings.volume" />
                                                        </th>
                                                        <th className="text-right text-uppercase">
                                                            <FormattedMessage id="Table.holdings.collValue" />
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td className="text-right">
                                                            {this.state.dataLoaded && (
                                                                <React.Fragment>
                                                                    <Form.Group>
                                                                        <Typeahead
                                                                            id="typeaheadid"
                                                                            labelKey="asset"
                                                                            multiple={multiple}
                                                                            selected={this.state.defaultvalue}
                                                                            options={data}
                                                                            selectHintOnEnter={true}
                                                                            onChange={(e) => this.getItem(e)}
                                                                            onInputChange={() => this.setState({ defaultvalue: undefined })}
                                                                            placeholder={chooseAsset}
                                                                            ref={(ref) => this._typeahead = ref}
                                                                        />
                                                                    </Form.Group>
                                                                </React.Fragment>
                                                            )}
                                                        </td>
                                                        <td className="text-right">
                                                            <Form.Group controlId="value">
                                                                <Form.Control
                                                                    type="number"
                                                                    step={this.state.step}
                                                                    ref={this.numberInput}
                                                                    placeholder={chooseVolume}
                                                                    value={this.state.addNumber}
                                                                    onChange={this.onChangeVolume}
                                                                    onKeyDown={this.volumeFocus}
                                                                    isValid={this.state.numberInputisValid}
                                                                    isInvalid={this.state.numberInputisInvalid}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        <td className="text-right">
                                                            {selectedItemValue}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Row className="mt-3">
                                                <Col>
                                                    <p>{validationmessage}</p>
                                                </Col>
                                            </Row>
                                        </React.Fragment>

                                        <TableView headingIntl={this.state.headingIntl} format={this.state.format} data={tbl} />
                                        <PostMessage onTextChange={this.postMessage} />
                                    </Modal.Body>

                                    <Modal.Footer>
                                        {!submitted && <Button variant="outline-primary" className="mb-1" size="sm" type="submit">
                                            <FormattedMessage id={"Action.substitution.button"} defaultMessage="Submit" />
                                        </Button>}
                                        {submitted && <Button variant="outline-primary" className="mb-1" size="sm" onClick={(e) => this.close(e)}>
                                            <FormattedMessage id="CcpMarginPos.escalation.close" defaultMessage="Close" />
                                        </Button>}
                                    </Modal.Footer>
                                </>
                            ) : (<>
                                <Modal.Body>
                                    {response &&
                                        <>  <b className="text-danger">{errormessage}</b><br />
                                            <b><FormattedMessage id={response} defaultMessage={response}
                                                values={{
                                                    a: msg => (<a className="external_link" href="mailto:support@envirio.co">{msg}</a>)
                                                }}
                                            />  {marginPos.name}</b>
                                            <br /><br />
                                        </>}
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="outline-primary" className="mb-1" size="sm" onClick={(e) => this.close(e)}>
                                        <FormattedMessage id="CcpMarginPos.escalation.close" defaultMessage="Close" />
                                    </Button>
                                </Modal.Footer>
                            </>)}
                        </Form>
                    </Modalcomp>
                </>
            </>
        )
    }
}

export default injectIntl(ModalRequestSubstitution);