/** 
 * @fileOverview Main module of the MarcoNet React App.
 *
 * Check out {@link https://jsdoc.app/ @use JSDoc} for documentation on how to document.
 * ...also have a look at this {@link https://github.com/shri/JSDoc-Style-Guide JSDoc style guide and tutorial}.
 *
 * @author Envirio Consulting AB
 *
 * @requires NPM:react
 * @requires NPM:react-router-dom
 * @requires NPM:react-bootstrap
 * @requires NPM:react-intl
 * @requires NPM:history
 * @requires NPM:@fortawsome
 * @requires module:utils/auth
 * @requires module:utils/global
 * @requires module:locales/language
 * 
 * @requires module:components/home
 * @requires module:components/footer
 * @requires module:components/cookie-consent
 * @requires module:components/page404
 * @requires module:components/info-blog-post
 * @requires module:components/modal-sign-in
 * @requires module:components/info
 * @requires module:components/dashboard
 * @requires module:components/overview-marginpos
 * @requires module:components/overview-transaction
 * @requires module:components/user-sign-out
 * @requires module:components/docs
 */

import React, { Component } from 'react';
import { Router, Route, Switch, NavLink } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown, Image } from "react-bootstrap";
import { FormattedMessage, useIntl } from 'react-intl';
import { createBrowserHistory } from "history";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import auth from './utils/auth';
import { global } from './utils/global';
import lang from "./locales/language.js";
import "./App.css";

import HomePage from "./components/home";
import Footer from "./components/footer";
import CookieConsent from "./components/cookie-consent";
import Page404 from "./components/page404";
import BlogPost from "./components/info-blog-post";
import SignIn from "./components/modal-sign-in";
import Info from "./components/info";
import Dashboard from "./components/dashboard";
import TransactionOverview from "./components/overview-transaction";
import MarginPosOverview from "./components/overview-marginpos";
import SignOut from "./components/user-sign-out";
import { DocsApp, DocsApi, DocsOpenApi } from "./components/docs";

import logo from "./marconet-logo.png";
import swedbankLogo from "./swedbank-logo.png";

library.add(faPlus, faSignInAlt, faSignOutAlt)
const history = createBrowserHistory();
export var thisApp;

/**
 * Display the View drop down of the main menu
 */
const InfoViewMenu = () => {
    const intl = useIntl();
    const titleView = intl.formatMessage({ id: "Nav.view" });

    return (
        <NavDropdown title={titleView} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={goDemo1}>Demo user CCP1</NavDropdown.Item>
            <NavDropdown.Item onClick={goDemo2}>Demo user CCP2</NavDropdown.Item>
            <NavDropdown.Item onClick={goDemo3}>Demo user CM 7000</NavDropdown.Item>
            <NavDropdown.Item onClick={goDemo4}>Demo user CM MPID2</NavDropdown.Item>
            <NavDropdown.Item onClick={goDemo5}>Demo user Investor</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={NavLink} to="/docsapi">API docs</NavDropdown.Item>
        </NavDropdown>
    )
}

// Switch to a demo user
const goDemo1 = () => { setDemoUser("DemoCCP1"); }
const goDemo2 = () => { setDemoUser("DemoCCP2"); }
const goDemo3 = () => { setDemoUser("Demo7000"); }
const goDemo4 = () => { setDemoUser("DemoMPID2"); }
const goDemo5 = () => { setDemoUser("DemoInvest"); }

/**
 * Switch to demo user "user" and go to the dashboard  
 * @param {string} user 
 */
const setDemoUser = (user) => {
    auth.authenticate(this, user, "", function () {
        window.location.replace('/');
    });
}

/**
 * Display the main menu of the app
 * 
 * @class MainMenu
 * @extends {Component}
 * 
 * @property {object} props - JSX properties passed to the class:
 * @property {picture} props.logo - Contains the app logo
 */
class MainMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
        }
    }

    switchToOtherLanguage = () => {
        console.log(lang.getLanguage());
        if (lang.getLanguage() === 'en') {
            lang.switchLanguage('sv');
        } else {
            lang.switchLanguage('en');
        }
    }

    doShowLogin = () => {
        this.setState({ showLogin: true });
    }

    componentDidUpdate() {
        if (this.state.showLogin) {
            this.setState({ showLogin: false });
        }
    }
    // OBS! href in dashboard link as a workaround for broadcast bug.
    render() {
        return auth.loggedIn() ? (
            <Navbar sticky="top" collapseOnSelect expand="sm" bg="light" variant="light">
                <Container>
                    <NavBarBrand logo={this.props.logo} />
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>
                            <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <InfoViewMenu />
                            <Nav.Link onClick={this.switchToOtherLanguage}><FormattedMessage id="Nav.language" defaultMessage="Svenska" /></Nav.Link>
                            {/* <Nav.Link href="/"><FormattedMessage id="Nav.settings" defaultMessage="Settings" /></Nav.Link>*/}
                            <Nav.Link href="/signout"><FontAwesomeIcon icon={["fas", "sign-out-alt"]} /> <FormattedMessage id="Nav.signout" defaultMessage="Logout" /></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        ) : (
                <Navbar sticky="top" bg="light" variant="light">
                    <Container>
                        <NavBarBrand logo={this.props.logo} />
                        <Nav className="ml-auto">
                            <Nav.Link onClick={this.switchToOtherLanguage}><FormattedMessage id="Nav.language" defaultMessage="Svenska" /></Nav.Link>
                            <Nav.Link onClick={this.doShowLogin}><FontAwesomeIcon icon={["fas", "sign-in-alt"]} /> <FormattedMessage id="Nav.signin" defaultMessage="Login" /></Nav.Link>
                            <SignIn show={this.state.showLogin} />
                        </Nav>
                    </Container>
                </Navbar>
            );
    }
}

/**
 * Display the application logo to the left of the menu
 * @property {object} props - JSX properties passed to the function:
 * @property {picture} props.logo - Contains the app logo
 */
const NavBarBrand = (props) => {
    return (
        <Navbar.Brand as={NavLink} to="/" >
            <Image
                alt="MarcoNet"
                src={props.logo}
                height="30"
                className="d-inline-block align-middle" />
        </Navbar.Brand>
    );
}

/**
 * Main class for the app holding the router
 *
 * @class App
 * @extends {Component}
 */
export default class App extends Component {
    constructor(props) {
        super(props);

        // Default branding
        this.state = { branding: 'marconet' };
        this.logo = logo;
        setStylesheet(this.state.branding);

        thisApp = this;
        this.subBlog = global.subscribe('/blog/' + lang.getLanguage(), this.setAllBlogPosts, { sort: "-date" });
        auth.getToken(() => { this.haveToken() });
    }

    haveToken() {
        this.subColr = global.subscribe('colr', this.setAllCollRequests, { sort: "deadline" });
        this.subMarginpos = global.subscribe('marginpos', this.setAllMarginPositions);
        this.doBranding();
        this.forceUpdate();
    }

    /** Handle notifications of updates to blog posts so the view gets refreshed */
    setAllBlogPosts = (name, data) => {
        this.setState({ allBlogPosts: data });
    }

    /** Handle notifications of updates to collateral requests so the view gets refreshed */
    setAllCollRequests = (name, data) => {
        this.setState({ allCollRequests: data });
    }

    /** Handle notifications of updates to margin positions so the view gets refreshed */
    setAllMarginPositions = (name, data) => {
        this.setState({ allMarginPositions: data });
    }

    componentWillUnmount() {
        global.unsubscribe(this.subColr);
        global.unsubscribe(this.subMarginpos);
        global.unsubscribe(this.subBlog);
    }

    doBranding() {
        var branding = (global.user && global.user.organisation && global.user.organisation.branding) || 'marconet';
        if (!this.state.branding || this.state.branding !== branding) {
            switch (branding) {
                case 'swedbank':
                    console.log("Let's do Swedbank branding")
                    this.logo = swedbankLogo;
                    break;
                case 'marconet':
                default:
                    console.log("Let's do MarcoNet branding")
                    this.logo = logo;
                    break;
            }
            setStylesheet(branding);
            this.setState({ branding: branding });
        }
    }

    goto(destination) {
        console.log("Location:", history.location)
        console.log("Going to:", destination);

        if (destination && destination.length > 0) {
            //window.location = destination;
            window.location.replace(destination);
            // history.go(destination);
            //this.forceUpdate();
        }
    }

    render() {
        let loggedIn = auth.loggedIn();
        let role = loggedIn && global.user && global.user.organisation ? global.user.organisation.role : undefined;
        console.log(global)
        // console.log("Role:", role)
        let comp = {
            home: loggedIn ? Info : HomePage,
            dashboard: Dashboard,
            marginpos: MarginPosOverview,
            transaction: TransactionOverview
        };
        if (role === 'network' || role === 'admin') {
            comp.dashboard = Page404;
            comp.transaction = Page404;
            comp.marginpos = Page404;
        }
        return (
            <Router history={history}>
                <MainMenu logo={this.logo} />

                <Switch>
                    <Route path="/" exact render={(props) => <comp.home {...props}
                        allBlogPosts={this.state.allBlogPosts} />} />
                    <Route path="/blogpost/:id" render={(props) => <BlogPost {...props}
                        allBlogPosts={this.state.allBlogPosts} />} />
                    {/*<Route path="/signin" component={SignIn} />*/}
                    <Route path="/signout" component={loggedIn ? SignOut : Page404} />
                    <Route path="/docsapp" component={loggedIn ? DocsApp : Page404} />
                    <Route path="/docsapi" component={loggedIn ? DocsApi : Page404} />
                    <Route path="/docsopenapi" component={loggedIn ? DocsOpenApi : Page404} />

                    <Route path='/dashboard' render={(props) => <comp.dashboard {...props}
                        allCollRequests={this.state.allCollRequests}
                        allMarginPositions={this.state.allMarginPositions} />} />
                    <Route path='/transaction/:id' render={(props) => <comp.transaction {...props}
                        allCollRequests={this.state.allCollRequests}
                        allMarginPositions={this.state.allMarginPositions} />} />
                    <Route path='/marginpos/:id' render={(props) => <comp.marginpos {...props}
                        allCollRequests={this.state.allCollRequests}
                        allMarginPositions={this.state.allMarginPositions} />} />
                    <Route component={Page404} />
                </Switch>

                <CookieConsent
                    location={"bottom"}
                    cookieName={"marconetconsent"}
                    debug={false}
                    hideOnAccept={true}
                >
                    <FormattedMessage id="Consent.text" defaultMessage="This website uses cookies to enhance the user experience" />
                </CookieConsent>

                <Footer />
            </Router>
        );
    }
}

/**
 * Switch style sheet to customize the application
 * @param {string} title - Title of the style sheet to be applied
 */
function setStylesheet(title) { //Main stylesheet switcher function. 
    var i, cacheobj
    for (i = 0; (cacheobj = document.getElementsByTagName("link")[i]); i++) {
        if (cacheobj.getAttribute("rel").toLowerCase() === "alternate stylesheet" && cacheobj.getAttribute("title")) { //if this is an alternate stylesheet with title
            cacheobj.disabled = true
            if (cacheobj.getAttribute("title") === title) //enable alternate stylesheet with title that matches parameter
                cacheobj.disabled = false //enable chosen style sheet
        }
    }
}
